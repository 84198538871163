.select-wrapper {
    margin: auto;
    max-width: 600px;
    width: calc(100% - 40px);
  }
  
  .select-pure__select {
    align-items: center;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    color: #363b3e;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: left;
    padding: 8px 12px;
    position: relative;
    transition: 0.2s;
    width: 100%;
  }
  
  .select-pure__options {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    color: #363b3e;
    display: none;
    left: 0;
    max-height: 221px;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 5;
  }
  
  .select-pure__select--opened .select-pure__options {
    display: block;
  }
  
  .select-pure__option {
    background: #0079c0;
    border-bottom: 1px solid rgba(255,255,255,.5);
    padding: 5px 2px;
    text-align: center;
    color: #fff;
    font-weight: 600;
  }
  .select-pure__option:hover{
      background-color: #3393cc;
  }
  .select-pure__option--selected {
    color: #fff;
    cursor: initial;
    background: #0079c0;
    pointer-events: none;
    display: none;
  }
  
  .select-pure__option--hidden {
    display: none;
  }
  
  .select-pure__selected-label {
    background: #0079c0;
    border-radius: 4px;
    color: #fff;
    cursor: initial;
    display: inline-block;
    margin: 2px;
    padding: 2px 8px;
  }
  
  .select-pure__selected-label:last-of-type {
    margin-right: 0;
  }
  
  .select-pure__selected-label i {
    cursor: pointer;
    display: inline-block;
    margin-left: 7px;
  }
  
  .select-pure__selected-label i:hover {
    color: #e4e4e4;
  }
  
  .select-pure__autocomplete {
    background: #fff;
    border: none !important;
    padding: 8px 12px !important; 
    width: 100%;
  }
  .select-pure__placeholder--hidden {
      display: none;
  }
  .select-pure__placeholder{
      color: #9c9fa5;
  }