
  .insight-w3-card3{
    padding: 24px;
  }

  @media screen and (min-width: 480px){
    .insight-border{
      border: 1px solid #e8e8e8;
    }
  }

  .insight-date{
    font-size : 14px;
    font-weight: 400;
    color:#212121;
  }
  .actionContainer{
    display: flex; 
    flex-flow: wrap; 
    margin: 24px 0px;   
  }
  
  .insight-border{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin: 12px; */
    margin: 12px ;
    width: 30.7%;
  }

  .insightContainer{
    padding: 18px;
    height: auto;
  }
  
  .insightcase-w4-card-4{
    float: left;
    padding-right: 12px;
    display: flex;
    padding-top: 1%;
  }
  
  .insightcase-w4-card-4 i{
    width: 24px; 
    height: 42px; 
    border-radius: 48px;
    font-size : 24px;
    padding-top: 1%; 
  }
  
  .insight-detail{
    overflow:hidden;
  }
  
  .insight-title{
    font-weight: 600; 
    font-size: 14px; 
    margin-bottom: 12px;
    line-height: 1.5;
    min-height: 42px;
  }
  
  .insight-text{
    margin-bottom: 1em;
    color: rgba(0,0,0,.65);
    overflow:hidden;
    /* font-size:14px; */
    line-height:1.5;
    font-size: 14px;
    padding: 8px 8px 0 0;
  }
  
  .insight-duration{
    font-weight: 500; 
    font-size: 12px;
    margin-bottom: 12px;
    padding:0 18px
  }

  .new-insightcase-view-div{
    width: auto;
  }

  .notification-box{
    width: auto;
    padding: 24px;
  }
  
  .notification-text{
    font-size: 14px;
    display: flex;
    flex-flow: wrap;
    padding: 0px;
    margin: 0px;
  }

  .nodataforinsight{
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .insight-head{
    margin: 0px 0 12px 0; 
    font-size:18px
  }

  .insight-warning{
    margin: 0px ; 
    font-size:14px; 
    background: white;
    padding: 12px;
    border: 1px solid#d9d5d5!important;
  }
  .date-box{
    display: flex;
    flex-direction: row;
    padding:12px
  }

  .date-box:after{
    content: "";
    flex: 1 1;
    border-bottom:1px solid #d9d5d5;
    margin: auto;
  }

  .notify-bellIcon{
    display:none;
    position: absolute;
    color: white;
    height: 15px;
    line-height: 1.5;
    background-color: red;
    margin-top: -48px;
    margin-left: 18px;
    font-weight: 600;
    border-radius: 10px;
    max-width: 40px;
    min-width: 22px;
    text-align: center;
    font-size: 10px;
  }

  @media screen and (max-width: 480px){
    .notification-box{
      padding: 0px;
    }
    .new-insightcase-view-div{
      padding: 0px;
    }
    .insightContainer{
      padding: 0px 0px 12px;
    }
    .insightcase-w4-card-4 i{
      width: 24px;
    }
    .insight-duration{
      padding:0px;
    }
    .insight-title{
      min-height: 0px;
    }
    .insight-border{
      width:100%;
      border-bottom: 1px solid #e8e8e8;
      margin: 12px 0px 0px;
      padding: 12px 12px 0 12px;
    }   
    .insight-date{
      margin-top: 12px;
    }
    .insight-w3-card3{
      margin: 0px;
      padding:0px;
    }
    .notify-bellIcon{
      margin-top: -40px;
      margin-left: 25px;
    }
    .date-box:after{
      margin: 0 0 14px;
    }
    .date-box{
      padding: 0 12px;
    }
    .notification-text{
      padding: 12px;
    }

    .insight-head{
      margin: 24px 0 12px 12px;
    }
  }

  .actionable-link{
    color: #848484 ;
    width: 42px;
    display: block;
    text-align: center;
    height: 66px;
    padding-top: 21px;
  }

  .actionable-insights-link{
    color: #848484!important;
    width: 42px;
    display: block;
    text-align: center;
    height: 66px;
    padding-top: 24px;
  }

  .actionable-link:hover {
    background: rgba(0, 0, 0, 0.024);
  }
  .actionable-insights-link:hover{
    background: rgba(0, 0, 0, 0.024);
  }
  
  .notify-icon{
    display: none;
    color: #fff;
    height: 15px;
    line-height: 1.5;
    background-color: red;
    font-weight: 600;
    border-radius: 10px;
    max-width: 40px;
    min-width: 22px;
    text-align: center;
    font-size:10px;
  }

  .positiveInsight{
    font-size: 22px !important;
    margin-left: 10px  !important;
    color:green !important;
    padding-top: 7px;

  }
  .negativeInsight{
    font-size: 22px !important;
    color:red !important;
    padding-top: 7px;
    margin-left: 10px  !important;


  }
  .mentions-text{
    font-size: 16px;
    padding: 8px 8px 0 0;
  }

  /* .insight-border:hover{
    cursor: pointer;
  } */
  .insight-link{
    color: #545454;
    transition: none;
  }


  .insight-border>a:hover,
  .insight-border>a:focus,
  .insight-border>a:active {
    text-decoration: none !important;
    color:#545454;
}

.rawfacebook-official{
  color: #5054b4!important;
}

.rawhashtag, .rawnewspaper-o ,.rawusers ,.rawkey ,.rawusers{
  color: #0079c0!important;
}

.rawtwitter{
  color: #3190f6!important;
}
.rawyoutube-play{
  color: red !important;
}
.rawinstagram{
  background: linear-gradient(#4f5bd5,#962fbf, 	#d62976,#fa7e1e,#feda75) !important;
  height: 26px !important;
  border-radius: 8px !important;
  color: white !important;
}
.sidebar-insightCount{
  font-weight:600;
  padding: 0 5px;
  min-width: 26px;
}

.insight-margin{
  line-height: normal;
  flex-grow:0
}
.insight-value{
  font-size: 20px;
  padding: 3px 0;
}
