
.top-action-bar-container {
    min-height: 48px;
    background: linear-gradient(130deg, #4054b2 0%, #6ec1e4 89%);
    display: flex;
    text-align: center;
}
.mention-limit-top-action-bar-container{
    min-height: 48px;
    background: linear-gradient(130deg,#eb5a46 0,#ffaf9f 89%);
    display: flex;
    text-align: center;
}

.top-header-cross {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.top-action-bar-content {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Helvetica, Arial, sans-serif;
}

.mention-limit-top-action-bar-content{
    color: #a94442;
    font-size: 14px;
    font-family: Roboto,"Helvetica Neue",Arial,sans-serif;
    font-weight: 400;
}

/* all enterprise layout */

.gotoButtom {
    position: fixed;
    border-radius: 100%;
    right: 5%;
    top: unset;
    bottom: 8%;
    display: none;
    /* padding: 15px; */
    width: 50px;
    height: 50px;
    /* display: flex;
    justify-content: center; */
    z-index: 999999999;
}

.gotoButtom i {
    color: #00dafc;
    font-size: 50px;
    width: 100%;
    cursor: pointer;
    background: white;
    height: 50px;
    border-radius: 100%;
    cursor: pointer;
    width: 50px;
    opacity: .9;
}

.gotoButtom i:hover {
    opacity: 1;
    color: #4632da;
}

/* ------------------------------------------------------------------- */

a.demo-modal-links:hover{
    background :#fafafa
}

.demo-summary-helpbox{
    width: 100%;
    padding: 12px ;
}
.demo-summary-helpbox img{
    max-width: 60px;
}

.demo-summary-helpbox h6{
    text-transform: unset;
    margin-bottom: 8px;
}
.firstTimeMessageImageDiv {
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.demo-video-modal .demo-video-container {
    background: #fff;
    padding: 24px 36px;
    border-radius: 5px;
}

.demo-iframe-video{
    width: 100%;
    min-height: 279px;
}

@media(max-width:480px){
    .demo-iframe-video{
        min-height: 160px;
    }
    .demo-video-modal .demo-video-container {
        background: #fff;
        padding: 24px 12px;
        border-radius: 5px;
    }
}



.counter-label {
    font-size: 10px;
    font-weight: 600;
    color: grey;
}


.firstTimecontainer img {
    max-width: 150px;
    max-height: 75px;
    transform: rotate(-15deg);
}

.timepicker {
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
}

.mention-inside-info {
    margin: 0 -20px;
}

span.highlightBeta {
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #f7e333;
    font-weight: 800;
    background: #000;
}

span.highlightUpgrade {
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 10px;
    color: #fff;
    font-weight: 800;
    background: #eb5a46;
    text-transform: uppercase;
}

/* .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #F9F9fa;
    color: #000 !important;
    text-align: center;
    border-radius: 6px;
    padding: 5px !important;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: -25vh;
    margin-left: 0px;
    font-family: "Roboto", Arial, sans-serif;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    font-family: "Roboto", Arial, sans-serif;
} */

/* .tooltip:hover .tooltiptext {
    visibility: visible;
} */

.wrapper {
    margin-left: -3.2% !important;
    margin-right: -3.2% !important;
    position: relative;
    height: 100%;
    display: flex;
    margin-bottom: 0px;
}

.swipe {
    position: fixed;
    z-index: 998;
}

/* .wrapper p {
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.7em;
    color: #000;
} */

/* .wrapper a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    color: #fff;
} */

.wrapper .navbar {
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

#content .navbar-header .navbar-btn {
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: none;
    outline: none !important;
    border: none;
    margin: 20px -4px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    background-color: #1f2322;
}

.swipe>.navbar-header>button:first-child {
    max-height: 30px;
    border-radius: 0px;
}

.swipe>.navbar-header>button:first-child>i {
    width: 5px;
    vertical-align: 5px;
}

#content .navbar .container-fluid>.navbar-collapse {
    margin-right: 0px;
    margin-left: 0px;
}

#sidebar {
    width: 256px;
    left: 0;
    float: left;
    z-index: 999;
    /* background: #1f2322; */
    color: #fff;
    transition: all 0.3s;
    min-height: 100vh;
    max-height: 100%;
}

.swipe>.navbar-header {
    position: fixed;
    z-index: 998;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.components li>a {
    color: #545454;
}

.components>li:hover {
    color: #0079c0
}

.btn:active,
.btn:hover,
.btn:focus,
.btn:selection {
    background: #101010 !important;
    color: #fff !important;
    outline: 0 !important;
}

#sidebarCollapse:focus {
    background: #101010 !important;
}

#sidebar.active {
    margin-left: 0;
    width: 80px;
}

.show-on-active-sidebar {
    display: none
}

#sidebar.active .hide-on-active-sidebar {
    display: none !important
}

.hide-on-active-sidebar{
    animation: cssAnimation 0s 0.3s forwards;
    visibility: hidden;
}

@keyframes cssAnimation {
    to   { visibility: visible; }
  }

#sidebar.active .show-on-active-sidebar {
    display: block
}

#sidebar.active .show-project-icon-on-active-sidebar {
    width: 40px;
    display: flex;
    justify-content: center
}

#sidebar .show-project-icon-on-active-sidebar {
    display: none
}

#sidebar.active .flex-justify-center-on-active-sidebar {
    display: flex;
    justify-content: center
}

/* #sidebar.active ul li.active a .show-project-icon-on-active-sidebar i.fa-product-hunt {
    color: #fff
} */

.side-btn-global-header {
    position: relative;
    height: 64px;
    padding: 0;
    width: 100%;
    max-width: 64px;
}

.side-angle-double {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.6em;
    text-rendering: optimizeLegibility;
}

.side-angle-span {
    /* height: 64px;
    padding: 35px 24px; */
    font-size: 20px;
    cursor: pointer;
    transition: all .3s, padding 0s;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    opacity: .7;
}
.side-angle-span:hover, .side-angle-span:focus, .side-angle-span:active{
    opacity: 1;
}

#sidebar.active .side-btn-global-header {
    max-width: unset;
}

/* #sidebar.active ul li a i.icon-size-on-active-sidebar {
    font-size: 16px
} */

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

#sidebar .sidebar-header {
    background: #111110;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#sidebar .sidebar-header h4 {
    color: #fff;
    font-weight: bold;
    margin: 10%;
    text-transform: uppercase;
    overflow: hidden;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.7em;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    color: #545454;
    display: block;
    text-transform: capitalize;
}

#sidebar ul li a.panel_option {
    padding: 10px 24px;
}

#sidebar ul li a.panel-subsuboption {
    padding: 10px 48px;
}

#sidebar.active ul li a.panel-subsuboption {
    padding: 10px;
}


#sidebar .components>li>a:hover, #sidebar .components .panel_suboption>a:hover{
    /* color: #fff !important; */
    background: #eceff1;
}

#sidebar .components .panel_suboption>a.activeSidebarOption:hover{
    background: #00dafc;
}

/* #sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
} */

a[data-toggle="collapse"] {
    position: relative;
}

a.panel_option[aria-expanded="true"]::before {
    right: 20px;
    /* top: 15px */
}

a.collapsed.panel_option[aria-expanded="false"]::before {
    right: 20px;
    /* top: 15px; */
}

a[aria-expanded="true"]::before {
    content: '\e260';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

a.collapsed[aria-expanded="false"]::before {
    content: '\e259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

#sidebar ul li>a.collapsed[aria-expanded="false"]::before,
#sidebar ul li ul li>a.collapsed[aria-expanded="false"]::before {
    top: 15px;
    right: 10px;
}

#sidebar ul li>a[aria-expanded="true"]::before,
#sidebar ul li ul li>a[aria-expanded="true"]::before {
    top: 15px;
    right: 10px;
}

#sidebar.active ul li>a.collapsed[aria-expanded="false"]::before,
#sidebar.active ul li ul li>a.collapsed[aria-expanded="false"]::before {
    top: 15px;
    right: 10px;
}

#sidebar.active ul li>a[aria-expanded="true"]::before,
#sidebar.active ul li ul li>a[aria-expanded="true"]::before {
    top: 15px;
    right: 10px;
}

.side-btn-global-header:hover {
    background: rgba(0, 0, 0, 0.024);
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 0;
    margin-bottom: 5px;
}

a.article,
a.article:hover {
    background: #111111 !important;
    color: #fff !important;
}

span>a {
    color: #fff;
}

.mention-inside span.widgetTitle {
    /* padding: 10% 0; */
    margin-left: 0px;
    letter-spacing: .5px;
    min-height: 45px;
    font-size: 18px;
    line-height: 1em;
    color: #000;
}

.mention-inside p.count {
    font-size: 34px;
    margin-bottom: 0;
    line-height: 1em;
}

p.count>a {
    font-size: 40px;
    color: #000;
    word-wrap: break-word;
}

p.totalsentiment>a {
    font-size: 18px;
}

.mention-inside p.totalsentiment:first-child {
    flex-grow: 1;
}

.mention-inside .btn-primary {
    border-radius: 0px;
    padding: 5px 10px;
    letter-spacing: 1px;
    border: 0px solid transparent !important;
}

.mention-inside p.words {
    margin-bottom: 0;
    word-break: break-word;
    line-height: 0.9em;
    text-align: left;
}

.words>span>a {
    color: #545454;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.6px;
    word-break: break-all;
    word-wrap: break-word;
}

.row-flex {
    flex-wrap: wrap;
    display: flex;
}

.row-flex>[class*="col-"] {
    margin-bottom: 30px;
}

.mentioncontainer {
    width: 83%;
    display: flex;
    align-items: center;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: none;
}

.panel_suboption {
    padding: 5px 0px;
    font-weight: 500;
    font-size: 12px;
    color: #d8d8d8;
    text-align: left;
}

.sidebarProjectName>span {
    font-weight: 600;
}

.mention-title.demographic-title {
    padding: 24px 24px 0px;
    min-height: unset;
    height: unset;
}

.activeSidebarOption {
    background: #00dafc;
    /* color: #545454 !important; */
}

li.notactive ul li a#sb-analysisnotactive[aria-expanded="false"]::before {
    content: '\e259';
    display: block;
    position: absolute;
    font-family: 'Glyphicons Halflings';
    font-size: .6em;
    top: 15px;
    right: 10px;
}
/* 
.activeSidebarOptionMain i,
.activeSidebarOptionMain span {
    color: #fff !important;
} */

.activeSidebarOption i,
.activeSidebarOption span {
    color: #fff !important;
}

.panel_suboption:first-child {
    margin-top: 0px;
}

.panel_suboption a span {
    font-size: 14px !important;
}

.panel_suboption a {
    font-size: 12px !important;
    font-weight: normal !important;
    color: #fff;
    display: block;
}

#bs-example-navbar-collapse-1 ul li.headbar-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#bs-example-navbar-collapse-1 {
    display: flex;
}

.headbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    float: right;
}

.improvements,
.recent-mention {
    /* margin-top: 20px; */
    padding: 0 25px;
    background-color: #eceff1;
}

.improve {
    padding: 5%;
    background-color: #fff;
    height: 100%;
    padding: 20px 20px 10px;
    text-align: left;
}

.improve>h4 {
    margin-bottom: 5%;
    color: #000;
}

.improve>ul {
    list-style: none;
    padding-left: 0px;
}

.post-box {
    padding: 0 0 0 15px;
}

.post-box>h3 {
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
}

.panelmenu_payment {
    margin-left: 20px;
}

.panelmenu {
    font-weight: 400;
    color: #7e7e7e;
    min-width: 500px;
    margin-right: 20px;
    font-size: 14px;
}

.panelmenu_payment_pay a {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ef7121;
    display: inline-block;
    color: #ef7121;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    margin-left: 10px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 10px;
}

.perception-score {
    margin-top: 5%;
    margin-bottom: 0;
    background: #fff;
    padding-top: 10px;
    margin-left: 24px;
    margin-right: 24px;
}

.perception-score>h4 {
    font-size: 24px;
    color: #000;
    margin-bottom: 10px;
    margin-top: 40px;
}

.quote {
    font-size: 12px;
    padding: 12px 0;
    overflow: hidden;
    font-style: italic;
    letter-spacing: .4px;
}

.perception-score>.btn {
    margin: 20px
}

.panelmenu a {
    color: #7e7e7e;
    text-decoration: none;
}

.panelmenu .breaker {
    margin: 0 15px;
    color: #f1f1f1;
}

.panelmenu .fa-panel-stack {
    top: -2px;
    position: relative;
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 2em;
    vertical-align: middle;
}

.panelmenu .fa-panel-stack .fa-circle-thin {
    top: 0;
}

.panelmenu .fa-panel-stack .fa {
    position: absolute;
    left: 0;
}

.panelmenu .fa {
    position: relative;
    top: 2px;
    font-size: 22px;
    margin-right: 5px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.upgrade-sidebar {
    padding: 5px 8px;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
    font-size: 9px;
    background: #ef7121 !important;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    /* float: right; */
}

a.upgrade-sidebar:hover,
a.upgrade-sidebar:focus,
a.upgrade-sidebar:active {
    background-color: #ef7320 !important;
    color: white !important;
}

.top_panel .header-enterprise {
    text-align: left;
    padding: 0 20px;
}

.header-enterprise .header-title {
    text-transform: capitalize;
    color: #000;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
}

.header-enterprise .main-header-title {
    text-transform: capitalize;
    color: #000;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
}

.header-enterprise .header-breadcrumbs {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 14px;
    color: #d2cccc;
}

.header-enterprise .header-breadcrumbs span {
    color: #5c5c5c;
    font-size: 14px;
}

.header-enterprise .header-breadcrumbs span a {
    color: #5c5c5c;
    font-size: 14px;
}

li.headbarlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.headbar>ul.navbar-right {
    display: flex;
}

.panel_big_holder>.graph {
    padding: 5%;
}

.mentions_box .mention {
    padding: 0px;
}

section.mention-box {
    width: 100%;
    height: auto;
    background: #fff;
    /* -webkit-box-shadow: 0px 0px 10px 1px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 0px 10px 1px rgba(222, 222, 222, 1);
    box-shadow: 0px 0px 10px 1px rgba(222, 222, 222, 1); */
    color: #9393A7;
    float: left;
    border-radius: 0px;
    margin-bottom: 20px;
    position: relative;
    margin-left: -20px;
}

.mention {
    padding: 20px;
    background-color: #fffffe;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    margin-bottom: 10px;
}

section.mention-box .header-enterprise {
    padding: 24px 24px 0 24px;
    clear: both;
    width: 100%;
    float: left;
}

.header-enterprise {
    flex-grow: 1;
}

section.mention-box .header-enterprise .avatar {
    border-radius: 50%;
    margin-top: 0.5%;
    max-width: 50px;
    height: auto;
    position: relative;
    float: left;
    margin-right: 5px;
}

.mentions_box a {
    text-decoration: none;
}

section.mention-box .header-enterprise .avatar img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: block;
    overflow: hidden;
    border: solid 1px #e3e3e3;
    cursor: pointer;
}

input[type=color]{
	width: 40px;
	height: 40px;
	border: none;
	background: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border-radius: 50%;
    box-shadow: 0 0 5px;
}
.pdfcoloroption{
    height: 90px;
    transition: transform .2s;
    width: 150px;
}

.btndisabled:hover{
    background-color: #0079c0;
}
.btn.btn-primary.btndisabled:active, 
.btn.btn-primary.btndisabled:focus, 
.btn.btn-primary.btndisabled:hover {
    box-shadow: none;
    color: #fff!important;
    background-color: #0079c0;
}
.pdfcoloroption:hover{
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Safari 3-8 */
    transform: scale(2); 
}
.pdfpagetitle{
    color: grey !important;
    padding-bottom: 8px
}

.ant-form-item{
    box-sizing: border-box;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    vertical-align: top;
    padding: 24px;
    padding-bottom: 8px;
}
.margin-24{
    margin: 24px
}
.margin12{
    margin: 12px;
}
.margin-6{
    margin: 6px;
}
.margin-bottom24{
    margin-bottom: 24px
}
.margin-bottom0{
    margin-bottom: 0
}
.margin-bottom12{
    margin-bottom: 12px
}
.margin-bottom6{
    margin-bottom: 6px
}
.margin-bottom48{
    margin-bottom: 48px
}
.compareMainheaderIcon{
    font-size: 14px !important;
    width: auto;
    margin-right: 8px;
}
.compareListLogo{
    width: 30px;
    height: 30px;
    position: relative;
    transition: all 0.3s;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    background-size: contain; 
    margin-right: 12px;   
}
.borderCompareList{
    align-items: center;
    margin: 6px;
    padding: 6px;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    filter: grayscale(1);
}
.borderCompareListSelected{
    filter: grayscale(0);
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
    border-color: #40a9ff;
}
.visibilityHidden{
    visibility: hidden;
}
.pdfInput:hover{
    border-color: #40a9ff
}
.pdfInput:focus{
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
}
.customReportIcon{
    height: 18px;
    width: 18px;
    margin-right: 12px;
}
section.mention-box .author {
    float: left;
    width: 74%;
    overflow: hidden;
    margin-top: 1%;
}

.mention-title {
    margin-top: 5%;
    margin-bottom: 0px;
    background: white;
    padding: 10px 20px 0;
    max-height: 130px;
    margin: 0 0 0 10px;
    line-height: 15px;
}

.mention-title>h4 {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 15px;
}

.mention-title>span {
    font-size: 12px
}

.overall {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 40px;
}

section.mention-box .author .mention-title-box {
    width: auto;
    /* max-width: 100%; */
    overflow: hidden;
    height: 20px;
    line-height: 20px;
    padding-top: 0;
    margin-top: 0px;
    text-align: left;
}
section.mention-box .author .mention-title-box{
    height: unset;
}

.cursorPointer{
    cursor: pointer;
}
.applyFilterBtn:hover{
    color:#0079c0
}
.applyFilterBtn:focus{
    border-color:#888 !important;
    opacity: 0.8;
}
.graphInPercent, .graphInNumber{
    font-size: 12px !important;
}
.resendMailButtonColor span{
    color:unset !important
}
section.mention-box .author .mention-title-box>a {
    cursor: pointer;
}

section.mention-box .author .mention-title-box>i {
    font-size: 16px;
}

section.mention-box .author .mention-title-box>a {
    cursor: pointer;
}

section.mention-box .author .mention-title-url {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    height: 22px;
    line-height: 22px;
    padding-top: 0;
    margin-top: 0px;
    text-align: left;
}

.mention-title-url i {
    font-size: 12px;
}
.mention-title-url img{
    padding-right: 3px;
}

.mention-title-url>span {
    font-size: 12px !important;
    color: #9393A7 !important
}

section.mention-box .author span {
    color: #232323;
    font-size: 14px;
}

.mention-title-box span {
    margin-left: 1%;
}

.mentions_box a {
    text-decoration: none;
}

.mention-inside {
    background-color: #fff;
    height: 100%;
    padding: 0 24px 24px 24px;
}

.mention-inside-detail {
    background-color: #fff;
    margin-bottom: 10%;
    height: 100%;
    padding: 10px;
    text-align: center;
    border: 1px solid;
    color: #545454;
    font-weight: 700;
}

section.mention-box .author i {
    padding-right: 5px;
    color: #4CAF50 !important;
}

.fa-twitter::before {
    content: "\f099";
}

section.mention-box .author p.followers-count {
    text-transform: lowercase;
    float: left;
    top: 2px;
}

section.mention-box .author p {
    margin: 0;
    margin-left: 0px;
    display: inline-flex;
    font-size: 12px;
    padding-left: 15px;
    margin-left: 15px;
    position: relative;
}

.mentions_box p {
    margin: 0;
}

section.mention-box .author p .followers-count-no {
    text-transform: uppercase;
    margin-right: 5px;
}

section.mention-box .author p::after {
    content: "•";
    position: absolute;
    left: 0;
}

section.mention-box .author .visited-box {
    position: relative;
    left: 0;
    top: 0;
    height: 45px;
    width: 45px;
    z-index: 999;
}

section.mention-box .author .mention-date,
section.mention-box .author .mention-source {
    font-size: 12px !important;
    color: #545454 !important;
    position: relative;
    right: 0px;
    line-height: 18px;
    /* top: 2px; */
    width: 100%;
    float: left;
    text-align: left;
}

section.mention-box .author i {
    padding-right: 5px;
    color: #0079c0 !important;
}

section.mention-box .author i.fa-youtube-play {
    color: #ff0000 !important;
}

section.mention-box .author i.fa-twitter {
    color: #3190f6 !important;
}

section.mention-box .author i.fa-facebook-official {
    color: #5054b4 !important;
}

.fa-calendar::before {
    content: "\f073";
}

section.mention-box .mention-information {
    float: left;
    width: 88%;
    font-size: 12px;
    margin: 8px 0 0;
}

section.mention-box .mention-information li:first-child {
    border: none;
    padding-left: 0;
    margin-left: 0;
    color: #0079c0;
}

section.mention-box .mention-information li {
    float: left;
    margin-left: 20px;
    padding-left: 20px;
    border-left: solid 1px #ededed;
    height: 20px;
    line-height: 19px;
}

.mentions_box ul li {
    list-style: none;
}

section.mention-box .mention_source {
    width: auto !important;
    /* display: block; */
    overflow: auto;
    margin-right: 0px !important;
    color: #0079c0;
}

.fa-long-arrow-up {
    color: green;
    vertical-align: middle !important;
    padding-bottom: 3px;
}

.fa-long-arrow-down {
    color: red;
    vertical-align: middle !important;
    padding-bottom: 3px;
}

.mentions_box a {
    text-decoration: none;
}

.fa-share-alt::before {
    content: "\f1e0";
}

section.mention-box .mention-information li {
    float: left;
    margin-left: 20px;
    padding-left: 20px;
    border-left: solid 1px #ededed;
    height: 20px;
    line-height: 19px;
}
.twitterBox{
    background: #2dc7fc;
}
.fbBox{
    background: #3b599a;
}
.youtubeBox{
    background: #e44028;
}
.instaBox{
    background: radial-gradient(circle farthest-corner at 20% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

}
.socialBox{
    color: white;
    height: 44px;
    font-size: 24px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin: 12px 0
}

.switchSlider {
    position: relative;
    display: inline-block;
    width: 60px;
}
.switchSlider input { 
    opacity: 0;
    width: 0;
    height: 0;
}
  
.switchSlider .toggleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.switchSlider .toggleSlider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .toggleSlider {
    background-color: #00DAFC;
}
  
input:focus + .toggleSlider {
    box-shadow: 0 0 1px #00DAFC;
}
  
input:checked + .toggleSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  
  /* Rounded sliders */
  .switchSlider .toggleSlider.round {
    border-radius: 34px;
}
  
.switchSlider .toggleSlider.round:before {
    border-radius: 50%;
}
.width100{
    width: 100%;
}
.pdfDropDownExtraSettingsIcon{
    color: #0079c0;
    padding: 5px 12px;
    width: auto;
}
.reportFiltersApplied{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.reportFiltersApplied i.fa.fa-filter{
    color: #0079c0;
    width: auto;
    font-size: 24px;
    margin-right: 12px;
}
.reportExcelList ol{
    column-count: 2;
    column-gap: 48px;
    padding-left:15px
}
.flex-justify-between {
    display: flex;
    justify-content: space-between
}
.pdfdropDownSettings a.collapsed[aria-expanded=false]::before{
    content:none;
}
.pdfdropDownSettings a[aria-expanded=true]::before{
    content:none;
}
.reportFilterItem{
    padding: 0 15px 30px;
    max-width: 25%;
    margin:0
}
.reportFilterItem .form-check-label{
    max-width: unset;
}
.reportbutton>button.generatepdfButton{
    width: fit-content;
}
.pdfPagesOptionStyle{
    border: 1px solid #d3d3d3;
    padding: 12px !important;
    border-radius: 4px;
    width: 95%;
}
.outerSocialBox{
    width:100%;
    margin-right:12px;
    justify-content:space-evenly;
}
.addSocialHandle{
    justify-content: center;
    font-size: 24px;
    align-items: center;
    color: #00dafc;
    margin-left: 8px;
    display: flex;
}
.addSocialHandle i{
    color: #0079c0;
    font-size: 24px;
    cursor: pointer;
}

.onboardingOption>li.active {
    color:#4285f4;
    text-transform: uppercase;
    text-align: center;
    height: 48px;
    min-width: 25%;
    border-bottom: 2px solid #4285f4;
    z-index: 1
}
.handleInput{
    width: auto
}
.onboardingOptionListItem{
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    width:50%;
    text-align:center;
    font-weight:500;
    margin: 0 !important
}
.onboardingOptionListItem:hover{
    background: aliceblue;
    transition: all 0.5s
}
.new-onboard-div{
    background: #fff;
    min-height:79vh
}
.padding-4side{
    padding: 0 4px
}
#step1right{
    min-height: 50%;
    min-width: 400px;
    justify-content: space-evenly
}
.handleName{
    max-width: 15ch;
    word-wrap: break-word;
}
.onboardingStep{
    width: 100%;
    min-height: inherit;
    padding: 0 48px 24px;
    justify-content: center
}
.font-size12{
    font-size: 12px;
}
.modalVerifyEmailPadding{
    padding: 48px !important;
}
.onboardingStep>div{
    max-width:70%;
    min-width: 50%;
}
.addExtraKeyword{
    justify-content: center;
    font-size: 24px;
    align-items: center;
    color: #00dafc;
    margin-left: 8px;
    display: flex;
}
.addedKeywords,.availableKeyword{
    padding: 4px !important;
    font-size: 14px !important;
}
.justify-between{
    justify-content: space-between
}
.align-center{
   align-items: center
}
.text-overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
}
.overflow-hidden-nowrap{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#step3 .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 1001;
    color: #545454;
    margin:0 2%;
    left: 0;
    right: 0;
    max-height: 150px;
    overflow-y: auto;
}
#step3 .autocomplete-items div {
    padding: 5px 2px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: .05px solid #d4d4d4;
    text-align: center;
}
#step3 .autocomplete-items div:hover {
    background-color: #00dafc;
    border-bottom: 1px solid rgba(255,255,255,.5);
}
#step3 .autocomplete-active {
    background-color: #00dafc !important;
    color: #ffffff;
  }
.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    margin-bottom: 12px;
    /* border-radius: 7px; */
}
.alert.alert-danger{
    border-radius: 7px;
}
.font-size14{
    font-size: 14px
}
.mentions_box ul li {
    list-style: none;
}
.brandImage{
    object-fit: contain;
    width: 100px; 
    height: 100px;
}
.border-round{
    border-radius: 30px !important
}
.iconPadding{
    padding: 0px 12px 6px 0
}
.margin0{
    margin: 0 !important
}
.activeHandle{
    transition: all 0.3s ease 0s;
    box-shadow: 3px 3px 5px grey;
    filter: grayscale(0) !important
}
.padding-side12{
    padding: 0 12px
}
.padding6{
    padding: 6px
}
#extraKeyword{
    width: fit-content;
    display: inline-block;
    margin: 0;
    margin-left: 12px;
    padding: 4px !important;
    height:auto
}
#availableKeywords{
    display: flex;
    flex-wrap: wrap
}
.shadowAddButton{
    filter: drop-shadow(3px 3px 4px black);
    transition: 0.2s;
}

.onboardingSubmitList{
    width: 25%;
}
.onboardingSubmitList li{
    border: 1px solid #E3E3E3;
    padding: 8px 12px;
    border-radius: 4px;
    position: relative;
    animation-name: slideIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;
}
/* .onboardingSubmitList:nth-child(1){
    animation-delay: 1s;
} */
.onboardingSubmitList li:nth-child(2){
    animation-delay: 0.5s;
}
.onboardingSubmitList li:nth-child(3){
    animation-delay: 1s;
}
.onboardingSubmitList li:nth-child(4){
    animation-delay: 1.5s;
}
.onboardingSubmitList li:nth-child(5){
    animation-delay: 2s;
}
.onboardingSubmitList li:nth-child(6){
    animation-delay: 2.5s;
}
.onboardingSubmitList li:nth-child(7){
    animation-delay: 3s;
}
.onboardingSubmitList li:nth-child(8){
    animation-delay: 4s
}
.onboardingSubmitList li:nth-child(9){
    animation-delay: 4.5s
}
@keyframes slideIn {
    from {
        left: 30%;
      opacity: 0;
    }
    to {
        left:0;
      opacity: 1;
    }
  }
/* td b,
td strong {
    font-weight: 500 !important;
} */
@media (max-width:480px){
    .modalVerifyEmailPadding{
        padding: 24px !important;
    }
    .pdfPagesOptionStyle{
        border: 1px solid #d3d3d3;
        padding: 6px !important;
        border-radius: 4px;
        width: 100%;
    }
    .reportFilterItem{
        width: 100%;
        max-width: 100%;
    }
    .reportExcelList ol{
        column-count: 1;
        padding:24px
    }
    .onboardingStep{
        width: 100%;
        min-height: inherit;
        padding: 12px;
        justify-content: center
    }
    #step1right{
        min-width: 100%
    }
    .onboardingOption>li.active{
        height: 60px;
    }
    .onboardingStep>div{
        max-width: 100%;
    }
    .showingFilterList{
        margin-left: 10px !important;
    }
    .onboardingSubmitList{
        width: 100%;
    }
    .brandinfo{
        flex-direction: column-reverse
    }
    .socialIcon{
        padding-bottom: 12px
    }
    .onboard-div{
        min-height: 85vh
    }
    #step3 .autocomplete-items{
        margin: 0 4%
    }
}
section.mention-box .influence-score {
    height: 5px;
    background: #f5f5f5;
    margin-bottom: 5px;
    border-radius: 3px;
    width: 40px;
    display: inline-block;
    margin: 0px 0 0 5px;
}

.influence-score {
    height: 5px;
    background: #f5f5f5;
    margin-bottom: 5px;
    border-radius: 3px;
}

.infl-social-contain {
    text-align: center;
    display: block;
    display: flex
}

img.svg-icon {
    max-width: 14px;
}

section.mention-box .influence-score span {
    border-radius: 3px;
    display: block;
    height: 5px;
    border-radius: 3px;
    background: #0FB36C;
    width: 40%;
}

.influence-score span {
    border-radius: 3px;
    display: block;
    height: 5px;
    border: none;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, .2);
    background-color: #0FB36C;
}

section.mention-box .mention-information li {
    float: left;
    margin-left: 20px;
    padding-left: 20px;
    border-left: solid 1px #ededed;
    height: 20px;
    line-height: 19px;
}

.mentions_box ul li {
    list-style: none;
}

section.mention-box .mention-information select {
    background: #fff;
    padding: 2px 20px 4px 6px;
    font-size: 12px;
    border: solid 1px #babac8;
    color: #babac8;
    font-size: 11px;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    font-weight: 400;
    background: url('../img/sentiment-arrows.png') no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    background-position: 93% 4px;
    width: auto;
    text-align: center;
    height: 24px;
}

section.mention-box .mention-information p,
section.mention-box .mention-information select {
    display: inline-block;
}

.author>.score {
    float: left;
    height: 20px;
    font-size: 12px;
    text-align: left;
}
.filter-list li .form-check{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0;
}
.filter-list li .form-check label{
    margin-bottom: 0px;
}
.verify-person {
    text-align: right
}

.summary_option>ul>li>a {
    font-size: 11px;
}
.overflowEllipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mentions_box ul li {
    list-style: none;
}

section.mention-box .mention-information .social-stats p {
    margin: 0 20px 0 0;
    line-height: 22px;
}

section.mention-box .mention-information p,
section.mention-box .mention-information select {
    display: inline-block;
}

.mentions_box p {
    margin: 0;
}

section.mention-box .mention-information .social-stats p:last-child {
    margin: 0;
}

section.mention-box .description {
    font-size: 13px;
    padding: 0 24px 24px 24px;
    width: 100%;
    line-height: 20px;
    float: left;
    height: 150px;
    min-height: 150px;
    color: #9393A7;
}

section.mention-box .description p {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 14px;
    line-height: 21px;
    color: #3E4144;
    font-weight: 400;
    overflow: hidden;
    height: 67px;
}

.mentions_box p {
    margin: 0;
}

.mention_text {
    color: #000000;
    line-height: 22px;
    font-size: 14px;
    font-weight: 400;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    display: inline-block;
    width: 100%;
    letter-spacing: 0.4px
}

.followBlock {
    display: inline-block;
    padding: 8px 16px;
    margin: 0px 10px 0px 0px;
    border: 2px solid #0099ff;
    color: #000;
}
.followBlockDetail {
    display: inline-block;
    padding: 8px 16px;
    margin: 0px 10px 0px 0px;
    border: 2px solid #0099ff;
    color: #000;
}

.detail-recent-image{
    max-width: 100px;
    padding-right: 12px;
    margin-bottom: 10px
}
.rankContainer{
    width: 45%;
    margin-bottom: 24px
}

.mention_text b {
    font-weight: 600 !important;
}

section.mention-box .options {
    border-top: solid 1px #e3e3e3;
    padding: 0 10px;
    font-size: 13px;
    float: left;
    width: 100%;
    clear: both;
    line-height: 36px;
}

section.mention-box .options ul {
    padding: 0;
    margin: 0 !important;
    float: left;
}

section.mention-box .options ul li {
    position: relative;
    cursor: pointer;
}

section.mention-box .options ul li {
    float: left;
    margin: 0 5px;
}

section.mention-box .options ul li.mentionOptionList {
    position: relative;
    cursor: pointer;
    float: left;
    margin: 0;
    border-right: solid 1px #e3e3e3;
    
}
.sidebarScroll{
    position:sticky;
    top:0;
    height:100vh;
    overflow-y:auto
}

body.modal-open .sidebarScroll{
    position:static;
}
li.sentimentUserLiDiv {
    width: 100%;
}

.mentions_box ul li {
    list-style: none;
}

section.mention-box .options .select {
    display: block;
    float: right;
    margin-top: 10px;
}

section.mention-box input[type="checkbox"] {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #fff;
    border: solid 1px #e3e3e3;
    border-radius: 3px;
    cursor: pointer;
}

.detail-recent-video-image{
    max-width: 54px;
    padding-right: 12px;
    margin-bottom: 10px;
}
.sign {
    height: 10px;
    width: 10px;
}

.desktop>.container-fluid>.navbar-header {
    margin-top: 5px;
}

.show-read-more .more-text {
    display: none;
}

.description p a {
    color: #0079c0;
}

.description>p {
    margin: 0 !important;
}

.header-enterprise .score {
    line-height: 0;
    margin-bottom: 5px;
}

/* for highlight of description */

span.highlight {
    padding: 2px 8px;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
}

span.detail-highlight {
    padding: 2px 8px;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
    margin: 2px 2px;
    display: inline-block;
}

.flex-header-enterprise {
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.summaryDescribeImageSize {
    margin-bottom: 2%;
    max-width: 100px;
    max-height: 125px;
}

li.open a.dropdown-toggle[aria-expanded="true"]::before {
    display: none;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

.opacityOnFilter {
    opacity: .9;
    text-shadow: 2px 2px 2px grey;
}

.sidebarProjectNameMobile {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .footer nav.pull-left {
        float: left !important;
    }
    .mention-inside p.words {
        margin-bottom: 0;
    }
    .mention-inside span {
        padding: 3% 0;
    }
    /* .navbar-nav>li {
        float: left !important;
    }
    .navbar-nav {
        margin: 1px 0;
        float: right !important;
    } */
}

@media (min-width: 960px) {
    .first-top-nav .navbar-collapse,
    .first-top-nav .navbar-header,
    .first-top-nav .navbar-right {
        padding-top: 0px !important;
    }
}

@media(min-width:768px) {
    .margininGraphright {
        margin: 0 0 0 10px !important
    }
    .margininGraphleft {
        margin: 0 10px 0 0 !important
    }
    .summarysmlowerpost {
        margin: 0 -35px 0 -5px;
    }
    .summarysmupperpost {
        margin: 0 0 0 -10px;
    }
    .navbar-header {
        float: left !important;
    }
}

@media (min-width: 320px) and (max-width:1024px) {
    #sidebar ul li.panel_suboption a {
        text-align: center !important;
        /* color: #fff; */
    }
    .sidebarProjectNameMobile span {
        font-size: 18px
    }
    #sidebar {
        width: 50px;
        left: 0;
        float: left;
        z-index: 999;
        background: #1f2322;
        color: #fff;
        transition: all .3s;
        min-height: 100vh;
    }
    #sidebar.active {
        margin-left: -50px;
    }
    #content.active {
        width: 100%;
    }
    #content {
        width: calc(100% - 50px);
        min-height: 100%;
        transition: all .3s;
        top: 0;
        right: 0;
        float: right;
    }
    .panel_suboption a {
        font-size: 20px !important;
    }
    .panel_suboption {
        padding: 10px 5px;
        font-weight: 500;
        font-size: 14px;
        color: #d8d8d8;
        text-align: left;
    }
}

@media screen and (max-width:767px) {
    .mention-title {
        background: #fff;
        padding: 10px 20px 0;
        height: 135px;
        margin: 0 10px;
    }
    .margininGraphright {
        margin: 0 10px !important
    }
    .margininGraphleft {
        margin: 0 10px !important
    }
    .summarysmlowerpost {
        margin: 0 -5px 0 -5px;
    }
    .summarysmupperpost {
        margin: 0 10px 0 -10px;
    }
}

@media screen and (max-width:1025px) {
    .panel_suboption .fa {
        text-align: center;
        width: 16px;
        /* color: #fff; */
    }
    .panel_suboption .fa:hover,
    .panel_suboption .fa:focus,
    .panel_suboption .fa:active {
        color: #545454;
    }
    /* .tooltip .tooltiptext {
        font-size: 14px;
        left: -17vh;
        z-index: 1;
        width: 200px !important;
        font-family: "Roboto", Arial, sans-serif;
    }
    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 91%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
        font-family: "Roboto", Arial, sans-serif;
    } */
}

@media(max-width:1024px) {
    #showAllProjectClick {
        max-width: 256px;
    }
    section.mention-box .author .mention-date,
    section.mention-box .author .mention-source {
        font-size: 10px !important;
    }
    .author>.score {
        font-size: 10px;
    }
    section.mention-box .author span {
        color: #232323;
        font-size: 14px;
        font-weight: 300 !important;
    }
    section.mention-box author .mention-title-box>i {
        font-size: 14px;
    }
    panel_suboption .fa {
        text-align: center;
        width: 16px;
        width: 100%;
    }
    .panel_suboption a {
        width: 100%;
        text-align: center;
    }
    li.panel_suboption a {
        padding: 0 10px !important;
    }
    .panel_suboption {
        padding: 10px 5px;
        font-weight: 500;
        font-size: 14px;
        color: #d8d8d8;
        text-align: left;
    }
    li.open a.dropdown-toggle[aria-expanded="true"]::before {
        content: '\e260';
        display: none;
        position: absolute;
        right: 20px;
        font-family: 'Glyphicons Halflings';
        font-size: 0.6em;
    }
    .mentionOptionList.open .dropdown-toggle a[aria-expanded=true]::before {
        content: '\e260';
        display: none;
        position: absolute;
        right: 20px;
        font-family: 'Glyphicons Halflings';
        font-size: .6em;
    }
    section.mention-box .options ul li {
        position: relative;
        cursor: pointer;
        float: left;
        margin: 0 12px;
    }
    section.mention-box .description {
        font-size: 13px;
        padding: 0 24px 24px;
        width: 100%;
        line-height: 20px;
        float: left;
        min-height: 235px;
        overflow: hidden;
        color: #9393A7;
    }
    section.mention-box .header-enterprise .avatar img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: block;
        overflow: hidden;
        border: 1px solid #e3e3e3;
    }
    section.mention-box .author {
        float: left;
        width: 70%;
        overflow: hidden;
        margin-top: 1%;
    }
    section.mention-box .header-enterprise {
        padding: 0 24px 24px 24px;
        clear: both;
        width: 100%;
        float: left;
    }
    ul.sm-projectSelectNames {
        display: none;
        position: absolute;
        z-index: 10;
        padding: 10px 0;
        max-width: 256px;
        top: 10px;
        /* margin-left: -25px; */
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        background-clip: padding-box;
    }
    .sm-projectSelectNames li {
        padding: 0 15px;
    }
    .sm-projectSelectNames li a {
        color: #0079c0;
        border-radius: 0;
        text-transform: capitalize;
        font-size: 18px;
    }
    .summaryDescribeImageSize {
        width: 80px;
        height: 100px;
    }
    .summary_option>ul>li>a .fa {
        font-size: 16px;
        text-shadow: 0 1px 10px rgba(0, 0, 0, .15);
    }
    .summary_option>ul>li>a {
        font-size: 11px;
        padding: 0 5px;
    }
    .smremoveMargin {
        margin-bottom: 0 !important
    }
}

@media (min-width:480px) and (max-width:1024px) {
    .mentioncontainer {
        width: 95%;
        display: flex;
    }
    .header-enterprise>.header-title>a {
        font-size: 18px;
        color: #000
    }
}

/* @media (min-width:992px) {
    .firsttimeUserHintModal {
        margin-left: -256px;
    }
} */

@media screen and (max-width: 767px) and (min-width:481px) {
    .form-control {
        width: 100%;
    }
}

@media(max-width:959px) {
    .content dashboard .container-fluid .wrapper {
        top: -8px;
    }
    body>header.header {
        display: none;
    }
    body>#content-dashboard {
        margin-top: 0px;
    }
}

@media(min-width:960px) {
    .wrapper {
        top: -80px;
        display: flex;
        margin-bottom: 0;
    }
    .mentioncontainer {
        width: 100% !important;
        display: flex;
    }
    .mentioncontainer .header-enterprise {
        margin-left: 10px;
    }
}

/* plotly graph css used */

/* *******************************featureAttribution.pug*************************************** */

.top_mention_keywords {
    padding: 5px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 800;
    white-space: nowrap;
    margin: 5px 10px;
    cursor: pointer;
}

.feature_header {
    clear: both;
    width: 100%;
    flex-grow: 1;
}

.feature_ProfilePic {
    border-radius: 50%;
    max-width: 50px;
    height: auto;
    position: relative;
    float: left;
    margin-right: 20px;
}

.feature_ProfilePic>img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    overflow: hidden;
    border: 1px solid #e3e3e3;
}

.feature_ProfileName {
    overflow: hidden;
}

.feature_title_box {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    height: 25px;
    line-height: 28px;
    padding-top: 0;
    margin-top: -5px;
    text-align: left;
}

.feature_title_box span {
    color: #000;
    font-size: 14px;
    font-weight: 300 !important;
}

.feature_des_box p {
    color: #323232;
    margin-bottom: 0px;
    overflow: hidden;
    font-size: 14px;
}

.flexwrap {
    display: flex;
    flex-wrap: wrap
}

.most-talked-alltype-container {
    padding: 12px;
    background: #fff;
    margin-bottom: 12px;
}

.dg_allkeywords {
    margin: 0;
    padding: 24px 12px;
    border-top: 1px solid #ddd;
}

.dg_sm_allkeywords {
    margin: 0;
    padding: 24px 0;
    border-bottom: 1px solid #ddd;
}

.dg_titleContainerHeight {
    /* height: 150px; */
    overflow: hidden;
}

.dg_titleContainer h4 {
    font-size: 14px !important;
}

.dg_titleContainer span {
    font-size: 12px !important;
}

.dg_sm_titleContainer {
    line-height: 16px;
}

.dg_sm_titleContainer h4 {
    font-size: 14px !important;
}

.dg_sm_titleContainer span {
    font-size: 12px !important;
}

.dg_sm_allkeywords>h4 {
    font-size: 16px;
}

.dg_geographBox h6 {
    margin-bottom: 0;
    background: #fff;
    margin: 0 24px;
    padding: 12px;
}

.wrapper p.dg-no-clear-data {
    margin-bottom: 0;
    font-size: 12px;
    color: #eb5a46;
    padding: 0 24px;
}

.dg_sm_geographBox h6 {
    margin-bottom: 0;
    background: #fff;
    padding: 10px;
}

#trendingHashtagOverLocation>svg>g>text,
#trendingEntitiesOverLocation>svg>g>text {
    cursor: unset !important;
}

.graph-head-div {
    margin-bottom: 10px;
    background-color: white;
    padding: 24px 48px;
}

.graph-head-div>h4 {
    font-size: 22px;
    color: #000;
    margin-bottom: 10px
}

#mentionsOverTimeBySentiment>.plot-container,
#mentionsOverSocial>.plot-container,
#mostTalkedFeatures>.plot-container,
.wordcloud>#TrandingHashtagOverLocation,
.wordcloud>#TrandingHashtagOfBrand {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#mentionsOverSocial>.plotly>.svg-container>.main-svg:first-child {
    padding-left: 40px !important;
}

.mentionGraph {
    background: #fff;
    margin: 0;
}

.mentionGraph p a {
    color: #0079c0;
    font-size: 12px;
}

.cloud-talk {
    margin: 0 0 0 24px;
    padding: 24px 24px 12px 24px;
    max-height: 130px;
}

.cloud-talk>h4 {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
}

.summarysetmargin {
    margin: 0 10px !important;
}

@media (max-width:375px) {
    section.mention-box .influence-score {
        display: none
    }
}

/*================ influrncer========== */

@media (max-width:480px) {
    #sidebar ul li a:hover {
        /* background: #fff; */
        color: #0079c0 !important;
    }
    .firstTimeMessageImageDiv {
        flex-direction: row;
        display: flex;
        justify-content: center;
    }
    
    .sm-projectSelectNames li a span {
        font-size: 14px;
    }
    .sm-projectSelectNames li a span.sidebarDaysLeft {
        font-size: 10px;
    }
    .videodesc {
        padding: 0;
        text-align: left;
    }
    .cloud-talk>h4 {
        letter-spacing: .5px;
        font-size: 18px;
        line-height: 1em;
        color: #000;
        text-align: left;
        margin-bottom: 10px;
    }
    .cloud-talk {
        margin: 0;
        padding: 15px 0;
        min-height: unset;
        max-height: unset
    }
    .mentionGraph {
        background: #fff;
        margin: 0;
    }
    .frequencyOption#frequency {
        width: 100%;
    }
    .header-enterprise .header-title {
        text-transform: capitalize;
        color: #000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    section.mention-box .options>ul {
        padding: 0;
        margin: 0 !important;
        float: left;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    section.mention-box .options ul li.mentionOptionList {
        position: relative;
        cursor: pointer;
        flex-grow: 1;
        float: left;
        margin: 0;
    }
    #showAllProjectClick {
        max-width: 150px;
    }
    /* .header-enterprise {
        margin-bottom: .5%;
        margin-right: 0;
        flex-grow: 1;
        margin-top: 1.5%;
    } */
    .sm-projectSelectNames li {
        padding: 0 8px;
    }
    section.mention-box .description p {
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        font-size: 12px;
        line-height: 21px;
        color: #3E4144;
        font-weight: 400;
        overflow: hidden;
        height: 67px;
    }
    .description p a {
        color: #0079c0;
        font-size: 12px;
    }
    section.mention-box .header-enterprise .avatar {
        border-radius: 50%;
        margin-top: .5%;
        max-width: 30px;
        height: auto;
        position: relative;
        float: left;
        margin-right: 20px;
    }
    section.mention-box .header-enterprise .avatar img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: block;
        overflow: hidden;
        border: 1px solid #e3e3e3;
    }
    section.mention-box .options ul li {
        position: relative;
        cursor: pointer;
        float: left;
        margin: 0;
    }
    section.mention-box .header-enterprise {
        padding: 5px 12px 0px 12px;
        clear: both;
        width: 100%;
        float: left;
    }
    section.mention-box .description {
        font-size: 13px;
        padding: 0 12px;
        width: 100%;
        line-height: 20px;
        float: left;
        min-height: 75px;
        height: 100%;
        overflow: hidden;
        color: #9393A7;
    }
    .description .marginzero {
        margin: 0
    }
    .summaryDescribeImageSize {
        width: 50px;
        height: 60px;
    }
    .wrapper .navbar {
        background: #fff;
        border-radius: 0;
        margin-bottom: 12px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
    #content .navbar .container-fluid>.navbar-collapse {
        margin-right: -15px;
        margin-left: 0;
        border-color: white;
    }
    ul.sm-projectSelectNames {
        display: none;
        position: absolute;
        z-index: 10;
        padding: 10px 0;
        max-width: 175px;
        top: 10px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
        background-clip: padding-box;
    }
    .sidebarProjectNameMobile span {
        font-size: 14px;
    }
    .summarysetmargin {
        margin: 0 !important;
    }
    .mobile-brandScorediv {
        padding: 0px;
        display: flex;
        flex-direction: column;
        background: #0079c0;
        justify-content: center;
    }
    .mobile-brandScorediv h4 {
        font-size: 36px;
        color: #fff
    }
    .mobile-brandScorediv span {
        color: #fff;
        font-size: 14px;
        font-weight: 600
    }
    .mobile_summaryPostrow {
        border-bottom: 1px solid #eceff1;
    }
    .mobile_summaryPostrow .sm-post-box {
        background: white;
        padding: 24px 10px;
    }
    spna.mobileTrackingMentionHeader {
        font-weight: 500;
        color: black;
    }
    span.mobileTrackingMentionOptions {
        font-size: small;
        color: #0079c0;
        font-weight: 600;
    }
    .summarysmlowerpost {
        margin: 0 -15px;
    }
    p.words {
        font-size: 20px;
    }
    .summarysmupperpost {
        margin: 0;
    }
    .header-enterprise .header-title>a {
        text-transform: capitalize;
        color: #000;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
    }
    .header-enterprise .header-breadcrumbs span,
    .header-enterprise .header-breadcrumbs span a {
        color: #5c5c5c;
        font-size: 12px;
    }
    .header-enterprise .header-breadcrumbs {
        text-transform: capitalize;
        font-size: 12px;
        color: #d2cccc;
    }
    .panel_suboption {
        padding: 5px 0px;
        font-weight: 500;
        font-size: 12px;
        color: #d8d8d8;
        text-align: left;
    }
    #sidebar ul li.panel_suboption a {
        text-align: center !important;
        /* color: #fff; */
    }
    .mentioncontainer {
        width: 100%;
        display: flex;
    }
    .sm_projectTitlName a {
        font-size: 12px;
    }
    .sm_projectTitlName {
        max-width: 100px;
        overflow: hidden;
    }
    .summary_option>ul>li>a {
        font-size: 11px;
        padding: 0;
    }
    .summarypaddingzero {
        padding: 0;
    }
    .recent-mention {
        /* margin-top: 20px; */
        padding: 0;
        background-color: #eceff1;
    }
    .content .navbar-default .container-fluid>.headbar>.navbar-right {
        padding-right: 0px;
    }
}

/* *************************Mention_detail_view*********************************** */

.detailLable-pop-over {
    left: 0px;
    width: 200px;
    top: 0px;
    z-index: 5;
}

.mainuser {
    display: flex;
    background: white;
    padding: 24px;
}

.videodesc {
    display: inline-block
}

.detailSentiment .POSITIVE {
    color: green !important;
}

.detailSentiment .NEGATIVE {
    color: red !important;
}

.detailSentiment .NEUTRAL {
    color: #0079c0 !important;
}

.sentimentUserLiDetail {
    font-size: 14px;
    padding: 2px 5px;
}

.sentimentUserLiDetail>a {
    margin: 2px 5px;
}

.sentimentfurterUldetail>li:hover {
    background: #fff
}

.sentimentfurterUldetail {
    display: none;
    position: absolute;
    background: #fff;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 8px 16px -4px rgba(9,45,66,.25), 0 0 0 1px rgba(9,45,66,.08);
    border-radius: 3px;
}

/*
nav.detailmainlist ul li.detailmainlistdiv:hover ul.sentimentfurterUldetail {
    display: block;
}
*/

.detailshowClicklist {
    display: block !important;
}

.detail-clickListli>li {
    padding: 10px 20px;
}

.detail-clickListli span,
.detail-clickListli a {
    color: #0079c0;
}

.detail-clickListli>li>a {
    cursor: pointer;
}

.detail-clickListli>li:hover,
.detail-clickListli>li:active,
.detail-clickListli>li:focus {
    background-color: #f1f1f1;
}

.sentimentfurterUldetail>li {
    padding: 10px;
}

.sentimentfurterUldetail>li:hover,
.sentimentfurterUldetail>li:active,
.sentimentfurterUldetail>li:focus {
    background-color: #fff;
}

.detail-clickListli {
    text-align: left;
    padding: 10px 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    background-clip: padding-box;
    opacity: 1 !important;
    visibility: visible !important;
}

.detailmainlist {
    position: absolute;
    width: 175px;
    z-index: 1;
    display: none;
}

.detailMainLabeldiv .showLabelsDropdown {
    position: absolute;
    background: #f5f5f5;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}
.detailLabelDropDownLeft{
    right:110%
}
.detailLabelDropDownRight{
    left:80%
}
.detailSentimentDropDownRight{
    left:90%
}
.detailSentimentDropDownLeft{
    right:90%
}
nav.detailmainlist ul li {
    cursor: pointer;
}

.detail-title {
    width: 100%;
    background: #fff;
    margin-top: 0;
}

.detailengagebtn {
    padding: 5px 16px !important;
    /* margin: 0 10px 0 0 !important; */
    background-color: #09f !important;
    letter-spacing: .4px !important;
    font-size: 14px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    color: white !important;
}

.detailengagebtn:hover,
.detailengagebtn:focus,
.detailengagebtn:active {
    color: white !important
}

.detail-ellipsis-v {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin-left: 24px !important;
}

.detail-onclickblock {
    opacity: 1 !important;
    visibility: visible !important;
}

/* .detail-content>.textdesc>p {
    font-size: 14px;
    word-break: break-word;
    line-height: 21px;
    color: #3E4144;
    overflow: visible!important;
    height: 100%!important;
    padding: 0 2%;
    text-align: left;
    margin-bottom: 1.5em;
    font-weight: 300;
} */

.detail-score>.sociallikes>ul {
    margin-top: 10px;
    border-bottom: 1px solid #e6ecf0;
    border-top: 1px solid #e6ecf0;
    width: 100%;
    margin: 0;
    list-style: none;
    padding: 0;
    overflow: hidden;
    background: #fff
}

.detail-score>.sociallikes>ul>li {
    overflow: hidden;
    float: left;
    margin: 0px 2% !important;
}

.detailcontainer {
    background: #fff;
    margin: 10px 10px 0 10px;
}

/* .detailbody {
    margin: 0 10px;
} */
.mentionDetail{
    padding: 24px 15%;
    min-height: 675px;
}

.detailbody>.row:first-child {
    /* display: flex; */
    background: #fff;
}

.mention-inside-detail>div {
    margin-left: 0px;
    font-size: 1.4rem;
    color: #000
}

.mention-inside-detail .count {
    font-size: 24px;
    margin-bottom: 15px;
    color: #000;
    margin-bottom: 15px;
    font-weight: 700;
}

.detail-title>.mention-title-box {
    text-align: left;
    /* padding: 2% 0 0 2%; */
    line-height: 1;
    padding-right: 2%;
    display: flex;
}

.detail-title>.mention-title-box>.detailtitle>i {
    font-size: 18px !important;
    color: #0079c0 !important;
    vertical-align: middle !important;
    padding-bottom: 10px !important;
}

.detail-title>.mention-title-box>.detailtitle>i.fa-youtube-play {
    color: #ff0000 !important;
}

.detail-title>.mention-title-box>.detailtitle>i.fa-twitter {
    color: #3190f6 !important;
}

.detail-title>.mention-title-box>.detailtitle>i.fa-facebook-official {
    color: #5054b4 !important;
}

.detail-title>.mention-title-box>.detailtitle>span {
    font-size: 18px !important;
    padding-left: 2% !important;
    /* max-height: 55px; */
    width: 100%;
    overflow: hidden;
    /* -webkit-line-clamp: 2; */
    /* -webkit-box-orient: vertical; */
    /* display: -webkit-box; */
    line-height: 1.3;
}
.detailtitle>i{
    line-height: 1.3;
}

/* .detailmore>.detail_drop_ul {
    left: -350% !important;
    padding: 10px 0 !important;
    margin-left: -10px !important;
    background: #fff !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) !important;
    background-clip: padding-box !important;
}

.detail_drop_ul>li>a {
    border-radius: 0 !important;
} */

.mention-title-box>.detailtitle {
    text-align: left;
    padding: 2% 0 0 0;
    line-height: 1;
    /* padding-right: 2%; */
    flex-grow: 1;
    display: flex;
}

.mention-title-box>.detailmore {
    padding: 15px 0 0 15px !important;
    line-height: 1 !important;
}

.detail-content {
    display: block;
}

li>.likesimage {
    max-width: 200px !important
}

li>.likesimage>img {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    margin: 0 2% !important;
    margin-top: 2% !important;
}

.detail-left-div {
    background: #fff;
    padding: 2% 0;
    margin: 15px 0;
}

.detail-commentProfilePic {
    border-radius: 50%;
    margin-top: .5%;
    max-width: 50px;
    height: auto;
    position: relative;
    float: left;
    margin-right: 20px;
}

.detail-commentProfilePic>img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: block;
    overflow: hidden;
    border: 1px solid #e3e3e3;
}

.detail-showAllPost {
    height: auto;
    position: relative;
    float: left;
    margin-right: 20px;
}

.detail-showAllPost>img {
    width: 50px;
    height: 50px;
}

.detailCommentsView hr:first-child {
    display: none;
}

.seedetailcomments {
    cursor: pointer;
}

.seedetailcomments:hover,
.seedetailcomments:active,
.seedetailcomments:focus {
    color: #0079c0;
}

.detail-commentProfileName {
    width: 74%;
    overflow: hidden;
}

.detail-comment-title-box {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    height: 25px;
    line-height: 28px;
    padding-top: 0;
    margin-top: -5px;
    text-align: left;
}

.detail-comment-title-box span {
    color: #232323;
    font-size: 12px;
    font-weight: 300 !important;
}

.detail-comment-source {
    font-size: 12px !important;
    color: #9393A7 !important;
    position: relative;
    right: 0;
    width: 100%;
    float: left;
    text-align: left;
}

.detail-commentProfileName .fa {
    padding-right: 5px;
    color: #0079c0 !important;
}

.detail-header-comment {
    clear: both;
    width: 100%;
    flex-grow: 1;
}

.detail-user {
    background: #fff;
    padding: 2px 0;
    border-top: 2px solid #3190f6;
    display: flex;
}

.detail-user i {
    font-size: 16px;
    color: #3190f6;
}

.detail-user-social {
    display: flex;
}

.detail-user-social>p {
    margin-bottom: 0.5em;
    margin-right: 3%;
}

.detail-score {
    padding: 5px 0px 0px 0px;
}

p.detail-entity {
    font-size: 14px;
}

.detail-score>.options {
    border-top: solid 1px #e3e3e3;
    padding: 0 10px;
    font-size: 13px;
    float: left;
    width: 100%;
    clear: both;
    line-height: 36px;
    overflow: hidden;
}

.detail-score>.options ul {
    padding: 0;
    margin: 0 !important;
    float: left;
    display: flex;
}

.detail-score>.options ul li .featured {
    position: relative;
    float: left;
    margin: 0 10px;
    display: flex;
}

.detail-score>.options ul li {
    border-right: solid 1px #e3e3e3;
}

.detail-score>.options ul li>span {
    width: auto !important;
    display: block;
    overflow: auto;
    margin-right: 0 !important;
    color: #0079c0;
}

/* .detail-score>.options ul li.featured{
        padding: 
} */

.influencerDetailTopAudience{
    width:48%
}

@media (width:768px) {
    .detailLable-pop-over {
        right: 20px;
        width: 170px;
        top: 0px;
        z-index: 5;
    }
    .sentimentfurterUldetail {
        display: none;
        position: absolute;
        right: 160px;
        margin-left: 0;
        background: #fff;
        padding: 10px;
        width: 100%;
    }
    .detailMainLabeldiv .showLabelsDropdown {
        position: absolute;
        margin-left: 0;
        background: #f5f5f5;
        right: 165px;
        padding: 10px;
        width: 100%;
        cursor: pointer;
    }
}

/* ===================extra css============================ */

@media (max-width:480px) {
    .mobile-upgradetext {
        white-space: nowrap;
    }
}

@media (max-width:1024px) and (min-width:481px) {
    .mainuser {
        display: block;
    }
}

.ant-menu-dark.ant-menu-submenu-popup {
    background: transparent;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    color: hsla(0, 0%, 100%, .65);
    background: #001529;
}

.ant-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: #fff;
    border-radius: 4px;
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
    transition: border-color .3s
}

.ant-menu-submenu>.ant-menu {
    background-color: #fff;
    border-radius: 4px;
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
    min-width: 160px;
    padding: 0;
    border-right: 0;
    transform-origin: 0 0;
}

.ant-menu-submenu .ant-menu-sub {
    cursor: auto;
    transition: background .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a {
    color: hsla(0, 0%, 100%, .65);
}


.ui-tooltip.ui-corner-all.ui-widget-shadow.ui-widget.ui-widget-content{
    border: 1px solid #000;
    background: #000;
    padding: 10px;
}
.ui-tooltip.ui-corner-all.ui-widget-shadow.ui-widget.ui-widget-content .ui-tooltip-content{
    color: #fff;
    line-height: 20px;
    font-size: 14px;
}

@media(max-width: 480px){
    .sm-detailpage-engagebox{
        padding: 12px 0;
    }
    .detail-title>.mention-title-box>.detailtitle>span{
        font-size: 16px!important;
    }
    .detail-title>.mention-title-box>.detailtitle>i {
        font-size: 16px!important;
    }
    .waring-action-btn{
        padding-top: 12px;
        display: flex;
        justify-content: space-between;
    }
    .mentionDetail{
        padding: unset;
        min-height: 675px;
    }
    .followBlockDetail{
        margin: 10px 10px 0px 0px;
    }
    .rankContainer{
        width: 100%;
        margin-bottom: 24px
    }
    .mobileInfluencerCircle{
        display: block !important
    }
    .desktopInfluencerCircle{
        display: none
    }
    .influencerDetailTopAudience{
        width:100%
    }
    .detailmainlist {
        right:0
    }
    .detailLabelDropDownRight{
        right:0;
        left:0
    }
    .detailSentimentDropDownRight{
        left:0;
    }
}

.progressBrandedPlain {
    height: 18px;
    border: 1px solid #929298;
    border-radius: 3px;
    position: relative;
}

.progressBrandedPlain .progressMeterBranded-bluePurple {
    background-image: -webkit-gradient(linear, left top, right top, from(#2690ce), to(#5a39a2));
    background-image: linear-gradient(to right, #2690ce, #5a39a2);
}

.progressBrandedPlain .progressMeterBranded {
    display: block;
    height: 100%;
    border-radius: 0;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    max-width: 100%;
}
.progressBrandedPlain .progressMeterBranded-GrayGradient {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(202,202,202,0.5)), to(#cacaca));
    background-image: linear-gradient(to right, rgba(202,202,202,0.5), #cacaca);
}
.progressBarbluePurpleNumberValue{
    color: #5a39a2;
    font-weight: 600;
}
.progressBargrayGradientNumberValue{
    color: #76767d;
    font-weight: 600;
}
@media(max-width:480px){
    .lineHeight12{
        line-height: 12px;
    }
}

.margin-right12{
    margin-right: 12px;
}

.dropdown-downloadChartCSV{
    margin-bottom:0;
    padding: 6px 12px; 
    cursor: pointer;
    width: max-content;
}