.list-type5 {
  margin: 0 auto;
}

.list-type5 ol {
  list-style-type: none;
  margin: 0;
  margin-left: 1em;
  padding: 0;
  counter-reset: li-counter;
}

.list-type5 ol li {
  position: relative;
  margin-bottom: 1.5em;
  padding: 0.5em;
  background-color: #F0D756;
  padding-left: 58px;
}

.list-type5 a {
  text-decoration: none;
  color: black;
  font-size: 15px;
  font-family: 'Raleway', sans-serif;
}

.list-type5 li:hover {
  box-shadow: inset -1em 0 #6CD6CC;
  -webkit-transition: box-shadow 0.5s;
  /* For Safari 3.1 to 6.0 */
  transition: box-shadow 0.5s;
}

.list-type5 ol li:before {
  position: absolute;
  top: -0.3em;
  left: -0.5em;
  width: 1.8em;
  height: 1.2em;
  font-size: 2em;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #6CD6CC;
  transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  -webkit-transform: rotate(-20deg);
  z-index: 1;
  overflow: hidden;
  content: counter(li-counter);
  counter-increment: li-counter;
}

@media (min-width: 641px) {
span.ct-label.ct-vertical.ct-start {
    font-size: initial;
    font-weight: 500;
}
span.ct-label.ct-horizontal.ct-end {
    font-size: 8px;
    font-weight: 500;
}
}
@media (max-width: 640px) {

span.ct-label.ct-vertical.ct-start {
    word-break: break-all;
    /* font-size: smaller; */
    padding-left: 5px;
    font-size: 10px;
    font-weight: 500;
}
span.ct-label.ct-horizontal.ct-end {
    font-size: 8px;
    font-weight: 500;
}
}
.counttext {
  text-align: left;
  vertical-align: middle;
  font-size: large
}

.countnumber {
  font-size: 20px;
  font-weight: 600
}

.boxRed {
  border-style: groove;
  border-color: #d0021b;
  margin: 5px;
}

.boxGreen {
  border-style: groove;
  border-color: #7ed321;
  margin: 5px;
}

.gsearch {
  margin: 5px;
}

.googleSearchH1 {
  font-size: 18px;
  font-family: arial;
  color: #1a0dab;
  line-height: 21.6px;
}

.googleSearchH2 {
  color: #006621;
  font-family: arial;
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
}

.googleSearchH3 {
  color: #333;
  font-size: 13px;
  font-family: arial;
  text-align: left;
  word-wrap: break-word;
}

.nodatafound {
  max-height: 100%;
  max-width: 100%
}

.reviewLogo {
  max-width: 100%;
  max-height: 100%;
}

.searchReviewsBlock {
  align-items: center;
  align-content: center;
  text-align: center;
  height: 100%;
  top: 30%;
}

.ratingText {
  text-align: center;
}

.analysis ul {
  list-style-type: none;
  padding-left: 0px
}

.analysis li img {
  float: left;
  margin: 0 15px 0 0;
}

.analysis img {
  width: 58px;
}

.analysis li:hover {
  background: #eee;
  cursor: pointer;
}

@media (min-device-width: 320px) and (max-device-width: 640px) {
  .reputationScoreBlock h2 {
    font-size: 48px;
  }
  .reputationScoreBlock {
    padding: 50px;
    padding-top: 5px;
  }
  .reputationScoreBlock h1 {
    font-size: 72px;
  }
}

@media (min-width: 768px) {
  .reputationScoreBlock h2 {
    font-size: 80px;
  }
  .reputationScoreBlock {
    padding: 50px;
    padding-top: 5px;
  }
  .reputationScoreBlock h1 {
    font-size: 52px;
  }
}

.loader {
  align-self: center;
  width: 3em;
  height: 3em;
}