.sm-recent-mention {
    padding: 0;
    background-color: #fff;
}

.sm-row-flex {
    flex-wrap: wrap;
    display: flex;
}

.sm-colorovertext {
    padding: 2px 10px;
    font-size: 16px !important;
    border-radius: 50px;
}

.mention-inside span.sm-widgetTitle {
    margin-left: 0;
    padding: 4px 12px;
    letter-spacing: .5px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1em;
    color: #fff;
    text-align: left;
}

.mobile-feature-headingdiv {
    width: 100%;
    padding: 8px 12px;
    font-weight: 600;
    background: #0079c0;
}

.sm-post-box>h3 {
    margin-bottom: 0;
    font-size: 18px;
    text-align: center;
    margin-top: 0;
}

.sm-panel_big_holder .mention-inside p.words {
    margin: 5px 12px;
    padding: 4px 12px;
}

.sm-panel_big_holder .mention-inside-featureAttribution p.words {
    margin: 5px 12px;
    padding: 4px 12px;
}


.mention-inside-featureAttribution span.sm-widgetTitle {
    margin-left: 0;
    padding: 0;
    letter-spacing: .5px;
    font-size: 16px;
    line-height: 1em;
    color: #000;
    text-align: left;
}

.mention-inside-featureAttribution {
    background-color: #fff;
    height: 100%;
    padding: 15px;
    border: none;
}

.mention-inside-featureAttribution span.sm-widgetTitle {
    margin-left: 0;
    padding: 0;
    letter-spacing: .5px;
    font-size: 18px;
    line-height: 1em;
    color: #fff;
    text-align: left;
}

.mention-inside-featureAttribution p.words {
    margin-bottom: 0;
    word-break: break-word;
    line-height: .9em;
    text-align: left;
}

.mention-inside-featureAttribution span {
    padding: 3% 0;
}

#lableAlertMessageOnMobileView {
    display: none;
    width: 60%;
    position: fixed;
    z-index: 1000;
    top: 10px;
    right: 10px;
    padding: 5px;
    left: unset;
}

.analysisBtnContainer {
    display: flex;
    padding: 15px;
    background: #fff;
    justify-content: space-between;
}

.analysisBtnDiv a {
    font-size: 10px;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 5px;
    font-weight: 600;
}

@media (min-width:768px) {
    .analysisBtnDiv a {
        font-size: 18px;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 10px;
        font-weight: 600;
    }
    .analysisBtnContainer {
        display: flex;
        padding: 15px;
        background: #fff;
        margin: 5px 24px;
        margin-top: 0;
        justify-content: space-between;
    }
}

@media (min-width:480px) {
    .analysisBtnDiv a {
        font-size: 14px;
        padding: 8px;
        display: flex;
        height: 100%;
        align-items: center;
        font-weight: 600;
    }
}


@media (max-width:480px) {
    .mentioncontainer{
        padding: 0 15px;
    }

}