section.infl_padding48 {
    padding: 0 96px;
}

.row-post-stats {
    display: flex;
    justify-content: space-between;
}

.block-post-stats {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.stat-type {
    font-size: 14px;
    width: 25%
}

.stat-number {
    font-size: 18px !important;
    line-height: 16px !important;
    font-weight: 800 !important;
    text-align: center;
}

.stat-label {
    line-height: 12px;
    font-size: 12px;
}

.infl_mainSection {
    margin: 0;
    padding: 24px;
    background: #fff;
    margin: 10px 0;
}

/*.infl_mainSection:first-child {
    margin: 0 0 10px 0;
    padding: 48px 48px 24px 48px;
}
*/

blockquoteComma:before {
    display: block;
    content: "\201C";
    font-size: 60px;
    position: absolute;
    left: -5px;
    top: 45px;
    color: #7a7a7a;
}

.info_audienceNetworkDiv img {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px darkgrey;
}

.infl_mainSectiondiv {
    display: flex;
    justify-content: center;
}

.info_audienceNetworkDiv {
    padding: 5px
}

.info_audienceNetworkContainer {
    /* padding: 24px; */
    height: 135px;
    overflow: hidden;
    width: 125px;
    flex-direction: column;
    display: flex;
}

.infl_audienceNetworkmaindiv {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.infl_top_audience {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
}

.info_audienceNetworkContainer .infl_audienceName {
    overflow: hidden;
    max-height: 48px;
    /* width: 100%; */
    word-wrap: break-word;
    margin: 5px 12px;
    font-size: 12px;
    line-height: 15px;
}

.infl_mainSection div.cloud-paragraph {
    padding: 24px !important;
}

p.cloudParablock {
    display: inline-block;
    margin: 0 !important;
    padding: 10px;
    letter-spacing: 0;
    line-height: 0;
    word-break: break-word;
}
p.cloudParablock:hover{
    transform: scale(1.5) !important;
    transition:  all 0.3s
}

.infl_graphadjustheight {
    min-height: 352px;
    margin-top: 24px;
}

.infl_graphadjustheightmargin {
    margin: 0 0 0 10px;
    max-height: 140px;
}

.infl_mainSection-div {
    display: flex;
    justify-content: center;
    padding: 0px
    /* border: 1px solid #dedede; */
}

.infl_mainSection-div h5 {
    word-wrap: break-word;
    padding: 15px 0;
    margin: 0;
    color: #000 !important;
    font-weight: 800;
}

.infl_dataImage {
    max-width: 125px;
    max-height: 125px
}

.infl_dataImage img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    transition: .5s;
    /* box-shadow: 0px 0px 2px 1px #f1f1f1; */
}

.engageDiv {
    padding-top: 8px;
}

.infl_sectionHeader h2 {
    font-size: 16px;
    text-align: left;
    color: #000;
    margin: 15px 0 !important;
}

.infl_scoreCircle {
    background: #fff;
    padding: 48px;
    border-radius: 50%;
    border: 2px solid red;
    font-size: 24px
}

.infl_mainSection-div svg.score-running-circle {
    transform: rotate(-90deg);
}

.badges {
    position: relative;
    width: 100%;
    height: 90px;
    min-width: 100px;
    float: right;
    margin-left: 4%;
    font-family: open sans;
    font-size: 10px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .3px;
}

.badges .top {
    position: absolute;
    top: 43px;
    width: 100%;
    background-color: #f6f8f9;
    color: #29c;
    font-weight: 700;
}

.infl_influencerName img {
    display: inline-block;
    width: 27px;
    height: 27px;
    vertical-align: text-bottom;
    margin-right: 10px;
}

.infl_influencerName h4 {
    display: inline-block;
    font-size: 28px;
}

.circ-button {
    display: block;
    width: 28px;
    height: 28px;
    position: absolute;
    margin-left: 85px !important;
    margin-top: -12px !important;
    letter-spacing: .05em;
    text-decoration: none;
    color: #fff;
    background: #0079c0;
    font-size: 10px;
    border: 2px solid #fff;
    border-radius: 50%;
    padding-top: 2px;
    transition: all linear .15s;
    box-shadow: 0 0 1px rgba(0, 0, 0, .5);
    margin: auto;
}

.circ-buttonOnMain {
    display: block;
    width: 4em;
    height: 4em;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
    transform: rotate(180deg);
    text-align: center;
    text-decoration: none;
    color: #fff;
    background: green;
    border: 10px dashed #fff;
    border-radius: 6em;
    transition: all linear 0.15s;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    margin: auto;
}

.circ-buttonOnMain p.influence {
    /* transform: rotate(180deg); */
    font-size: 9px;
    transform: rotate(180deg);
    color: #fff;
    line-height: 10px;
    margin-top: 28px;
}

.circ-buttonOnMain p.score {
    color: #fff;
    transform: rotate(180deg);
    margin-bottom: 0;
    line-height: 30px;
    font-size: 1.1em;
    font-weight: 400;
}

.star-for-more-then-avg-score {
    width: 40px;
    position: absolute;
    z-index: 10;
    left: 0;
}

@media (max-width: 960px) {
    .infl_mainSection:last-child {
        margin-bottom: 24px;
    }
}

@media (max-width: 768px) {
    .infl_mainSection:last-child {
        margin-bottom: 56px;
    }
}

.infl_displayNone {
    display: none !important;
}

.infl_displayBlock {
    display: block !important;
}

.infl_PostImage {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
}

.infl_PostImageImg {
    width: 100%;
    height: 100%;
    background-size: cover;
    margin-bottom: 0;
    background-position: center;
}

.infl_postText {
    background: #f1f1f1;
    padding: 24px;
}

.infl_postText p {
    max-width: 200px
}

.infl_postTextInsideImage {
    bottom: auto !important;
    padding: 24px !important;
}

.infl_postTextInsideImage>p {
    color: #fff !important;
}

/* 
.infl_imageOverlayFull {
    position: absolute;
    top: 10px;
    display:none;
    transition: bottom 0.8s ease;
} */

/* .infl_PostImagehide:hover > .infl_imageOverlayFull{
    transition: bottom 0.8s ease;
} */

/* .infl_PostImagehide:hover ~ .infl_PostImageImg {
    opacity: 0 !important;
    display:none
}

.infl_PostImagehide:hover + .infl_imageOverlayFull  {
    opacity: 1 !important;
    display:block;
} */

/* .infl_followBlock:first-child{
    padding-right:15px
} */

.infl_followBlock {
    padding: 3px 12px;
    margin: 0 10px 0 0;
    border: 1px solid;
}

.infl_detailLi:first-child {
    display: flex;
}

.infl_detailLi {
    margin-top: 12px
}

.infl_scoreSubHead {
    color: #545454 !important;
    margin-bottom: 0;
    font-size: 13px !important;
    height: 48px;
    padding: 0 10px;
    font-style: italic;
}

.infl_overImageBottomdiv {
    padding: 10px;
    position: relative;
    min-width: 100%;
    width: 100%;
}

.infl_PostOverImage {
    padding-top: 10px;
}

.infl_overImageBottom {
    width: 100%;
    background: #525352;
    font-size: 13px;
    bottom: 0;
    color: #fff;
    display: flex;
    padding: 4px 10px;
    left: 0;
    position: absolute;
    word-wrap: break-word;
}

.infl_overImageBottomfirst {
    flex-grow: 1;
    font-size: 10px;
}

.infl_overImageBottomSecond {
    text-align: right;
    font-size: 0;
    ￼padding: 10px;
    ￼position: relative;
    ￼min-width: 250px;
    ￼width: 250px;
}

.nodataonIpadView .filter-head .mention-box .header-enterprise {
    padding: 20px 35px 0 !important;
}

.nodataonIpadView p {
    font-size: 24px;
    font-weight: 600
}

.nodataonIpadView {
    padding: 48px;
}

@media (max-width:480px) {
    .infl_mainSection:last-child {
        margin-bottom: 0px;
    }
    .nodataonIpadView {
        padding: 24px;
    }
    .nodataonIpadView .filter-head .mention-box .header-enterprise {
        padding: 24px 12px !important;
    }
    .nodataonIpadView p {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }
    .infl_mainSection:first-child {
        margin: 0;
        padding: 48px 24px;
    }
    .infl_sectionHeader {
        padding: 0 15px
    }
    .infl_mainSection div.cloud-paragraph {
        padding: 15px !important;
    }
    .infl_overImageBottomdiv {
        padding: 10px;
        position: relative;
        min-width: 258px;
        /* width: 258px; */
    }
}