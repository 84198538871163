.feature-graph-alltype-container {
  padding: 0px;
  margin-bottom: 2px;
}

.feature-word-graph-container {
  padding-left: 1px;
  margin: 0px;
  flex-basis: 100%;
  flex-grow: 1;
}

.features-main-Container {
  background: #fff;
  /* margin-bottom: 5px; */
}

.features-main-Container h4 {
  margin-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  color: #000;
}

.feature-word-cloud-div {
  padding: 24px;
  background: #fff;
  min-height: 305px;
}

.features-graph-Container {
  flex-basis: 100%;
  padding-right: 1px;
  background: #fff
}

.feature-graph-headline {
  margin: 0;
  padding: 2px;
  background: #fff;
}

.feature-graph-headline h4 {
  font-size: 14px;
  color: #545454;
  margin-bottom: 8px;
  line-height: 15px;
}

.feature-Attributes-headline h4 {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  line-height: 20px;
}

.feature-graphdiv {
  background: #fff;
  padding: 0 5px 5px 5px
}

#featureCompleteWordGraph>svg>g>text,
#featureSocialWordGraph>svg>g>text,
#featureArticleWordGraph>svg>g>text,
#featureReviewWordGraph>svg>g>text {
  cursor: default !important;
}

#featureCompleteBarGraph .draglayer>.xy>.nsewdrag.drag.cursor-pointer,
#featuresocialbarGraph .draglayer>.xy>.nsewdrag.drag.cursor-pointer,
#featurearticlebarGraph .draglayer>.xy>.nsewdrag.drag.cursor-pointer,
#featureReviewbarGraph .draglayer>.xy>.nsewdrag.drag.cursor-pointer {
  cursor: default !important;
}

.feature-image-demo {
  position: relative;
  text-align: center;
}

.feature-image-demo img {
  opacity: .3;
  width: 100%;
  height: 100%;
}

.feature-overlay-centered-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
/* .active#content .feature-overlay-centered-text {
  left: 53%;
} */

.feature-lock-screen {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
}

.feature-overlay-centered-text i {
  font-size: 32px;
  color: #000;
  height: 100%;
  width: 100%;
  padding: 10%;
}

.feature-overlay-centered-text i:hover {
  color: #0079c0;
}

.feature-overlay-centered-text h4 {
  color: #fff;
  margin: 10px 0;
}

.feature-overlay-centered-text p {
  color: #fff;
  margin: 0;
}

.feature-hide-data-container {
  max-height: 100%;
  background: rgba(0, 0, 0, .99);
}

.flex-justify-center {
  display: flex;
  justify-content: center
}

.wrapper a.text-color{
  color: #545454;
}

.wrapper a.text-color:hover, .wrapper a.text-color:active, .wrapper a.text-color:focus{
  color: #0079c0;
}




a.js-feature-analysis-slide.carousel-control{
  width: 3%;
}

.js-feature-analysis-slide.carousel-control .glyphicon-chevron-left {
  left: 50%;
  top: 40%;
}

.js-feature-analysis-slide.carousel-control .glyphicon-chevron-right{
  right: 50%;
  top: 40%;
}

.feature-attribute-text{
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}
.attributeAnalysis{
  width: 50%
}
@media (max-width:480px){
  .attributeAnalysis{
    width: 100%
  }
}