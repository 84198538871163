.infl-social-block {
  border-top: 1px solid #e6e6e6;
}

.infl-social-block .infl-social-desc-block {
  border-right: 1px solid #e6e6e6;
  padding: 7.5px 0;
  border-bottom: 1px solid #e6e6e6;
  float: unset;
}

.infl-social-block p {
  color: #B7B7B7;
  margin-bottom: 0;
  line-height: 1.2;
}

.infl-social-block .infl-social-main-content {
  display: block;
  text-align: center;
  margin: 0px auto;
}

.infl-social-block b {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin: 5px 0;
  display: block;
}

.infl-social-block .infl-social-main-content span {
  color: #545454;
  font-size: 11px;
  display: block;
  overflow: hidden;
  font-weight: 400;
  text-transform: capitalize;
}

.infl_workingfield span {
  background: yellow;
  border-radius: 10px;
  color: #000;
  padding: 2px 10px;
}

/* .infl_workingfield span:hover {
  background: #0079c0;
  opacity: .8;
} */

#influencerSearchBoxDiv .bootstrap-tagsinput {
  display: flex;
  flex-wrap: wrap;
  max-height: 130px;
  min-height: 60px;
  margin-top: 0;
  height: unset;
  border: 2px solid #ccc;
  border-radius: 4px;
  overflow: auto !important;
  padding: 4px 0px
}

#influencerSearchBoxDiv .bootstrap-tagsinput .tag {
  /* padding: 4px; */
  font-size: 90%;
  margin: 2px;
}

#influencerSearchBoxDiv .bootstrap-tagsinput input {
  width: 100%;
  padding: 0 5px;
  height: 18px;
}

#influencerSearchBoxDiv .bootstrap-tagsinput>input::placeholder {
  font-size: 12px;
  width: 100%;
  font-weight: 400;
}
#selectedInterests .bootstrap-tagsinput>input{
  min-width: 245px;
  width: unset;
}

#selectedInterests .bootstrap-tagsinput{
  min-height: 104px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  overflow-y: auto!important;
  border-color: #00dafc;
}

.influencer-Discovery-Form .card label{
  font-size: 16px;
  color: #fff;
}
@media(min-width:480px){
  .flex-end-content{
    justify-content: flex-end;
  }
}


.discovery-container .bootstrap-tagsinput {
  max-width: 175px;
}

#selectedInterests .bootstrap-tagsinput .tag {
  margin: 2px;
  padding: .5em .6em;
  background-color: #0079c0;
}

.influencer-Search-Button,
.influencer-follower-count-btn,
.influencer-influence-score-btn {
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 32px;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.influencer-Search-Button .fa,
.influencer-follower-count-btn .fa,
.influencer-influence-score-btn .fa {
  font-size: 12px
}

.influencer-select-Button .form-control {
  width: auto;
}

.influencer-select-Button select.form-control {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  height: 33px;
  margin-bottom: 0;
  padding: 0 5px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
  /* animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0; */
}

/* div.carddiv:nth-child(4)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(5)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(6)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(7)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(8)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(9)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(10)>.card {
  animation-delay: 1s;
}

div.carddiv:nth-child(11)>.card {
  animation-delay: 2s;
}

div.carddiv:nth-child(12)>.card {
  animation-delay: 2s;
}

div.carddiv:nth-child(13)>.card {
  animation-delay: 2s;
}

div.carddiv:nth-child(14)>.card {
  animation-delay: 2s;
}

div.carddiv:nth-child(15)>.card {
  animation-delay: 2s;
}

div.carddiv:nth-child(16)>.card {
  animation-delay: 3s;
}

div.carddiv:nth-child(17)>.card {
  animation-delay: 3s;
}

div.carddiv:nth-child(18)>.card {
  animation-delay: 3s;
}

div.carddiv:nth-child(19)>.card {
  animation-delay: 3s;
}

div.carddiv:nth-child(20)>.card {
  animation-delay: 3s;
}

div.carddiv:nth-child(21)>.card {
  animation-delay: 3s;
}

div.carddiv:nth-child(22)>.card {
  animation-delay: 4s;
}

div.carddiv:nth-child(23)>.card {
  animation-delay: 4s;
}

div.carddiv:nth-child(24)>.card {
  animation-delay: 4s;
}

div.carddiv:nth-child(25)>.card {
  animation-delay: 4s;
}

div.carddiv:nth-child(26)>.card {
  animation-delay: 4s;
}

div.carddiv:nth-child(27)>.card {
  animation-delay: 4s;
}

div.carddiv:nth-child(28)>.card {
  animation-delay: 5s;
}

div.carddiv:nth-child(29)>.card {
  animation-delay: 5s;
}

div.carddiv:nth-child(30)>.card {
  animation-delay: 5s;
} */

/* @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.img-card {
  width: 100%;
  height: 80px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  overflow: hidden;
}

.img-card img.influence_profile {
  width: 60px;
  height: 60px;
  object-fit: cover;
  transition: all .25s ease;
  border-radius: 50px;
  margin-top: 5px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1), 0 0px 10px 0 rgba(0, 0, 0, 0.19);
  border: 2px solid #fff;
}

.brandCard {
  box-shadow: 0 0 5px 0 rgba(0, 121, 192, .16), 0 0 10px 0 rgba(0, 121, 192, 1) !important;
}

/* .brandCard:hover {
  box-shadow: 0 8px 17px 0 #0079c0, 0 6px 20px 0 #0079c0 !important;
} */

.card-content {
  padding: 5px 10px;
  text-align: left;
  height: 220px;
}

.influencer-discovery .card-content{
  padding: 5px 0;
}

.card-title {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* max-height: 36px; */
  overflow: hidden;
}

.card-title a {
  color: #000;
  text-decoration: none !important;
}

.card-read-more {
  border-top: 1px solid #D4D4D4;
  display: flex;
  width: 100%;
}

.card-read-more a {
  text-decoration: none !important;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  color: #fff;
}

.img-card>img.influence-icon {
  width: 25px;
  height: 25px;
  border-radius: 0px;
  top: 15px;
  margin-left: -25px;
  position: relative;
  margin-top: 15px;
  object-fit: cover;
  transition: all .25s ease;
}

.influencecategory {
  margin-bottom: 0px;
  overflow: hidden;
  width: 100%;
  font-weight: 100;
  font-size: 12px;
  text-align: center;
  max-height: 65px;
  min-height: 65px;
  line-height: 1.5em;
}

.influencecategory p.author_bio {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 0px;
}

span.author_bio {
  font-size: 12px;
}

.btn-block {
  border-radius: 0 0 10px 10px;
  background: #0079c0;
  opacity: 1;
}

.card-read-more .btn-block:hover,
.card-read-more .btn-block:active,
.card-read-more .btn-block:focus {
  background: #0079c0;
  opacity: .8 !important;
  color: #fff !important;
}

.infl-mark-default-influencer {
  position: absolute;
  right: 10%;
  color: red;
  cursor: pointer;
}

.infl-option-default-influencer {
  position: absolute;
  right: 10%;
  color: rgba(0,0,0,.45);
  cursor: pointer;
}


div[id^="influencer-dropbox"] {
  display: none;
}


.infl-ant-dropdown {
  box-sizing: border-box;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  right: 0;
  z-index: 1040;
  display: block;
}
.infl-ant-dropdown-hidden{
  display: none;
}

.infl-ant-dropdown i.fa.fa-heart, .infl-ant-dropdown .fa-heart-o {
  color: red;
}


.infl-ant-dropdown:before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: .0001;
  content: " ";
}


.infl-ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
}

.infl-ant-dropdown-menu-item, .infl-ant-dropdown-menu-submenu-title {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    clear: both;
    margin: 2px 0;
    background: rgba(0,0,0,.02);
    padding: 5px 12px;
    color: rgba(0,0,0,.65);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
}

.infl-ant-dropdown-menu-item:hover{
  background: rgba(0,0,0,0.05);

}
/* influencer detail page */

.infl-detail-marked {
  color: red;
  padding: 5px;
}

.follower-range-search,
.influence-range-search {
  background: #0079c0;
  color: #fff;
  padding: 1px 5px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}

a.follower-range-search:hover,
a.follower-range-search:focus,
a.follower-range-search:active,
a.influence-range-search:hover,
a.influence-range-search:active,
a.influence-range-search:focus {
  color: #fff !important;
  opacity: 0.9;
}

.influencer-input-follower-count,
.influencer-input-influence-score {
  padding: 2px 5px !important;
  height: 30px !important;
  color: #545454;
  font-weight: bold;
}

.influencer-input-follower-count {
  width: 75px;
}

.influencer-input-influence-score {
  width: 50px
}

ul.filter-list {
  padding-top: 5px
}

.ui-slider-horizontal .ui-slider-handle {
  top: -.4em !important;
}

.ui-slider-horizontal {
  height: .5em !important;
}

.influ-search-box-div {
  width: 100%;
  padding: 10px 0;
}




.infl-discovery .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-total{
  margin-top: 0;
}

.infl-discovery .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-avatar{
  top: unset
}

.infl-discovery .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-meta{
  height: unset;
}
.infl-discovery .kpi-view-container{
  margin: 0 15px 24px 15px;
  flex-basis: 33%;
}
.influence-discovery-container .shopping-box{
  padding: 12px 24px;
}
.badge-info{
  color: #fff;
  background-color: #17a2b8;
}

.badge{
  display: inline-block;
  padding: 0.25em 0.4em;
  margin-right: 2px;
  margin-top: 2px;
  text-align: center;
  white-space: pre-wrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  font-size: 10px;
  color: #fff;
  padding: 3px 5px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: default;
  background-color: transparent;
  color: #1a668b;
  border: 1px solid #1a668b;
}

.showMoreList:hover ~ .badgeOnHover{ 
  visibility: visible;
  opacity: 1;
}

.badgeOnHover{
  position: absolute;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s linear .33s,opacity .33s linear;
  transition: visibility 0s linear .33s,opacity .33s linear;
  /* right: 20px; */
  margin-top: 26px;
  text-align: center;
  width: 80%;
  background-color: #fff;
  z-index: 10;
}

.influencer-discovery .card-content {
  height: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.influencer-discovery .infl-social-block b{
  font-size: 14px;
}
.influencer-discovery .infl-social-block .infl-social-main-content span{
  font-size: 12px;
}


.infl-discovery .panal-box-count{
  padding: 12px 0 0 0 !important;
}

.influencer-discovery-container .bootstrap-tagsinput{
  margin-top: unset;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto !important;
  height: 44px;
  border-right: unset;
  border-radius: 5px 0 0 5px;
}

.influencer-discovery-container .infl-search-icon-image{
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-left: unset;
  border-radius: 0px 5px 5px 0;
}

.infl-search-icon-image:hover{
  background: #f5f5f5;
}
.bootstrap-tagsinput input::placeholder{
  color: rgba(0,0,0,.40);
}
.influencer-discovery-container .bootstrap-tagsinput input{
  width: auto;
}

.influencer-discovery-container .bootstrap-tagsinput:focus, .influencer-discovery-container .bootstrap-tagsinput:hover{
  border-color: #0079c0;
}


#chanageInterests:focus  + .infl-search-icon-image, #chanageInterests:hover + .infl-search-icon-image {
  border-color: #0079c0;
}
.influencer-discovery-container .bootstrap-tagsinput input {
  width: unset;
}
.influencer-discovery-container .bootstrap-tagsinput .tag{
  margin: 2px;
  padding: 0.5em .6em .5em;
  background-color: #0079c0;
}

.influence-discovery-container .showingFilterList {
  padding: 2px 8px;
}

.influencer-discovery .card {
  height: 330px;
}
/* .influencer-discovery .img-card {
  height: 120px;
} */


.sm-filter-box {
  margin-left: 24px;
}

.sm-filter-box .fa-filter {
  padding-right: 22px;
}

.influencer-discovery-container {
  padding: 15px 24px;
  display:flex;
  background: #fff;
  margin: 0 0 24px 2px;
}


.influencer-Discovery-Form{
  padding: 48px;
}

.influencer-Discovery-Form #influencerDiscoveryForm .increasekeyword{
  color: #545454 !important;
}

.influencer-Discovery-Form .bootstrap-tagsinput{
  border: .5px solid #ccc;
}
.influencer-Discovery-Form .bootstrap-tagsinput:active, .influencer-Discovery-Form .bootstrap-tagsinput:focus{
  border: .5px solid #0079c0 !important;
}


.influencer-Discovery-Form .card, .influencer-Discovery-Form .card:hover{
  box-shadow: unset;
  background-color: transparent;
  /* box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 0 0 1px rgba(63,63,68,.1); */
}

.influencer-discovery .card-title{
  max-height: unset;
  min-height: unset;
}
@media(max-width: 767px) and (min-width: 300px){
  .influencer-Discovery-Form .bootstrap-tagsinput{
    border: .5px solid #ccc ;
  }
  .influencer-Discovery-Form .bootstrap-tagsinput:active, .influencer-Discovery-Form .bootstrap-tagsinput:focus{
    border: .5px solid #0079c0 !important;
  }
}


@media(max-width:480px) {
  .influencer-Discovery-Form{
    padding: 24px;
  }

  .discovery-filter .influencer-input-follower-count {
    width: 96px;
  }
  .discovery-filter .btn.btn-primary{
    padding:  8px 16px !important;
  }
  .discovery-filter .padding-24{
    padding: 0;
  }
  .influencer-discovery-container #chanageInterests{
    width: 100%;
    min-width: inherit;
    max-width: 100%;
  }
  /* .influencer-discovery-container .bootstrap-tagsinput{
    max-width: 200px;
  } */
  .influencer-discovery-container .bootstrap-tagsinput input, .influencer-discovery-container .bootstrap-tagsinput input:focus{
    width: 100%;
  }
  #influencerSearchBoxDiv {
    max-width: 300px !important
  }

  .infl-discovery .kpi-view-container{
    margin: 12px 0 0 0;
    flex-basis: 33%;
  }
  /* .infl-discovery .kpi-view-container:first-child {
    margin-top: 24px
  } */
  .infl-discovery .kpi-view-container:last-child {
    margin-bottom: 12px
  }
  .padding-sm-0{
    padding: 0;
  }
  .influence-discovery-container .shopping-box{
    background: #fff;
  }
  .sm-change-interest{
    padding: 5px 0;
  }
  .sm-filter-box {
    margin-left: 12px;
  }
  .sm-filter-box .fa-filter {
    padding-right: 0px;
  }
  
  .influencer-discovery-container {
    padding: 15px 24px;
    display:flex;
    background: #fff;
    margin: 0;
  }
  .influence-discovery-container .showingFilterList {
    margin: 2px 5px 2px 0px;
    margin-left: unset !important;
  }
  .influence-discovery-container .showingFilterList:first-child {
    margin-left: 10px
  }
}



.influencer-discovery .carddiv{
  max-width: 100%;
  flex-basis: 100%;
}
@media(min-width:320px){
  .influencer-discovery-container .bootstrap-tagsinput{
    max-width: 165px;
  }
}
@media(min-width:360px){
  .influencer-discovery-container .bootstrap-tagsinput{
    max-width: 200px;
  }
}
@media (max-width: 375px){
  .influencer-discovery-container .bootstrap-tagsinput {
      max-width: 216px;
  }
}

@media(min-width:411px){
  .influencer-discovery-container .bootstrap-tagsinput{
    max-width: 254px;
  }
}

@media(min-width:480px){
  .influencer-discovery-container #chanageInterests {
    min-width: 400px;
    max-width: 400px; 
  }
  .influencer-discovery-container .bootstrap-tagsinput{
    max-width: 400px;
  }
  .influencer-discovery .card-title{
    max-width: 195px;
    min-height: unset;
    max-height: unset;
    margin-bottom: 5px;
  } 
  .influencer-discovery .influencecategory{
    max-width: 195px;
    min-height: unset;
    max-height: 32px;
  }

  .influencer-discovery .carddiv{
    max-width: 33%;
    flex-basis: 33%;
  }
}

@media(min-width:768px){
  .influencer-discovery .carddiv{
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media(min-width:920px){
  .influencer-discovery .carddiv{
    max-width: 20%;
    flex-basis: 20%;
  }
}