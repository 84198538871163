.io_influencerDataMainContainer {
  background: #fff;
  padding: 24px;
  text-align: center;
}

.io_insta_profileNotFound {
  padding: 48px;
  color: red;
}

.io_insta_profileNotFound span {
  font-size: 20px;
}

.io_insta_profileNotFound i {
  font-size: 20px;
  padding: 0 24px;
}

.io_influencerData {
  display: inline-block;
  position: relative;
  padding: 24px;
}

.io_influencerDataMainContainer .io_headline {
  margin-bottom: 5px
}

.io_influencername {
  cursor: pointer;
}

.io_displayTask {
  display: inline-block;
  position: relative;
  padding: 0 5px;
}

.io_influencecategory {
  font-style: italic;
  text-align: center;
  line-height: 1.5em;
  height: 55px;
  overflow: hidden;
}

.io_influencedesc {
  font-style: italic;
  text-align: center;
  line-height: 1.5em;
  height: 65px;
  overflow: hidden;
}

.io_card-content {
  padding: 5px 10px;
  text-align: left;
}

.io_img-card img.influence_profile {
  width: 70px;
  height: 70px;
  object-fit: cover;
  transition: all .25s ease;
  border-radius: 50px;
  /* margin-top: 15px; */
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1), 0 0 10px 0 rgba(0, 0, 0, .19);
  border: 2px solid #fff;
}

.io_card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  transition: box-shadow .25s;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
  height: 220px;
}

.influencer-onboading-logo {
  width: 160px;
  height: 100%;
}

@media(max-width:480px) {
  .io_influencerDataMainContainer {
    padding: 24px
  }
  .influencer-onboading-logo {
    width: 160px;
    height: 100%;
  }
  .io_insta_profileNotFound {
    padding: 0
  }
}