.main-db-header{
  background-color: #fff;
}

.main-nav-header-section{
  background: #001529;
}
.main-db-header.navbar-default{
  box-shadow: 0 2px 8px rgba(0,0,0, .5);
  border-bottom: unset;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-bottom: 0;
}

.main-db-header .main-sidebar-right-ul-container>li a.dropdown-item{
  color: #000 !important;
  opacity: .65;
}
.main-db-header .main-sidebar-right-ul-container>li a.dropdown-item:hover{
  color: #000 !important;
  opacity: 1;
}

.main-db-header .main-sidebar-left-ul-container>li a i.anticon {
    min-width: 14px;
    margin-right: 12px;
    font-size: 14px;
    transition: font-size .15s cubic-bezier(.215,.61,.355,1),margin .3s cubic-bezier(.645,.045,.355,1);
}


a.actionable-link{
  color: #848484 ;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

a.actionable-link .fa{
  width:  24px;
}

li.flexalignCenter.headerIcon:last-child {
  padding-right: 12px;
}

 
.main-db-header .main-sidebar-left-ul-container>li a, .main-db-header .main-sidebar-right-ul-container>li a {
  color:  hsla(0,0%,100%,.65) !important
  /* background-color: #e7e7e7; */
}

.main-db-header .main-sidebar-left-ul-container>li.active a{
  color:  rgba(255,255,255)  !important;
  font-weight: 800;
  /* background-color: #e7e7e7; */
}


 

.main-db-header .main-sidebar-left-ul-container>li a:hover, 
.main-db-header .main-sidebar-right-ul-container>li a:hover {
  color:  rgba(255,255,255) !important;
}
.main-navbar-header-box{
  height: 56px;
  padding-right: 12px;
  padding-left: 12px;
}
@media(min-width:480px){
  .main-db-header .main-sidebar-left-ul-container>li a{
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-sidebar-left-ul-container, .main-sidebar-right-ul-container{
    height: 56px;
  }
  .main-sidebar-left-ul-container>li, .main-sidebar-right-ul-container>li {
    height: 57px;
  }

  span.highlighttrial {
    font-size: 12px;
    font-weight:600
  }
  .actionable-link .fa-eye, .actionable-link .fa-question-circle,.actionable-link .fa-power-off{
    font-size: 24px;
  }
  .main-nav-header-section{
    padding: 0 12px;
  }

  .nav.main-sidebar-right-ul-container>li>a{
    padding-left: 12px;
    padding-right: 12px;
  }
}



@media(max-width:480px){
  .main-db-header.navbar-default{
    box-shadow: unset;
  }  

a.actionable-link{
  height: unset;
  width: 100%;
  text-align: left;
}


.main-sidebar-left-ul-container>li:hover, .main-sidebar-right-ul-container>li:hover, .main-sidebar-left-ul-container>li:focus, .main-sidebar-right-ul-container>li:focus, .main-sidebar-left-ul-container>li:active, .main-sidebar-right-ul-container>li:active{
    background-color: #001f3d;
}

.main-navbar-header-box{
  height: 56px;
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.main-navbar-header-btn{
  padding: 0;
  flex-grow: 1;
}
.main-sm-collapse-navbar{
  padding: 0;
}
.main-sidebar-left-ul-container, .main-sidebar-right-ul-container{
  margin: 0
}
.main-toggle-callapsed{
  margin: 0 !important;
}
}




