.ig_ribbon-div {
    position: absolute;
    width: 68%
}

.ig_container1 {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    z-index: 100;
}

.ig_content {
    position: relative;
    margin: 0 auto;
}

/* -------------------------------------------------------- ribbon */

.ig_ribbon {
    background-color: #00b1b4;
    position: relative;
    margin: 0 auto;
    color: white
}

.ig_ribbon:before,
.ig_ribbon:after {
    position: absolute;
    top: 5px;
    z-index: -100;
}

.ig_ribbon:before {
    border-color: #00b1b4 #00b1b4 #00b1b4 transparent;
    left: -25px;
    border-width: 15px;
}

.ig_ribbon:after {
    border-color: #00b1b4 transparent #00b1b4 #00b1b4;
    right: -25px;
    border-width: 15px;
}

.ig_background {
    background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(images/report/HR.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.ig_mention {
    border: 2px solid blue;
    width: 36%;
    height: 11%;
    position: absolute;
}

.ig_like {
    transform: rotate(-45deg);
}

.ig_shares {
    transform: rotate(45deg);
}

.id_mentiondiv {
    height: 195px;
}

.ig_like_div {
    top: 6%;
    left: 10%
}

.ig_share_div {
    top: 8%;
    left: 58%;
}

.ig_mention_posts_div {
    top: 15%;
    left: 33%;
    background: #fff
}

.ig_like_set {
    top: -5%;
    left: -28%;
}

.ig_shares_set {
    top: -5%;
    left: 62%;
}

.ig_posts_set {
    bottom: -5%;
    left: -5%;
    width: 110%;
}

.ig_mention_text {
    margin: 15px;
    width: 79%;
    overflow: hidden;
    font-size: 2vw;
}

.ig_thumbsup {
    position: absolute;
    height: 75px;
    margin-top: 5%;
    margin-left: 8%;
}

.ig_mentionGraph {
    background: none;
    margin-left: -35px;
}

.ig_companyname {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px
}

.ig_pieGraph {
    background: none;
    margin-left: 15px;
}

.ig_fourpiaGraph {
    background: none;
    margin-left: 15px;
}

.ig_fourpia_image {
    height: 22px;
    width: 22px;
    position: absolute;
    bottom: 84px;
    z-index: -10;
}

.ig_count {
    margin-left: 40px;
    margin-right: -60px;
}

.ig-nbs-text{
    margin-left: 10px;
    padding: 4px 20px;
    font-size: 20px;
    border: 2px solid #09f;
}

.ig_total {
    display: inline-block;
    margin-bottom: 0px
}

.ig_total_value {
    display: inline-block;
    width: 118px;
    margin-bottom: 0px;
    height: 15px;
    overflow: hidden;
}

.most_influential_post {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px
}

.ig_posts_div {
    display: flex;
    margin-top: 10px
}

.ig_div_box {
    width: 50px;
    flex-direction: column;
    height: 50px;
    flex-shrink: 0;
    background: url('/images/default_profile_normal.png');
}

.ig_div_image {
    position: absolute;
    height: 25px;
    width: 25px;
    margin-top: -2%;
    margin-left: -2%;
}

.ig_post_text {
    font-size: 12px;
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 38px;
    color : #000000
}

.ig_post_text_div {
    margin: 0 0 0 15px;
}

.ig_hastag {
    height: 75px;
    position: absolute;
    margin-left: 78%;
    margin-top: 62%;
}

.ig_influential_author {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 2px;
    font-size: 18px
}

.ig_author_div {
    /* border: 2px solid lightgrey; */
    width: 100%;
    overflow: hidden;
}

.ig_author_inside {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ig_author_box {
    margin: 0 9px;
    height: 100px;
    overflow: hidden;
    width: 52px;
}

.ig_platform_box {
    margin: 0 9px;
    height: 80px;
    overflow: hidden;
    width: 100%;
}

.ig_author_textbox {
    width: 50px;
    height: 50px;
}

.ig_platform_textbox {
    width: 50px;
    height: 50px;
}

.ig_platform_textbox>img {
    margin: 0 50%;
}

.ig_total_value1 {
    width: 100px;
}

.ig_author_box>p {
    margin-bottom: 0;
    font-size: 11px;
    word-wrap: break-word;
    color: black;
}

.ig_author-name {
    max-height: 30px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
}

.ig_platform_box>.ig_platform_textbox>p {
    margin-bottom: 0;
    font-size: 10px;
    word-wrap: break-word;
    max-height: 30px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 100px;
    color: #000000
}

.ig_platform_div {
    padding-left: 10px;
    margin-top: 10px;
}

.ig_platform_div>h5 {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 2px;
    font-size: 18px
}

.ig_platform {
    /* border: 2px solid lightgrey; */
    width: 100%;
    overflow: hidden;
}

.ig_platform_inside {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ig_platform_inside_div {
    margin: 0 9px;
    display: flex;
}

.ig_platform_image,
.ig_platform_image>img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.ig_hr {
    margin-top: 5px;
    margin-bottom: 2px;
    border-top: 1px solid lightgray;
    z-index: -10;
}

.ig_talked {
    padding-left: 0px;
    padding-top: 10px
}

.ig_talked>h5 {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px
}
.ig_hr {
    margin-top: 5px;
    margin-bottom: 2px;
    border-top: 1px solid lightgray;
    z-index: -10;
}
.ig_talked-ve-div {
    /* border: 2px solid lightgrey; */
    width: 100%
}

.ig_talked-div {
    padding: 10px 5px;
    height: 150px;
    overflow: hidden
}

.ig_talked-div>p {
    margin: 0 0 10px;
    color: black;
}

.ig-minus-circle {
    font-size: 44px;
    color: #bbb;
    position: absolute;
    bottom: 0px;
    z-index: 0;
    opacity: 0.5;
    right: 20px;
}

.ig_hr-first {
    position: absolute;
    width: 100%;
    /* left: -170px;
    top: 33px; */
    transform: rotate(90deg);
    border-top: 5px solid #eee;
    z-index: -10;
    margin-left: -17%;
    margin-top: 45px;
}

hr.ig_hr-second {
    position: absolute;
    width: 100%;
    margin-left: 14%;
    margin-top: 45px;
    transform: rotate(90deg);
    border-top: 5px solid #eee;
    z-index: -10;
}

hr.ig_hr-mention {
    position: absolute;
    margin-left: 29%;
    margin-top: 22%;
    width: 39%;
    transform: rotate(90deg);
    border-top: 5px solid #eee;
    z-index: -10;
}

.ig_fourpia_image:nth-child(4) {
    margin-left: 348px
}

.ig_fourpia_image:nth-child(2) {
    margin-left: 85px
}

.ig_fourpia_image:nth-child(3) {
    margin-left: 215px
}

@media (min-width:1380px) {
    hr.ig_hr-mention {
        margin-left: 32%;
        margin-top: 20%;
        width: 35%;
    }
    hr.ig_hr-second {
        width: 90%;
        margin-left: 19%;
        margin-top: 48px;
    }
    .ig_hr-first {
        width: 90%;
        margin-left: -13%;
        margin-top: 48px;
    }
    .ig_hr {
        margin-top: 12px;
        margin-bottom: 2px;
        border-top: 1px solid lightgray;
        z-index: -10;
    }
}

#info_spectrum_jobs {
    background: linear-gradient(to right, red, #ff0, orange, green);
    padding: 10px;
    height: 20px;
    width: 102%;
    float: left;
    border-radius: 4px;
    border: 4px solid #a9a9a9;
    box-shadow: inset 0 0 10px #f9f9f9;
    position: relative;
    margin: 5px 0;
}

#info_line_white {
    padding: 2px;
    border: 2px solid #a9a9a9;
    width: 1px;
    height: 38px;
    position: relative;
    margin-top: -5px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px #a9a9a9;
    transition-duration: .5s;
    background-color: #f2f2f2;
    animation: moveline 2s;
    z-index: 10;
}

.info_measure_div {
    display: flex;
    margin: 0;
    width: 91%;
}