#content-dashboard {
    /* margin-top: 0 !important; */
    background-color: #eceff1;
    min-height: 600px;
    position: relative;
}

/* .mention-limit-true{
    margin-top: 48px
} */
.mention-limit-text{
    margin-bottom: 0
}

.mention-limit-color{
    color: #fff;
}

a.mention-limit-color:hover{
    color: #545454;
}
@media(max-width: 480px){
    /* .mention-limit-true{
        margin-top: 95px
    } */
    .mention-limit-text{
        margin-bottom: 5px
    }
    .btn.btn-primary{
        font-size: 12px !important;
        padding: 4px 10px !important;
    }
}

/* @media (max-width: 380px){
    .mention-limit-true {
        margin-top: 116px;
    }
} */



.wrapper {
    top: 0 !important;
    display: flex;
    margin-bottom: 0;
}

.wrapper .navbar {
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 10px !important;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
}

.header-enterprise {
    /* display: flex; */
    /* align-items: center; */
    margin: 0 !important
}

.flexalignCenter {
    display: flex;
    align-items: center;
}

.flexalignCenter.headerIcon {
    padding: 0 12px
}

.flexalignCenter.headerIcon:last-child {
    padding-right: 24px;
}
.sum-headericons p {
    margin-bottom: 0;
}

.sum-headericons {
    padding: 0;
}

.flexjustifycenter {
    display: flex;
    justify-content: center;
}

.summary-main-mention-title {
    min-width: 100px;
    text-align: center;
}

.flexdirectionCol {
    display: flex;
    flex-direction: column;
}

.flexdirectionRow {
    display: flex;
    flex-direction: row;
}

.sum_mentionOverTimeContainer {
    width: 100%;
    background: #fff;
    margin-left: 12px
}

.sum_mention-over-time-div {
    justify-content: space-between;
    height: 100%;
}

.margin-right-5 {
    margin-right: 5px
}

.sum_mention-title {
    margin: 0;
    padding: 12px 12px;
    background: #fff;
    line-height: 16px;
}
.sum_mention-graph-title{
    line-height: 16px;
}

.sum_mention-title h4,
.sum_mention-graph-title h4 {
    font-size: 14px;
    margin: 0;
    color: #000;
    line-height: 20px;
    margin-bottom: 5px;
}

.sum_mention-title h4 {
    margin-bottom: 5px;
    font-weight: 400;
}

.sum_mentionsdiv span {
    padding-bottom: 0;
    font-size: 12px;
}

.wrapper .sum-des-below-graph span {
    padding-bottom: 0;
    font-size: 12px;
}

.mentionGraph>.quote {
    height: 140px;
    overflow: hidden;
}

.sum_mention-title span {
    font-size: 12px
}

.sum_mention-title i span {
    font-size: 12px
}

.summary_all_post_container {
    border-bottom: 1px solid #ddd;
    padding-top: 4px;
    padding-bottom: 4px;
}

.summary_all_post_container:last-child {
    border-bottom: unset
}

.sum_mentionsdiv {
    background: #fff;
    padding: 12px 8px;
    /* margin-right: 5px; */
    justify-content: space-around;
    /* white-space: normal; */
    flex-grow: 1;
    flex-basis: 100%;
    min-width: 0px;
}

.wrapper .sum_mentionsdiv p {
    font-size: 11px;
    margin-bottom: 0px;
    line-height: 15px;
}
::-webkit-scrollbar {
    width: 10px;
    height:10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c1c7c2; 
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #a4abb0;
    border-radius: 10px;
}

.headerNextProjectBtn {
    padding: 0px;
    margin: 3px;
    border-radius: 4px;
    /* background: #0079c0;  */
    display: flex;
    align-items: center;
    background: #5cb85c;
}

.headerNextProjectBtn a:hover {
    color: #fff !important;
}

.sum_mentionsdiv h4 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.marginzero {
    margin: 0
}

.sumGraphsThirdContainer {
    background: #fff;
    min-height: 394px;
}

.paddingzero {
    padding: 0
}

.summarypostnav-tab.nav-tabs {
    border-bottom: unset;
    background: #eceff1;
}

.summarypostnav-tab.nav-tabs .summarypostnav-tab-div {
    /* background: #fff; */
    background: #f5f5f5;
    flex-grow: 1;
    flex-basis: 100%;
}

.summarypostnav-tab h6 {
    margin-bottom: 0px;
    padding: 11px 24px;
    min-height: 54px;
    height: 100%;
    max-height: 86px;
    overflow: hidden;
    margin-top: 0;
    font-size: 14px;
    border-bottom: 2px solid #ddd;
    cursor: pointer;
    background: #fff;
    opacity: .7;
    text-transform: none;
}

.summarypostnav-tab-container {
    max-height: 552px;
    background: #fff;
    padding: 0px;
}

.mentionGraph>.quote {
    height: 140px;
    overflow: hidden;
}

.summarypostnav-tabContent .tab-pane {
    padding: 0 24px;
    height: 495px;
}

.sum_PostContainer .avatar img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #e3e3e3;
    cursor: pointer;
}

.sum_PostContainer .avatar {
    padding-right: 12px;
}

.sum_PostContainer.score {
    line-height: 0;
    margin-bottom: 5px;
}

.sum_PostContainer .author {
    overflow: hidden;
    width: 100%;
}

.sum_PostContainer .author .mention-title-box {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    height: 25px;
    line-height: 28px;
    padding-top: 0;
    margin-top: -5px;
    text-align: left;
    cursor: pointer;
}

.sum_PostContainer .author i {
    padding-right: 5px;
    color: #0079c0 !important;
    font-size: 12px;
}

.sum_PostContainer .author i .sum_PostContainer .author i.fa-youtube-play {
    color: #ff0000 !important;
}

.sum_PostContainer .author i.fa-twitter {
    color: #3190f6 !important;
}

.sum_PostContainer .author i.fa-facebook-official {
    color: #5054b4 !important;
}

.sum_PostContainer .author span {
    color: #000;
    font-size: 12px;
}

.sum_PostContainer .author .mention-date,
.sum_PostContainer .author .mention-source {
    font-size: 10px !important;
    color: #9393A7 !important;
    flex-grow: 1
}

.sum_PostContainer.score {
    line-height: 0;
    margin-bottom: 5px;
}

.sum_PostContainer .influence-score {
    height: 5px;
    background: #f5f5f5;
    border-radius: 3px;
    width: 40px;
    display: inline-block;
    margin: 0 0 0 5px;
}

.sum_PostContainer .influence-score span {
    display: block;
    height: 5px;
    border-radius: 3px;
    background: #0FB36C;
    width: 40%;
}

.sum_PostContainer .verify-person {
    text-align: right;
    width: 50px;
    padding: 5px
}

.sum_PostContainer .author .score {
    font-size: 10px;
    line-height: 12px;
}

.sum_Postdescription {
    font-size: 12px;
    width: 100%;
    height: 40px;
    line-height: 10px;
    color: #9393A7;
}

.sum_Postdescription #resizeImage {
    max-width: 30px;
    max-height: 30px;
}

.sum_Postdescription .textdesc .sum_mention_text,
.sum_Postdescription .sum_mention_text {
    height: 40px;
    padding-top: 5px;
    margin-bottom: 0;
    overflow: hidden;
    text-align: left;
    font-size: 12px;
    color: #545454;
    line-height: 16px;
    font-weight: 400;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    display: inline-block;
    width: 100%;
    letter-spacing: 0.4px
}

.sum_mention_text b {
    font-weight: 600 !important;
}

.sum_Postdescription p a {
    color: #0079c0;
}

.sum_Postoptions {
    border-top: solid 1px #e3e3e3;
    padding: 0 10px;
    font-size: 13px;
    float: left;
    width: 100%;
    clear: both;
    line-height: 36px;
}

.sum_Postoptions ul {
    padding: 0;
    margin: 0 !important;
    float: left;
}

.sum_Postoptions ul li {
    position: relative;
    cursor: pointer;
    float: left;
    margin: 0 5px;
}

.sum_Postoptions .mention_source {
    width: auto !important;
    display: block;
    overflow: auto;
    margin-right: 0 !important;
    color: #0079c0;
}

.sum_Postoptions>ul>li>a {
    font-size: 12px;
}

.sum_Postoptions>ul>li>a {
    color: #0079c0
}

.summaryPostListActive {
    opacity: 1 !important;
    /* text-shadow: 1px 1px 1px #848484; */
    border-bottom: unset !important;
}

.sum-des-below-graph {
    padding: 12px;
}

.sum-des-above-graph {
    padding: 12px 24px;
}

.sum-des-above-graph span{
    font-size: 12px;
    padding:0;
}

.wrapper .sum-des-below-graph p {
    margin-bottom: 0;
    font-style: italic;
    font-size: 12px;
    color: #545454;
}

@media (min-width:768px) {
    #content>.navbar.navbar-default {
        min-height: 60px;
        margin-bottom: 10px;
    }
}

@media (max-width:480px) {
    #content>.navbar.navbar-default {
        min-height: 60px;
        margin-bottom: 10px;
    }
}

.webPlotColor {
    height: 15px;
    width: 15px;
}

.web-plot-div {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.web-plot-text-div {
    font-size: 12px
}

.summary-tile-view {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}
.summary-tile-view1 {
    box-shadow: none;
}


/* .summary-tile-view:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
} */

.padding-12 {
    padding: 12px
}

.background-white {
    background: #fff;
}

.margin-left-5 {
    margin-left: 5px;
}

.negative-color {
    color: #f92000
}

.neutral-color {
    color: #00ccf7;
}

.positive-color {
    color: #c8e400;
}

.sum_PostContainer .author i.fa-youtube-play {
    color: red !important;
}

.negative-background {
    background-color: #f92000;
}

.neutral-background {
    background-color: #00ccf7;
}

.positive-background {
    background-color: #c8e400;
}

.ant-tag.not_found-background{
    border: 1px solid #d9d9d9;
}
.ant-tag.negative-background {
    border: 1px solid #f92000;
}
.ant-tag.neutral-background {
    border: 1px solid #00ccf7;
}
.ant-tag.positive-background {
    border: 1px solid #c8e400;
}

.identify-share {
    margin-top: -5px;
}

.share-text-div {
    font-size: 8px;
}

.sidebar-share {
    padding: 0px;
    font-size: 8px;
    /* color: #fff; */
    text-transform: uppercase;
    font-weight: 800;
    white-space: nowrap;
}
.notactiveProjFont{
    font-weight: 400 !important;
    color: rgba(0,0,0,.65);
}

a.color {
    color: #545454
}

a.color:hover,.header-color:hover,
a.color:active,.header-color:active,
a.color:focus,.header-color:focus {
    color: #0079c0 !important
}

.header-color{
    color: #212121 !important
}

a[class^="read-more-"] {
    color: #0079c0 !important;
}

.content-all-boxes {
    flex-basis: 100%;
    flex-grow: 1;
    background: #fff;
    line-height: 1.4;
}
.detailbody .padding-24Toggle{
    padding:24px
}

@media (min-width:481px) {
    .set-margin-left {
        margin-left: 12px;
    }
    .set-margin-right {
        margin-right: 12px;
    }
}

@media (max-width:480px) {
    .set-margin-top {
        margin-top: 24px;
    }
    .padding-24Toggle{
        padding:12px !important
    }
    .set-padding-header {
        padding: 24px 12px !important;
        background: #fff !important;
    }
}

.content-showtext-notfound{
    min-height: 100px;
    font-size: 12px;
    padding: 24px;
    display: flex;
    align-items: center;
}

.sum-des-above-graph .fa-lightbulb-o{
    text-align: left
}

/* .ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    transition: all .3s;
} */

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard {
    position: relative;
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartTop {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-avatar {
    position: relative;
    top: 4px;
    float: left;
    margin-right: 20px;
    
}
.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-meta {
    height: 22px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rmo-title-format{
    color: rgba(0,0,0,.45);
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0;
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-total {
    height: 38px;
    margin-top: 4px;
    margin-bottom: 0px;
    /* overflow: hidden; */
    color: rgba(0,0,0,.85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    word-break: break-all;
}
.wrapper .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-total a{
    height: 38px;
    margin-top: 4px;
    margin-bottom: 0px;
    overflow: hidden;
    color: rgba(0,0,0,.85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    word-break: break-all;
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-content {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
}
.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-footer {
    margin-top: 8px;
    padding-top: 9px;
    border-top: 1px solid #e8e8e8;
}
.antd-pro-pages-dashboard-analysis-components-charts-field-index-field {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 0;
}

.antd-pro-pages-dashboard-analysis-components-charts-field-index-field .antd-pro-pages-dashboard-analysis-components-charts-field-index-number {
    margin-left: 8px;
    color: rgba(0,0,0,.85);
}
.wrapper .antd-pro-pages-dashboard-analysis-components-charts-field-index-number a{
    margin-left: 8px;
    color: rgba(0,0,0,.85);
}
.kpi-view-container .ant-card-body{
    max-height: unset;
}

.antd-pro-pages-dashboard-analysis-components-trend-index-down {
    color: #f5222d;
    top: -1px;
}

.antd-pro-pages-dashboard-analysis-components-trend-index-up {
    color: #52c41a;
}

.antd-pro-pages-dashboard-analysis-components-trend-index-down, .antd-pro-pages-dashboard-analysis-components-trend-index-up {
    position: relative;
    top: 1px;
    margin-left: 4px;
}


.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.ant-card-head {
    min-height: 48px;
    /* margin-bottom: -1px; */
    padding: 0 24px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1;
}
.anticon svg {
    display: inline-block;
}

.ant-card-head-title {
    flex: 1 1;
    padding: 16px 0;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

.ant-card-head-wrapper {
    display: flex;
    align-items: center;
}

.antd-pro-pages-dashboard-analysis-components-trend-index-up i {
    font-size: 12px;
    transform: scale(.83);
}

.antd-pro-pages-dashboard-analysis-components-trend-index-down i {
    font-size: 12px;
    transform: scale(.83);
}
.antd-pro-pages-dashboard-analysis-style-trendText {
    margin-left: 8px;
    color: rgba(0,0,0,.85);
}
.wrapper .antd-pro-pages-dashboard-analysis-style-trendText a{
    margin-left: 8px;
    color: rgba(0,0,0,.85);
}


#content.notactive:not(.active) .kpi-text-overflow-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
}

.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target span {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 4px;
    border-radius: 100px;
}
.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target span {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 4px;
    border-radius: 100px;
}
.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
    width: 20px;
}
.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress {
    position: relative;
    width: 100%;
    padding: 5px 0;
}

.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-contentFixed {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    letter-spacing: 0;
}
.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-chartCard .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-content {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
}
.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-progressWrap {
    position: relative;
    background-color: #f5f5f5;
}
.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-progress {
    width: 0;
    height: 100%;
    background-color: #1890ff;
    border-radius: 1px 0 0 1px;
    transition: all .4s cubic-bezier(.08,.82,.17,1) 0s;
}
.antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-miniProgress .antd-pro-pages-dashboard-analysis-components-charts-mini-progress-index-target span:last-child {
    top: auto;
    bottom: 0;
}
.antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-action{
    color: rgba(0,0,0,.45);
}


.ant-pro-global-footer-copyright {
    color: rgba(0,0,0,.45);
    font-size: 14px;
}


.most-attributes-table td:first-child{
    border-top:unset
}


/* start Social Media KPI's Box */

.ant-card-meta-avatar {
    float: left;
    padding-right: 16px;
}
.ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
}
.ant-avatar-image {
    background: transparent;
}

.ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 40px;
    height: 40px;
    line-height: 32px;
    border-radius: 50%;
}

.ant-avatar>img {
    display: block;
    width: 100%;
    height: 100%;
}
.ant-card-meta-detail {
    overflow: hidden;
}
.social-card-meta-title {
    overflow: hidden;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    white-space: inherit;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    height: 40px;
    display: flex;
    align-items: center
}

.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo {
    margin-top: 16px;
    margin-left: 40px;
    zoom: 1;
}
.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo>div {
    position: relative;
    float: left;
    width: 50%;
    text-align: left;
}
/* .antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo>div p:first-child {
    margin-bottom: 4px;
    color: rgba(0,0,0,.45);
    font-size: 12px;
    line-height: 20px;
} */
.antd-pro-pages-list-search-applications-style-filterCardList .antd-pro-pages-list-search-applications-style-cardInfo>div p {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
}
/* 
.ant-card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fafafa;
    border-top: 1px solid #e8e8e8;
    zoom: 1;
    display: flex;
}

.ant-card-actions>li:not(:last-child) {
    border-right: 1px solid #e8e8e8;
}
.ant-card-actions>li {
    margin: 12px 0;
    color: rgba(0,0,0,.45);
    text-align: center;
}
.ant-card-actions>li>span {
    position: relative;
    display: inline-block;
    min-width: 32px;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}


.ant-card-actions>li>span>.anticon {
    font-size: 16px;
    line-height: 22px;
} */

/* .ant-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
} */
.social-kpi-fields{
    flex-basis: 100%
}
.social-kpi-fields h4{
    margin-bottom: 12px
}
.social-kpi-fields .social-kpi-show-count{
    font-size: 22px;
    color: #212121;
    font-weight: 400;
}
.social-kpi-lock{
    font-size: 24px!important;
    color: #fff;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,50%);
}
/* finish Social Media KPI's Box */


.show-outline.btn-outline-primary{
    background-color: transparent;
    color: #0079c0;
    border-color: #0079c0;
    opacity: .8;
}


.show-outline.btn-outline-primary:hover{
    background-color: transparent;
    color: #0079c0;
    border-color: #0079c0;
    opacity: 1;
}

.btn.btn-primary.rmo-submit-button{
    background-color: #0079c0 !important;
    border-color: #0079c0 !important;
    opacity: 0.9;
}

.btn.btn-primary.rmo-submit-button:hover, .btn.btn-primary.rmo-submit-button:focus, .btn.btn-primary.rmo-submit-button:active{
    opacity: 1;
}

.btn.btn-primary.newRmo-border-btn{
    background-color: #fff;
    border: 2px solid #0079c0 !important;
    color: #0079c0;
    opacity: .8;
}
.btn.btn-primary.newRmo-border-btn:hover{
    background-color: #fff ;
    border: 2px solid #0079c0 !important;
    color: #0079c0 !important;
    opacity: 1;
}

/* ======================login===================================== */

.login-form-box{
    padding: 0px 20%;
}
@media(max-width:480px){
    .login-form-box{
        padding: 0;
    }
    .sm-mb-24{
        margin-bottom:24px
    }
}
.login-label{
    color: #545454;
    font-weight: 400;
}
.newbtn.newRmo-border-btn{
    background-color: #fff;
    border: 2px solid #00dafc !important;
    color: #00dafc;
    opacity: .8;
}
.newbtn.newRmo-border-btn:hover, .newbtn.newRmo-border-btn:focus, .newbtn.newRmo-border-btn:active{
    background-color: #4632da ;
    border: 2px solid #4632da !important;
    color: #fff !important;
    opacity: 1;
}

.newbtn, a.newbtn{
    font-family: "Montserrat", Sans-serif;
    font-size: 12px;
    font-weight: 600;
    fill: #ffffff;
    color: #ffffff;
    background-color: #00dafc;
    border-radius: 30px 30px 30px 30px;
    line-height: 12px;
    padding: 10px 20px;
    border: unset;
    text-transform: uppercase;
}

a.newbtn:hover, .newbtn:hover, a.newbtn:active, .newbtn:active, a.newbtn:focus, .newbtn:focus {
    color: #fff;
    background-color: #4632da
}

.login-section{
    height:92vh;
}
/* --------------------------------------------------------------- */



.padding-top-12{
    padding-top: 12px
}
.signupLoginForm{
    padding: 24px 12px;
    background-color:#fff
}
.loginHeading{
    font-size: 40px;
    color: #545454;
    line-height: 63px;
    margin: 0;
    font-weight: 300;
}
.loginSubHeading{
    margin:12px 0;
    font-size: 20px;
    color: #545454;
    font-weight: 300;
}
.loginSubHeading a{
    color: #0079c0;
    font-size: inherit;
    font-weight: 300;
}
.antInputBox{
    border: 1px solid #E3E3E3;
}
.antInputBox:hover{
    border-color: #40a9ff
}
.antInputBox:focus{
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
}
.whatsIncludedPadding{
    padding: 24px 48px !important;
    background-color:#243e56
}
.signupWhatsNew li{
  padding:6px 0
}
.loginSignUpButton{
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    background-color: #009cfc;
    color: white;
    text-align: center;
    line-height: 21px;
    font-weight: 400;
    border-radius: 3px;
    padding: 10px 20px;
    margin: auto;
}
.loginSignUpButton:hover {
    background-color: #004f80;
    color: white;
}
.signupWhatsNew li:before {
  content:"\2713\0020";
  padding-right:5px
}
.signupWhatsNew{
  font-size:14px;
  margin:24px 0 50px
}
.trustedPartnersImg img{
  max-width:100px;
  max-height:30px;
  margin:12px;
}
.trustedPartnersImg{
  justify-content:center
}
@media(max-width:480px){
    .loginHeading{
        font-size: 32px;
        line-height: 46px;
    }
    .loginSubHeading{
        font-size: 16px;
    }
    .whatsIncludedPadding{
        padding: 45px 20px !important;
    }
}
@media(min-width:480px){
    .padding-md-left-0{
        padding-left: 0
    }
    .md-mr-12{
        margin-right:12px;
    }
    .md-ml-12{
        margin-left:12px;
    }
}

.padding-top-24{
    padding-top: 24px
}

.md-width-50{
    width: 100%
}

@media (min-width: 768px){
    .md-width-50{
        width: 50%
    }
}


.margin-bottom-12{
    margin-bottom: 12px;
}