.navbar-nav{
    li.layout_header-li {
        padding: 24px 0;
    }
}

.container-fluid{
    padding-left:3%;
    padding-right: 3%;
}
body{
    background-color: #eceff1;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}
.fa-percent:before{
    font-size:24px !important;
}
.starRatingBlock{
    margin-top: 10px;
    margin-bottom: 10px;
    .star-ratings-sprite {
        background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2605/star-rating-sprite.png") repeat-x;
        font-size: 0;
        height: 21px;
        line-height: 0;
        overflow: hidden;
        text-indent: -999em;
        width: 110px;
        margin: 0 auto;
        &-rating {
        background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2605/star-rating-sprite.png") repeat-x;
        background-position: 0 100%;
        float: left;
        height: 21px;
        display:block;
        }
    }
}
.main-panel {
    float:none;
    width:100%;
    .first-top-nav{
        position: fixed;
        width: 100%;
        z-index: 100;
        min-height:50px;
        // background-image: linear-gradient(263deg, #c500f9, #0079c0);
        color:#fff;
        border:none;
        .navbar-header{
            position: relative;
            z-index: 10;
        }
        .navbar-brand{
            padding: 0px;
            height: auto;
            color:#fff;
            margin-top: 0px;
            text-transform: capitalize;
            .rmo-icon-image{
                width:40px;
                height:40px;
            }
            .rmo-image{
                margin-left:5px;
            }
        }
        .navbar-form{
            .form-group{
                border-radius: 2px;
                background-color: #ffffff;
                border: solid 1px rgba(137, 158, 185, 0.5);
                width:325px;
                input{
                    font-size:12px;
                    width:300px;
                    color:#9b9b9b;
                }
                .input-group-btn{
                    margin-top: 1%;
                    margin-right: 4%;
                    .btn{
                        padding: 0px;
                        border: none;
                        margin: 0px;
                    }
                }
            }
        }
        .navbar-nav{
            li{
                a{
                    padding:0px 24px;
                    p{
                        color:#d4d4d4;
                    }
                    p:hover{
                        color:#fff;
                    }
                    span{
                        color:#d4d4d4;
                    }
                    span:hover{
                        color:#fff;
                    }
                    .fa{
                        color: #d4d4d4;
                        font-size:16px;
                    }
                    .fa:hover{
                        color: #fff;
                    }
                }
            }
        }
    }
    .second-top-nav{
        top:50px;
        position: fixed;
        min-height:50px;
        width: 100%;
        z-index: 100;
        background-color:#fff;
        border-bottom: solid 1px rgba(137, 158, 185, 0.5);
        .navbar-brand{
            padding-left: 0px;
            padding-top:5px;
            padding-right: 5px;
            text-transform: capitalize;
            height: 50px;
        }
        .brand-name-container{
            word-wrap: break-word;
            margin-top: 10px;
            line-height:12px;
            margin-bottom: 10px;
            text-transform: capitalize;
            display: inline-block;
            .notyou{
                font-size: 10px;
                text-decoration: underline;
            }
        }
        .brand-image{
            width:40px;
            height:40px;
        }
        img{
            height: 50px;
        }
        ul{
            li{
                font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
                font-size: 12px;
                font-weight: 600;
                opacity: 0.8;
                color: #000000;
                margin-right: 5px;
                margin-top: 3px;
                height: 45px;
                text-align: center;
                a{
                    padding-top: 15px;
                    p{
                        margin-bottom:0px;
                        font-size: 12px;
                        color: #333;
                    }
                }
            }
            li.active,li:hover{
                opacity:1;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
                background-color: #0079c0;
                box-shadow: 0 0 16px 0 rgba(0, 121, 192, 0.25);
                p{
                    color:#fff;
                }
            }
        }
    }
}
body>.footer{
    position:relative;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e7e7e7;
    z-index:1000;
}
ol,p,ul,div,a{
    font-size:14px;
}
.headerName-container{
    position:fixed;
    z-index:100;
    width:100%;
    margin-top:100px;
    background-color: #eceff1;
    .headerName{
        margin-top: 15px;
        opacity: 0.8;
        font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
        font-size: 24px;
        font-weight: 800;
        text-align: left;
        color: #545454;
        padding-bottom: 14px;
        margin-bottom: 0px;
    }
}
#content-dashboard{
    // margin-top:158px;
    background-color: #eceff1;
    min-height:600px;
    position:relative;
    .fixed{
        position:fixed;
    }
    .card{
        min-height:300px;
       
        .sentiment-indicator{
            padding-left:10px;
            padding-right: 20px;
        }
        .legend{
            margin-top:20px;
            div{
                font-size:13px;
            }
        }
        .footer{
            .legend{
                width:100%;
            }
        }
        .keyword{
            padding:10px;
            margin-bottom: 15px;
            border-radius: 24px;
            background-color: #0079c0;
            border: solid 1px #0079c0;
            display: inline-block;
            .keyword-number{
                padding: 5px 10px;
                border-radius: 24px;
                background-color: #ffffff;
                border: solid 2px #ffffff;
                color:#0079c0;
                font-size:16px;
                font-weight: 800;
                text-align: center;
            }
            .keyword-text{
                padding: 0px 10px;
                color:#fff;
                font-size: 13px;
                text-align: left;
                font-weight: 600;
            }
        }
        .left-nav{
            li{
                width:100%;
                a{
                    position:relative;
                    padding:25px 15px;
                    border-bottom: solid 1px rgba(137, 158, 185, 0.5);
                    p{
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
                        font-size: 13px;
                        letter-spacing: 0.05em;
                        text-transform: capitalize;
                        text-align: left;
                        color: #545454;
                    }
                }
                a.active,a:hover,a:focus,.infocus{
                    background-color: #0079c0;
                    p{
                        color:#fff;
                    }
                }
            }
        }
        #ratings-on-the-web{
            .carousel-inner{
                min-height: 20px;
            }
            .carousel-indicators {
                bottom : -25px;
            }
            .carousel-indicators li{
                border-color: #0079c0;
                border : 1px solid #0079c0;
            }
             .carousel-indicators .active{
                background-color :  1px solid #0079c0
            }
        }
        .gsearchBoxes{
            // padding-top:15px;
            .gsearchBox{
                //border-radius: 2px;
                background-color: #ffffff;
                //box-shadow: 0 0 16px 0 rgba(0, 121, 192, 0.1);
                //border: solid 1px rgba(0, 0, 0, 0.1);
                padding: 0px 10px 10px 10px;
                // margin-top: 10px;
                .counttext{
                    font-size: 16px;
                    text-align: center;
                }
                .countnumber{
                    text-align: center;
                    font-size:38px;
                    font-weight:600;
                    height:55px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .reputationScoreBox{
            text-align: center;
            margin-top:20px;
            h1{
                font-size:72px;
            }
            /*border-radius: 2px;
            background-color: #ffffff;
            box-shadow: 0 0 16px 0 rgba(0, 121, 192, 0.1);
            border: solid 1px rgba(0, 0, 0, 0.1);
            padding: 10px;*/
        }
        .searchReviewsBlock{
            h4{
                margin-bottom: 0px;
            }
        }
        #fbCommentsDetailed{
            .fb-comment-text{
                margin-bottom:0px;
                margin-top:20px;
                font-size: 13px;
                text-align: justify;
                color: #4a4a4a;
            }
            .fb-comment-time{
                margin-bottom:10px;
                font-size: 13px;
                color: #9b9b9b;
            }
        }
    }
    #reviewsAndComplaints,#mentions{
        .card{
            min-height:100px;
            form{
                .filter-element{
                    width:100%;
                    button{
                        width:100%;
                        font-size: 13px;
                        text-transform: capitalize;
                        letter-spacing: 0.05em;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        z-index:0;
                        border-bottom:solid 1px rgba(137,158,185,.5);
                        padding:20px;
                        border-top:none;
                        border-left:none;
                        border-right:none;
                        border-radius: 0px;
                    }
                    button:hover{
                        z-index:0;
                        .caret{
                            border-top-color: #fff;
                            color:#fff;
                        }
                    }
                    .dropdown-menu{
                        border-radius: 0px;
                        width: 100%;
                        z-index:1;
                        .dropdown-item{
                            padding:15px;
                            color: #768aa0;
                            border-bottom:solid 1px rgba(137,158,185,.5);
                        }
                    }
                }
            }
        }
        .reviewObject,.mentionObject{
            .reviewSite,.mentionSite{
                color: #848484;
                font-size:14px;
                padding-left: 10px;
                padding-top:10px;
                padding-bottom: 0px;
                margin-bottom:0px;
            }
            .starRatingBlock{
                padding-left: 10px;
                padding-top: 0px;
                .star-ratings-css{
                    margin:0;
                }
            }
            .reviewText,.mentionText{
                color: #4a4a4a;
                font-size: 14px;
                line-height: 1.5;
                padding: 10px;
                font-weight: 400;
                margin-bottom:0px;
                letter-spacing: 0.04em;
                text-align: justify;
            }
            .mentionImage{
                max-width: 100%;
                padding-top: 10px;
            }
            .mentionCitation{
                background-color: yellow;
                font-weight: 600;
            }
            .mentionTitle{
                color: #4a4a4a;
                padding-left: 10px;
                padding-top: 10px;
                font-size: 16px;
                font-weight: 600;
                margin-bottom:0px;
                letter-spacing: 0.04em;
            }
            .pe-7s-pen{
                text-transform: capitalize;
            }
        }
    }
}
.analysis{
    h2{
        font-size:28px;
        color:#848484;
    }
    ul{
        li{
            padding-top: 10px;
            margin-bottom:10px;
            h3{
                font-size: 18px;
                color: #545454;
                margin-bottom: 10px;
                font-weight: 600;
            }
            hr{
                margin-bottom:0;
            }
            p{
                font-size: 15px;
                letter-spacing: .02em;
                text-align: justify;
                margin-bottom:0px;
            }
        }
    }
}
#ratings-on-the-web-start,
#reviews-on-the-web-start,
#statewise-user-split-start,
#brand-keywords-start,
#popularity-graph-start,
#facebook-comments-summary-start,
#complaints-on-the-web-start,
#google-suggestive-search-start,
#google-search-results-start,
#seoprofiler-start,
#facebook-comments-detailed-start,
#perception-score-start
{
    position:absolute;
    top:-170px;
}
#ratings-on-the-web,
#reviews-on-the-web,
#statewise-user-split,
#brand-keywords,
#popularity-graph,
#facebook-comments-summary,
#complaints-on-the-web,
#google-suggestive-search,
#google-search-results,
#seoprofiler,
#rep-score,
#facebook-comments-detailed,
#perception-score {
    min-height: 200px;
}
.login-holder{
    position: relative;
    top: 150px;
    z-index: 1;
    margin: 0 auto;
    width: 100%;
    .social-disclaimer{
        font-size:13px;
    }
}
#login-spinner{
    top:200px;
}
.after-login{
    text-align: center;
    font-size: 14px;
    color:green;
    display:none;
}
.checkbox,.radio{
    span{
        padding-left: 15px;
    }
}

.fh5co-cover .desc .examples{
    font-size:12px;
}
.row.content.printPDF {
  .carousel-indicators { display: none; }
  .carousel-control { display: none; }
  .carousel-inner>.item{
                display: block;
        }
        .neeraj{text-align: center; }
}
.row.content.printPDF.googleSuggestive {
    .col-xs-12{
        width : 50%
    }
    .col-md-12{
        width : 50%
    }
}
.row.content.printPDF .facebookCommentsPieChartClass {

    page-break-inside : avoid;
    float:none;
     display: inline-block;
    div[class|=col-]{float:left;}
    .col-sm-6{width:50%}
    .col-xs-12{
        width : 50%
    }
    #commentsPieChart {
        margin-top : 0px !important;
        width : 500px
    }
    #fbCommentsDetailed {
          margin-top : 0px
    }
    .chartGraph{
        float:none;
        display: inline-block;
        //padding-right:  150px;
        margin-right:10px;
        padding-left : 10px;
        margin-left : 10px;

        
    }
    .commentsGraph{
        float:none;
        display: inline-block;
        padding-right:10px;
        padding-left : 10px;
       
    }
    .ct-chart-donut{
        width : 80%;
        float : left;
    }
    .gsearchBox{
        float:none;
        display: inline-block;
    }
    .legend{
         padding-right:100px;
        padding-left : 100px;
    }
}
#pageHeader{
    position: running(pageHeader);
}

#pageFooter{
    position: running(pageFooter);
}

.printPDFBackground{
    background-color: white
}

#facebook-comments-summary,#seoprofiler{
    .ranking{
                font-size: xx-large;
                font-weight: 400;
                color: #0079c0;
}
        
    .facebookCommentsPieChartClass{
        margin-top : 50px;
    }
    .fbheading {
            //margin-left: 50px;
            padding-left:15px;
            margin-top: 50px;
            font-family: Roboto, Arial, sans-serif;
            font-size: 22px;
            font-weight: 300;
    }
    .post-block {
    display: flex;
    margin-bottom: 10px;
    margin-left: 50px;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px #bbb;
    align-items: baseline;
    margin-right: 30px;
    p{
        padding-right : 20px;
    }
    }
    .post-content {
    margin-left: 20px;
    width:100%
    }
    .post-share-data{
    display: flex;
    flex-direction: row;
    }
    .share-data-item {
        margin-left : 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: initial;
        align-items: center;
    }
}

/* Custom, iPhone Retina */
@media (min-width : 320px) {
    .notifMessage {
    .notifyHeading {
            font-size: large;
    }
    ul {
        color: white;
        font-size: 13px;
         font-weight: 400;
    }
    .endnote {
        text-align: center;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    }
}
    
    .competitiveAnalysis {
        table-layout: fixed;
        th td {
            padding : 4px
        }
        th{
            padding : 4px
        }
        .leftheadings1 {
        background-color: rgba(29, 199, 234, 0.56);
        color : black !important
    }
    .brandHeading{
            font-size: 18px;
            font-weight: 600;
            color:black;
            word-wrap: break-word;
    }
    .minusButton{
            margin-left: 5px;
            padding: 0px;
    }
    .reputationScore{
            font-size: 18px !important;
    }
    .leftheadings {
        background-color: #0079c0;
        color: white;
        width: 100%;
        word-wrap: break-word;
    }
     .neutral {
         font-size: 22px;
          font-weight: 600;
           color: darkslategrey;
           background-color :white;
            text-align: center;
    }
    .good{
            font-size: 14px;
            font-weight: 600;
            background-color :#08eb66;
            color: #fff;
            text-align: center;
    }
    .bad {
        font-size: 14px;
        font-weight: 600;
        background-color: #f02836;
        color: white;
        text-align: center;
    }
}

    .alt-table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dddddd;
    -webkit-overflow-scrolling: touch;
  }

    #facebook-comments-summary,#seoprofiler{
        .ranking{
                font-weight: 400;
                color: #0079c0;

        }
        .linkBlock{
                width: 90%;
                word-wrap: break-word;
                font-size: 16px;
}
        
    .post-block {
    display: flex;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 10px;
    box-shadow: 2px 2px 2px 2px #bbb;
    align-items: baseline;
    margin-right: 10px;
        p   {
        padding-right : 20px;
        }
    }
}
    .login-holder{
        .social-disclaimer{
            font-size:11px;
        }
    }
    .loginForm{
        .login-button{
            margin-top: 3%;
        }
    }
    .button-footer{
        position: fixed;
        top: ~"calc(100% - 50px)";
        background-color: #0079c0;
        width: 100%;
        height: 50px;
        z-index: 2000;
        color:#fff;
        .improve-footer,.feedback-footer{
            text-align: center;
            padding: 5px;
            // border-right: 1px solid #fff;
            a{
                p{
                    color: #fff;
                    font-size: 17px;
                    padding: 7px;
                }
            }
        }
    }
    .desktop{
        display:none ;
    }
    .mobile{
        display:block;
    }
    .tablet{
        display:none;
    }
    .navbar{
        .container-fluid{
            .navbar-header{
                margin-left:0px;
                margin-right:0px;
            }
        }
    }
    .main-panel{
        .second-top-nav{
            top:0px;
            position: relative;
        }
    }
    .fixed-pos-top-nav{
        top: 0 !important;
        z-index: 100 !important;
        position: fixed !important;
        width: 100% !important;
    }
    .headerName-container{
        margin-top:0px;
        position:relative;
        .dot{
            text-align: center;
            background-color: #333;
            color: #fff;
            border-radius: 27px;
            width: 15%;
            margin-right: 1.6%;
            margin-top: 15px;
        }
        .headerName{
            font-size:1.5em;
            margin-top:15px;
            padding-bottom: 15px;
        }
    }
    // #content-dashboard{
    //     margin-top: 5px;
    // }
}
/* Extra Small Devices, Phones */
@media (min-width: 480px) {
}
/* Small Devices, Tablets */
@media (min-width: 768px) {
    .tablet{
        display:initial;
    }
    .social-disclaimer{
        font-size:13px;
    }
    .loginForm{
        .login-button{
            margin-top: 3%;
        }
    }
}
/* Medium Devices, Desktops */
@media (min-width : 960px) {
   
    .notifMessage {
    .notifyHeading {
            font-size: x-large;
    }
    ul {
        color: white;
        font-size: 16px;
         font-weight: 400;
    }
    .endnote {
        text-align: center;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    }
}
    .competitiveAnalysis {
    .brandHeading{
            font-size: 22px;
            font-weight: 600;
            color:black;
            word-wrap: break-word;
    }
    .leftheadings1 {
        background-color: rgba(29, 199, 234, 0.56);
        color : black !important
    }
    .minusButton{
            margin-left: 5px;
            padding: 0px;
    }
    .reputationScore{
            font-size: 66px !important;
    }
    .leftheadings {
        background-color: #0079c0;
        color: white;
    }
    .neutral {
         font-size: 22px;
          font-weight: 600;
           color: darkslategrey;
           background-color :white;
            text-align: center;
    }
    .good{
            font-size: 22px;
            font-weight: 600;
            background-color :#08eb66;
            color: #fff;
            text-align: center;
    }
    .bad {
        font-size: 22px;
        font-weight: 600;
        background-color: #f02836;
        color: white;
        text-align: center;
    }
}

    .navbar-collapse.collapse{
        display: block;
    }
    .desktop{
        display:initial;
    }
    .mobile{
        display:none;
    }
    #content-dashboard{
        // margin-top:154px;
        .sibling-fixed-2{
            margin-left: 17%;
        }
        .sibling-fixed-3{
            margin-left: 25%;
        }
    }
    .main-panel{
        .second-top-nav{
            top:50px;
            position:fixed;
            .brand-name-container{
                width: 120px;
            }
            #secondNav{
                .navbar-nav{
                    margin-left: 0px;
                    li{
                        margin-right: 1px;
                        border-bottom: 1px solid #999;
                        border-right: 1px solid rgba(0,0,0,0.2);
                        border-left: 1px solid rgba(0,0,0,0.2);
                        border-radius: 18px;
                        a{
                            p{
                                font-size:11px;
                            }
                        }
                    }
                }
            }
        }
    }
    .first-top-nav{
        .navbar-header, .navbar-collapse{
            padding-top:5px;
        }
        .navbar-right{
            padding-top: 5px;
        }
        .navbar-center
        {
            position: absolute;
            width: 100%;
            text-align: center;
            margin: auto;
            padding-top: 5px;
        }
    }
    .headerName-container{
        margin-top:80px;
        position:fixed;
    }
}
/* Large Devices, Wide Screens */
@media (min-width : 1200px) {
    .main-panel{
        .second-top-nav{
            .brand-name-container{
                width: 140px;
            }
            ul{
                li{
                    margin-right: 3px;
                    a{
                        p{
                            letter-spacing: 0.0em;
                            font-size:13px !important;
                        }
                    }
                }
            }
        }
    }
    #content-dashboard .card .left-nav li a p{
        font-size:14px;
      
    }
}
.ct-series-1 .ct-line {
  /* Set the colour of this series line */
  stroke : #FF1493;
  /* Control the thikness of your lines */
  stroke-width: 5px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 10px 20px;
}

.ct-series-2 .ct-line {
  /* Set the colour of this series line */
  stroke: #006400;
  /* Control the thikness of your lines */
  stroke-width: 5px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 10px 20px;
}

#rep-score {
    .analysis ul li {
    padding-top: 1px;
    margin-bottom: 1px;
    }
    .analysis h3 {
        font-size :  14px;
        font-weight: 300
    }
    .analysis img {
    width: 15px;
}
.reputationScoreBox {
    margin-top : 0px !important
}
 h4 {
        margin-bottom : 0px
    }
}