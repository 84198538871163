.contentOption{
  cursor: pointer;
  font-size: 14px;
  flex: 1;
  padding: 12px 24px;
  font-weight:500;
  height: 40px;
  margin: 0 !important
}

.contentOption hr{
  margin-top: 12px!important;
  margin: 0 -25px;
}
.contentOption:hover{
  background: aliceblue;
  transition: all 0.5s
}
.contentOption.active {
  color:#4285f4;
  background: #f0f8ff;
  border-bottom: 2px solid #4285f4;
  z-index: 1
}
.contentPageBox{
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width:100%
}
#contentPageOptions::-webkit-scrollbar{
  height:5px
}
.content-all-boxes .detailDataItem{
  margin-bottom: 0;
}
.content-all-boxes .postImage{
  max-width: 100% !important;
}
.content-all-boxes .kpi-view-container{
  flex:1;
  padding: 12px 24px;
  border: 1px solid #d3d3d3;
  margin-right: 24px;
  border-radius: 4px;
}
.content-all-boxes .kpi-view-container .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-total{
  margin-top: 18px;
}
.content-all-boxes .kpi-view-container:last-child{
  margin-right: 0;
}
.content-all-boxes .oembedBox{
  margin:0 0 0 24px;
  max-width: 80%;
}
.content-all-boxes .kolUserData{
  min-width: 80px; 
  justify-content: space-evenly; 
  align-items: center; 
  width: 25%; 
  margin:0 24px
}
#socialAccountSummary .oembedBox{
  margin-left: 0;
}

@media (max-width:480px){
  .contentPageBoxDiv{
    padding: 0 !important;
  }
  .content-all-boxes .kpi-view-container{
    margin-right: 0;
    margin-bottom: 24px;
  }
  .content-all-boxes .kpi-view-container:last-child{
    margin-bottom: 0;
  }
  .content-all-boxes .oembedBox{
    margin: 24px 0 24px;
    max-width: 100%;
  }
  .content-all-boxes .kolUserData{
    min-width: 60px; 
    align-items: center; 
    width: unset; 
    margin:0
  }
}