.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    border-radius: 10px;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 10px;
    background-color: #E1E0E0;
    left: calc(50% - 5px);
}

.timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    width: 35%;
    left: 10.8%;
    border: 2px solid #0079c0;
    border-radius: 10px;
    padding: 0px;
    position: relative;
    background: #fff;
    color: #000000;
    cursor: pointer;
    top: -1px;
}

.ext-logo {
    max-height: 40px;
    max-width: 40px;
}

.timeline>li .timeline-panel:after {
    position: absolute;
    top: 15px;
    right: -14px;
    display: inline-block;
    border-top: 16px solid transparent;
    border-left: 16px solid #0079c0;
    border-right: 0 solid #0079c0;
    border-bottom: 16px solid transparent;
    content: " ";
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 1.4em;
    display: block;
    margin: 1em auto;
    background-size: cover;
    background-position: center center;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    border: 5px solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: calc(50% - 20px);
    background-color: #0079c0;
    z-index: 2;
    border-radius: 50%;
    text-align: center;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.timeline>li>.timeline-badge1 {
    color: #fff;
    width: 65px;
    height: 65px;
    display: block;
    margin: 1em auto;
    background-size: cover;
    background-position: center center;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    border: 5px solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    left: calc(50% - 33px);
    background-color: #999;
    z-index: 2;
    padding-top: 10px;
}

.t1 {
    top: -90px;
}

.t2 {
    top: -40px;
}

.timeline>li.timeline-inverted .timeline-panel {
    left: 55%;
}

.timeline>li.timeline-inverted .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline>li.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.ft {
    padding: 15px;
}

.hd {
    font-size: 3.5em;
    float: left;
    font-weight: bold;
    margin: auto;
}

.texte {
    float: right;
    padding-right: 15px;
}

.bt {
    font-size: 12px;
    border: 0px solid#000000;
    border-radius: 20px;
    color: #777;
    font-family: arial black;
    background-color: #fff;
}

.timeline-title {
    width: 100%;
    height: 80px;
    margin-top: 0;
    color: #fff;
    background-color: #0079c0;
    border-radius: 8px 8px 0px 0px;
    line-height: 25px;
    font-size: 18px;
    margin: auto;
    padding: 25px 0px 0px 10px;
    vertical-align: middle;
}

/* .timeline-body p,
.timeline-body>ul {
    width: 100%;
    margin-bottom: 0;
    display: block;
    background-color: #e6e6e6;
    border: 0px solid#aaa;
    border-radius: 5px;
    font-size: 15px;
    padding: 10px;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    margin-top: 10px;
} */

@media (max-width: 767px) {
    .timeline:before {
        width: 10px;
        left: 15px;
    }
    .timeline>li .timeline-panel {
        width: 70%;
        left: 20%;
        border: 2px solid #0079c0;
        border-radius: 10px;
        padding: 0px;
        top: -1px;
    }
    .timeline>li .timeline-panel:after {
        position: absolute;
        top: 15px;
        right: -14px;
        display: inline-block;
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
        content: " ";
    }
    .timeline>li>.timeline-badge {
        width: 30px;
        height: 30px;
        font-size: 12px;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        padding-top: 2px;
        font-family: Arial;
    }
    .timeline>li>.timeline-badge1 {
        width: 40px;
        height: 40px;
        line-height: 14px;
        font-size: 12px;
        left: 0%;
    }
    .t1 {
        top: -50px;
    }
    .t2 {
        top: -10px;
    }
    .timeline>li.timeline-inverted .timeline-panel {
        left: 20%;
    }
    .ft {
        padding: 15px;
    }
    .hd {
        font-size: 2.5em;
        padding: 13px 20px 20px 0px;
    }
    .texte {
        padding-top: 13px;
    }
    .timeline-title {
        width: 100%;
        height: 60px;
        line-height: 25px;
        padding-top: 5px;
        font-size: 18px;
        font-family: arial;
    }
    .timeline-body p,
    .timeline-body>ul {
        font-size: 15px;
    }

    .timeline-body{
        box-sizing: border-box;
    }
    
    .arrow{
        font-size: 12px;
    }
    .event-box{
        display: flex;
        flex-direction: row;
        background-color: #f2f2f2;
        
    }
    .box-icon{
        font-size:24px;
        flex-direction: row;
        justify-content: center;
        display: flex;
        padding: 5%;

    }
    .icon{
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 24px;
    }
    .box-content
    {
        flex-direction: column;
        font-size: 20px;
        flex-grow: 100;
    }
    .increment{
        display: flex;
        flex-direction: column;
        justify-content: center;

    }
    .content-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 20px;
    }
    .events{
        padding: 5px;
    }
   
    
}

@media (min-width: 768px) and (max-width: 1365px) {
    .timeline:before {
        left: calc(50% - 5px);
    }
    .timeline>li .timeline-panel {
        width: 37%;
        left: 8%;
        border: 2px solid #0079c0;
        top: 10px;
    }
    .timeline>li .timeline-panel:after {
        top: 15px;
        right: -14px;
    }
    .timeline>li>.timeline-badge {
        width: 50px;
        height: 50px;
        font-size: 1.6em;
        left: calc(50% - 25px);
        border-radius: 50%;
    }
    .timeline>li>.timeline-badge1 {
        width: 65px;
        height: 65px;
        font-size: 1.4em;
        left: calc(50% - 33px);
        padding-top: 10px;
    }
    .t1 {
        top: -90px;
    }
    .t2 {
        top: -40px;
    }
    .timeline>li.timeline-inverted .timeline-panel {
        left: 57%;
    }
    .ft {
        padding: 15px;
    }
    .hd {
        font-size: 3.5em;
        float: left;
        font-weight: bold;
        margin: auto;
    }
    .texte {
        padding-right: 17px;
    }
    .timeline-title {
        line-height: 25px;
        font-size: 19px;
        padding: 25px 0px 0px 10px;
    }
    .timeline-body p,
    .timeline-body>ul {
        font-size: 12px;
    }
    .timeline-body{
        box-sizing: border-box;
    }


    .arrow{
        font-size: 12px;
    }
    .event-box{
        display: flex;
        flex-direction: row;
        background-color: #f2f2f2;
        
    }
    .box-icon{
        font-size:24px;
        flex-direction: row;
        justify-content: center;
        display: flex;
        padding: 5%;

    }
    .icon{
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
    }
    .box-content
    {
        flex-direction: column;
        font-size: 20px;
        flex-grow: 100;
    }
    .increment{
        display: flex;
        flex-direction: column;
        justify-content: center;

    }
    .content-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 20px;
    }
    .events{
        padding: 5px;
    }
    
   
   
}