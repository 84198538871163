@font-face{
  font-family:casino_handregular;
  src:url(fonts/Casino_Hand/casino_hand-webfont.eot);
  src:url(fonts/Casino_Hand/casino_hand-webfont.eot?#iefix) 
  format('embedded-opentype'),
  url(fonts/Casino_Hand/casino_hand-webfont.woff) 
  format('woff'),
  url(fonts/Casino_Hand/casino_hand-webfont.ttf) 
  format('truetype'),
  url(fonts/Casino_Hand/casino_hand-webfont.svg#casino_handregular) 
  format('svg');font-weight:400;font-style:normal
}
.enjoyhint {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1100;
  pointer-events: none;
  overflow: hidden;
}

.enjoyhint_hide {
  display: none;
}

.enjoyhint_disable_events {
  position: absolute;
  width: 2000px;
  height: 1500px;
  z-index: 1011;
  pointer-events: all;
}

.enjoyhint_next_btn,
.enjoyhint_skip_btn {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100px;
  height: 40px;
  font: normal normal normal 17px/40px "Advent Pro", Helvetica, sans-serif;
  cursor: pointer;
  margin: 0 auto;
  letter-spacing: 1px;
  text-align: center;
}

.enjoyhint_close_btn,
.enjoyhint_next_btn,
.enjoyhint_skip_btn {
  z-index: 1012;
  pointer-events: all;
  -o-text-overflow: clip;
  text-overflow: clip;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.enjoyhint_next_btn {
  position: absolute;
  box-sizing: content-box;
  -webkit-border-radius: 40px;
  color: rgba(255, 255, 255, 1);
  border-radius: 40px;
  background: #1ecd97;
  -webkit-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  -moz-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  -o-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
}

.enjoyhint_next_btn:hover {
  color: rgb(255, 255, 255);
  background: rgb(23, 148, 108);
  border: 2px solid rgba(33, 224, 163, 1);
}

.enjoyhint_next_btn:active {
  border: 2px solid rgba(33, 224, 163, 1);
  background: rgba(33, 224, 163, 1);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.enjoyhint_skip_btn {
  box-sizing: content-box;
  -webkit-border-radius: 40px;
  color: rgba(255, 255, 255, 1);
  border-radius: 40px;
  background: #1ecd97;
  -webkit-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  -moz-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  -o-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
}

.enjoyhint_skip_btn:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0);
  border: 2px solid rgba(33, 224, 163, 1);
}

.enjoyhint_skip_btn:active {
  border: 2px solid rgba(33, 224, 163, 1);
  background: rgba(33, 224, 163, 1);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.enjoyhint_close_btn {
  display: inline-block;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: .3em;
  height: .3em;
  border: none;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  font: 400 8em/normal Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 1);
  background: rgba(0, 0, 0, 0);
  border: 2px solid rgba(33, 224, 163, 1);
}

.enjoyhint_close_btn::after,
.enjoyhint_close_btn::before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 73%;
  height: 2px;
  position: absolute;
  content: "";
  border: none;
  font: 400 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 1);
  background: #fff;
  text-shadow: none;
  -o-text-overflow: clip;
  text-overflow: clip;
  display: inline-block;
}

.enjoyhint_close_btn::before {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  top: 48%;
  left: 14%;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.enjoyhint_close_btn::after {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  top: 46%;
  left: 15%;
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.enjoyhint_close_btn:hover {
  color: rgba(255, 255, 255, 1);
  background: #1ecd97;
  cursor: pointer;
}

.enjoyhint_close_btn:active {
  border: 2px solid rgba(33, 224, 163, 1);
  background: rgba(33, 224, 163, 1);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.enjoyhint_btn {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 150px;
  height: 40px;
  cursor: pointer;
  margin: 0 auto;
  border: 2px solid #1ecd97;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  font: normal normal normal 17px/40px "Advent Pro", Helvetica, sans-serif;
  color: #1ecd97;
  text-align: center;
  -o-text-overflow: clip;
  text-overflow: clip;
  letter-spacing: 1px;
  background: rgba(0, 0, 0, 0);
  -webkit-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  -moz-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  -o-transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
  transition: background-color .3s cubic-bezier(0, 0, 0, 0), color .3s cubic-bezier(0, 0, 0, 0), width .3s cubic-bezier(0, 0, 0, 0), border-width .3s cubic-bezier(0, 0, 0, 0), border-color .3s cubic-bezier(0, 0, 0, 0);
}

#kinetic_container,
.enjoyhint_canvas,
.enjoyhint_svg_wrapper,
.enjoyhint_svg_wrapper svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.enjoyhint_btn:hover {
  color: #1ecd97;
  background: #1ecd97;
}

.enjoyhint_btn:active {
  border: 2px solid rgba(33, 224, 163, 1);
  background: rgba(33, 224, 163, 1);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.enjoyhint div.canvas-container {
  position: absolute;
}

.enjoyhint_canvas {
  z-index: 100;
  pointer-events: none;
}

#kinetic_container {
  pointer-events: none;
  top: 0;
  left: 0;
}

.enjoyhint_svg_wrapper {
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-transition: opacity .4s cubic-bezier(.42, 0, .58, 1);
  -moz-transition: opacity .4s cubic-bezier(.42, 0, .58, 1);
  transition: opacity .4s cubic-bezier(.42, 0, .58, 1);
}

.enjoyhint_svg_wrapper svg {
  top: 0;
  left: 0;
}

.enjoyhint_svg_transparent .enjoy_hint_label,
.enjoyhint_svg_transparent .enjoyhint_svg_wrapper {
  opacity: 0;
}

.enjoy_hint_label1 {
  background-color: #111;
  box-shadow: 0px 0px 40px 13px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 40px 13px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 40px 13px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  position: absolute;
  color: #1ecd97;
  z-index: 107;
  padding: 5px 24px;
  font-size: 22px;
  font-family: Arial;
  -webkit-transition: opacity .4s cubic-bezier(.42,0,.58,1);
  -moz-transition: opacity .4s cubic-bezier(.42,0,.58,1);
  transition: opacity .4s cubic-bezier(.42,0,.58,1);
  display: inline-block;
  min-width: 200px;
  text-align: center;
  max-width: 80%;
}
.enjoy_hint_label{
  min-width: 200px;
  text-align: center;
  max-width: 80%;
  position: absolute;
  color: #fff;
  z-index: 107;
  font-size: 22px;
  font-family: casino_handregular,Arial;
  -webkit-transition: opacity 400ms cubic-bezier(.42,0,.58,1);
  -moz-transition: opacity 400ms cubic-bezier(.42,0,.58,1);
  transition: opacity 400ms cubic-bezier(.42,0,.58,1);
}

div.kineticjs-content {
  position: absolute !important;
}

.enjoyhint_skip_btn {
  position: fixed;
  top: 500px;
  left: 130px;
}