/* css only for newUserSelectOption */


.nu-select-option .nu-selectionBox:hover{
  /* opacity: 1; */
  background-color: #f8f8f8;
  border: 1px solid #0079c0;
}   
.nu-usecase.nu-selectionBox{
  justify-content: space-between
}

.nu-selectionBox {
  position: relative;
  width: 100%;
  max-width: 235px;
  margin: 0;
  padding: 24px;
  margin-bottom: 24px;
  border: 1px solid #ccc;
  box-shadow: unset;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow .25s;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.nu-step2-options>div>.nu-selectionBox:nth-child(2), .nu-step1 .nu-project-image-selectionBox:nth-child(2) {
  animation-delay: 0.4s;
}

.nu-step2-options>div>.nu-selectionBox:nth-child(3), .nu-step1 .nu-project-image-selectionBox:nth-child(3) {
  animation-delay: 0.8s;
}

.nu-step2-options>div>.nu-selectionBox:nth-child(4), .nu-step1 .nu-project-image-selectionBox:nth-child(4) {
  animation-delay: 1.2s;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nu-usecase.nu-selectionBox{
  max-width: 170px;
}
.nu-step2-options .nu-selectionBox {
  width: 100%;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.nu-selectionBox img{
  width: 75px;
  padding: 12px;
}

.nu-selectionBox p{
  font-weight: 400;
  color: #000;
  padding: 10px 0;
  margin: 0;
  font-size: 12px;
}
.nu-step2-options .nu-selectionBox p{
  font-size: 14px;

}
.nu-step2-options .nu-selectionBox p.nu-heading{
  text-align: left;
  overflow: hidden;
  /* text-transform: uppercase; */
  margin-bottom: 12px;
  line-height: 24px;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  padding: 0;
  font-size: 16px;
  /* white-space: nowrap;
  text-overflow: ellipsis; */
}
.nu-step2-options .nu-selectionBox p.nu-subheading{
  padding-top: 0px;
  padding-bottom: 12px;
  font-size: 14px;
  text-align: left;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  color: rgba(0,0,0,.65);
}
.nu-select-h3{
  /* margin:0; */
  color: rgb(101, 101, 101);
  font-weight: 300;
  line-height: 32px;
  text-align: center;
}



body{
  padding: 0 !important;
  padding-right: 0 !important;
}

.flex-align-center {
  display: flex;
  align-items: center;
}
/* .nu-select-option .w3-container{
  padding-left: 24px
} */

.modal.fade.in{
  overflow-x: hidden;
  overflow-y: auto;
}


.show-select-btn{
  background: #0079c0;
  color: #fff;
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 4px;
  font-weight: 500;
  opacity: 0.8;
}

.nu-selectionBox img.nu-demo-project-icon{
  width: 48px;
  height: 48px;
  border-radius: 48px;
  padding: 0;
  margin-right: 12px;
  box-sizing: content-box;
}

.nu-own-project-gif{
  border-radius: 0;
}

.show-select-btn:hover, .show-select-btn:focus, .show-select-btn:active{
  opacity: 1;
  color: #fff;
  text-decoration-line: none;
}

.padding-12{
  padding: 12px;
}

.nu-back-btn:hover{
  background: #f8f8f8;
}
.showing-of-steps{
  color: #212121;
  font-weight: 500;
}

.nu-login-container .login-content-holder>div:first-child{
  display: none;
}
.nu-block-padding{
  padding:24px
}

@media (min-width: 480px){

  .nu-image-infographics{
    padding: 5% 5% 5% 5%;
    width: 100%
  }
  .mr-lg{
    margin-right: 12px;
  }
  .ml-lg{
    margin-left: 12px;
  }
  .nu-block-padding{
    padding:48px 10%;
  }
  .nu-select-option {
    margin-bottom: 32px;
  }
}
.justify-content-center{
  display: flex;
  justify-content: center;
}



.nu-step3-options .price-box-divs{
  max-width: 280px;
}


.nu-login-container .login-content-holder{
  padding: 0;
  margin: 0;
}

.nu-login-container .login-content-holder h4{
  display: none;
}
.nu-login-container>div{
  width: 100%;
}

.btn.btn-primary.rmo-submit-button-blue{
  background-color: #0079c0 !important;
  border-color: #0079c0 !important;
  opacity: 0.9;
}


.btn.btn-primary.rmo-submit-button-green{
  background-color: #4CB648 !important;
  border-color: #4CB648 !important;
  opacity: 0.9;
}

.btn.btn-primary.rmo-submit-button-green:hover, .btn.btn-primary.rmo-submit-button-green:focus, .btn.btn-primary.rmo-submit-button-green:active,
.btn.btn-primary.rmo-submit-button-blue:hover, .btn.btn-primary.rmo-submit-button-blue:focus, .btn.btn-primary.rmo-submit-button-blue:active {
  opacity: 1;
}

.nu-all-dash-container{
  flex-basis: 180%;padding: 0 24px;
}



#nu-msform {
  min-width: 250px;
  width: 50%;
	margin: 24px auto;
	text-align: center;
	position: relative;
}

/*nu-progressbar*/
#nu-progressbar {
  padding: 0;
	margin-bottom: 0px;
	overflow: hidden;
	/*CSS counters to number the steps*/
	counter-reset: step;
}
#nu-progressbar li {
	list-style-type: none;
	color: white;
	text-transform: uppercase;
	font-size: 9px;
	width: 33.33%;
	float: left;
	position: relative;
}
#nu-progressbar>li:before {
  content: counter(step);
  counter-increment: step;
  width: 35px;
  line-height: 35px;
  display: block;
  font-size: 0;
  color: #333;
  background: #ccc;
  margin: 0 auto;
  border-radius: 50%;
}
/*nu-progressbar connectors*/
#nu-progressbar>li:after {
  content: '';
  width: 100%;
  height: 3px;
  background: #ccc;
  position: absolute;
  left: -50%;
  top: 16px;
  z-index: -1; /*put it behind the numbers*/
}
#nu-progressbar li:first-child:after {
	/*connector not needed before the first step*/
	content: none; 
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#nu-progressbar li.active:before,  #nu-progressbar li.active:after{
	background: #00dafc;
	color: white;
}

#nu-progressbar li.active{
  cursor: pointer;
}

#nu-progressbar li.active:hover::before{
  background: #4632da
}

.resend-disable{
  opacity: .5 !important;
  cursor: not-allowed !important;
}

.cursor-pointer{
  cursor: pointer;
}

.nu-demo-text{
  position: relative;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 800;    
  font-size: 12px;
  color: #f7e333;
  background: #000;
  width: fit-content;
  margin-bottom: 5px;
}

.nu-show-dashboard{
  font-weight: 300;
  line-height: 32px;
  font-size: 28px
}


.nu-days-free-trial{
  color: white;
  background-color: rgb(63, 158, 215);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-block-start: 0px;
  margin-block-end: 10px;
  border-radius: 3px;
  padding: 4px 15px;
  letter-spacing: 1px;
  width: fit-content;
}

.nu-subscribe-desc{
  margin-block-start: 0px;
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1px;
}

.nu-price-plan-name{
  font-weight: bold;
  margin-bottom: 5px;
  margin-block-start: 0px;
  font-size: 14px;
  line-height: 1.3;
  margin-top: 5px;
  color: rgba(0,0,0,.85);
  padding: 0;
  letter-spacing: 2px;
}

.fh5co-pricing .price-box .classes li.nu-li {
  display: block;
  list-style: none;
  margin: 0;
  font-size: 12px;
  padding: 2px 0;
}

.plan-card__features__list li.nu-li {
  margin-bottom: 2px;
  font-size: 13px;
  color: #000;
  font-weight: 400;
}

.nu-select-demo-image{
  border-radius: 2px 2px 0 0;
  width: 100%;
  /* max-width: 0px; */
}

.nu-project-image-selectionBox{
  max-width: 220px;
  background: #fff;
  border-radius: 2px;
  transition: all .3s;
  list-style: none;
  border: 1px solid #e8e8e8;
  margin-bottom: 24px;
  cursor: pointer;
  transition: box-shadow .25s;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}


.nu-project-image-selectionBox:hover{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.nu-project-image-name-box p{
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

.nu-project-image-name-box{
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 480px){
  .nu-selectionBox, .nu-usecase.nu-selectionBox {
    width: 100%;
  }
  .nu-step2-options .nu-selectionBox p.nu-subheading{
    text-align: left;
  }
  .nu-step2-options .nu-selectionBox p.nu-heading{
    text-align: left;
    line-height: 18px;
    padding-bottom: 12px;
  }

  .mobile-justify-content-center{
    display: flex;
    justify-content: center;
  }
  .nu-all-dash-container{
    padding: 0
  }
  .nu-selectionBox, .nu-step3-options .price-box-divs, .nu-project-image-selectionBox{
    max-width: 100%;
  }

  a.nu-show-dashboard, .nu-select-h3 {
    font-size: 22px;
    line-height: 28px;
  }

}


@media (max-width: 1200px){
  .nu-infographics-pics{
    display: none !important;
  }
  .nu-hideimage{
    display: none !important;
  }
}

.nu-login-container .login-form-box{
  padding: 0;
  margin: 0;
}