.init-monthly-box{
  /* border: 1px solid #ccc; */
  width: 100%;
  margin-bottom: 24px;
}

/* 
.init-monthly-box:hover{
  border-color: #00dafc;
} */

.bWRoFY{
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  color: #00dafc;
  border-color: #00dafc;
}
.bWRoFY::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0px);
    width: 12px;
    height: 12px;
    background: #00dafc;
    border-radius: 50%;
}

.init-fnq .init-fnq-q{
  font-size: 18px;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 5px;
}

.init-plan-list-container ul.plan-card__features__list{
  padding: 0;
}

.init-plan-list-container .plan-card__features__list li{
  font-size: 14px;
  color: #545454;
}

.init-plan-section{
  padding: 24px;border: 1px solid #ccc; height: 100%;background: #fff;
}

.init-billing-cycle-container{
  flex-basis:100%;padding: 24px;border: 1px solid #ccc; margin: 0 12px;background: #fff;
}

.init-planName{
  padding-left: 12px; font-size:18px;margin-bottom:0px
}



.razorpay-payment-button {
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  fill: #ffffff;
  color: #ffffff;
  background-color: #00dafc;
  border-radius: 30px 30px 30px 30px;
  line-height: 12px;
  padding: 15px 30px;
  border: unset;
  text-transform: uppercase;
}

.razorpay-payment-button:hover, .razorpay-payment-button:focus, .razorpay-payment-button:active{
  color: #fff;
  background-color: #4632da
}
.init-main-container{
  padding: 0 10%;
}

@media(max-width: 480px){
  .init-main-container{
    padding: 0 12px;
  }
  .init-mb{
    margin-bottom: 24px;
  }

  .razorpay-payment-button{
    font-size: 12px;
    padding: 10px 20px;
  }
}