/*375px is smallest then 480px 600px 768px 992px 1200px */

.sidebarLogoImage {
  padding: 12px 15px;
}

.onboard_flexcombo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h3 .keywords-title {
  margin: 10% 0 0 10%;
}

.sidebarDaysLeft {
  padding: 5px;
  font-size: 8px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 800;
  white-space: nowrap;
}

.project::placeholder {
  margin-top: 10px;
  font-weight: 400;
}

.sidebarProjectName {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  margin-left: 5px;
  margin-right: 5px;
}

.sidebarProjectNameWithDays {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  margin-left: 5px;
  margin-right: 5px;
}

.sidebarProjectNameWithoutDays {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 175px;
  margin-left: 5px;
  margin-right: 5px;
}

.select2-container {
  width: 100% !important;
  border: none !important;
  color: #545454 !important;
  height: 40px !important;
}

.select2-selection--multiple {
  height: 40px !important;
  border: none !important;
  width: 100% !important;
  color: #545454 !important;
}

.bootstrap-tagsinput {
  box-shadow: none;
  padding: 5px;
  color: #545454;
  vertical-align: middle;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  line-height: 0;
  margin-top: 24px;
  cursor: text;
  display: flex;
  overflow: hidden !important;
}

.bootstrap-tagsinput>input {
  max-width: 100%;
  height: 29px;
}

.label-info {
  background-color: #337ab7;
  font-size: 100%;
  padding: 0.5em 0.6em 0.3em;
}

#content {
  width: calc(100% - 256px);
  min-height: 100%;
  transition: all 0.3s;
  top: 0;
  right: 0;
  float: right;
  /* margin-bottom: 3%; */
  /* margin-bottom: 105px; */
}

#content.active {
  /* width: 100%; */
  width: calc(100% - 80px);
}


/* @media (min-width: 320px) and (max-width:1024px) {
    #sidebar {
        width: 50px;
        left: 0;
        float: left;
        z-index: 999;
        background: #1f2322;
        color: #fff;
        transition: all .3s;
        min-height: 100vh;
    }
    #sidebar.active {
        margin-left: -50px;
    }
    #content.active {
        width: 100%;
    }
    #content {
        width: calc(100% - 50px);
        min-height: 100%;
        transition: all .3s;
        top: 0;
        right: 0;
        float: right;
    }
    .panel_suboption a {
        font-size: 20px !important;
    }
    .panel_suboption {
        padding: 10px 5px;
        font-weight: 500;
        font-size: 14px;
        color: #d8d8d8;
        text-align: left;
    }
} */

@media (max-width: 500px) and (min-width: 300px) {
  .label-info {
    padding: 7px 10px;
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  float: left !important;
  margin-left: 2% !important;
  width: 95%;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #337ab7;
  border: none !important;
  color: #fff;
  margin-bottom: 5px;
}

i.onboarding-icon {
  font-size: 40px !important;
}

@media (min-width:300px) and (max-width: 767px) {
  .group {
    margin-top: 10px;
  }
  element.style {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 1px 2px 4px rgba(0, 0, 0, .5); */
  }
  .information {
    margin: 0px 0px;
  }
  div i.onboarding-icon {
    font-size: 40px !important;
  }
  .form-control {
    margin-left: 0px;
    margin-right: 0px;
  }
  .stepwizard-row .stepwizard-step {
    position: relative;
    margin: 0 5%;
  }
  h3.keywords-title {
    font-size: 20px;
    padding-bottom: 20px;
    padding-top: 12px;
  }
  .group lable {
    font-size: 12px;
  }
  .autocomplete input[type="text"] {
    width: 90%;
    margin-right: 20%;
  }
  .map {
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .group .autocomplete label {
    color: #000;
    position: absolute;
    left: 0%;
    top: 15%;
    border: none;
    margin-top: 10px;
  }
  .information div p {
    font-size: 12px;
  }
  .review {
    font-size: 16px;
  }
  .select2-container {
    width: 100% !important;
    border: none !important;
    color: #545454 !important;
    height: 40px !important;
  }
  .select2-selection--multiple {
    height: 40px !important;
    border: none !important;
    width: 100% !important;
    color: #545454 !important;
  }
  .bootstrap-tagsinput {
    border: .5px solid #0079c0;
    border-radius: 5px;
    box-shadow: none;
    padding: 5px;
    color: #545454;
    vertical-align: middle;
    /* border-radius: 0; */
    height: 40px;
    width: 100%;
    line-height: 0;
    cursor: text;
    display: flex;
    overflow: hidden !important;
  }
  .label-info {
    background-color: #337ab7;
    font-size: 100%;
  }
  button.first {
    margin-top: 10%
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    float: left !important;
    margin-left: 2% !important;
    width: 95%;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #337ab7;
    border: none !important;
    color: #fff;
    margin-bottom: 5px;
  }
  .group .autocomplete label {
    color: #000;
    position: absolute;
    pointer-events: none;
    left: 8%;
    top: 0%;
  }
}

@media (min-width: 900px) and (max-width: 1365px) {
  .group .autocomplete label {
    color: #000;
    position: absolute;
    pointer-events: none;
    left: 8%;
    top: 30%;
    border: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media(max-width:480px) {
  .stepwizard {
    margin: 24px;
    background: #fff;
    padding: 48px 0;
  }
  .setup-content {
    margin: 24px 0;
  }
  .onboard_chevron-right {
    position: relative;
    top: -35px;
    color: #0079c0;
  }
  .stepwizard-step .btn-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding: 3px;
    font-size: 14px;
    line-height: 1.428571429;
  }
}

.information div p {
  font-size: 16px;
}

#step-1 {
  display: block;
}

#step-2 {
  display: none;
}

#step-3 {
  display: none;
}

.btn-circle {
  border-color: none;
  border-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

button {
  border: 0;
  border-radius: 0;
  cursor: pointer;
  color: #FFF;
  transition: 0.3s ease all;
}

.group {
  position: relative;
  margin-bottom: 30px;
}

body:hover,
body :focus {
  outline: 0;
  outline-offset: 0;
}

.group input {
  display: block;
  margin: 10px auto;
  background-color: #fff;
  padding: 10px 5px;
  width: 80%;
  border: none;
  border-radius: 0;
  color: #545454;
  font-size: 1em;
  font-weight: 600;
}

.group input:focus,
.group input:not(:placeholder-shown) {
  border: none;
}

.group input::-webkit-input-placeholder {
  color: transparent;
}

.group input:-moz-placeholder {
  color: transparent;
}

.group input::-moz-placeholder {
  color: transparent;
}

.group input:-ms-input-placeholder {
  color: transparent;
}

.group label {
  color: #000;
  position: absolute;
  pointer-events: none;
  left: 12%;
  top: 20%;
  transition: 0.3s ease all;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.group input:focus-within~label,
.group input:not(:placeholder-shown)~label {
  top: -20px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  font-size: 14px;
}

.group .bar {
  position: relative;
  display: block;
  width: 100%;
}

/* .group .bar:before, .group .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 10px;
  position: absolute;
  background: #FFF;
  transition: 0.3s ease all;
} */

.group .bar:before {
  left: 50%;
}

.group .bar:after {
  right: 50%;
}

.group input:focus~.bar:before,
.group input:focus~.bar:after {
  width: 50%;
}

.group input:not(:placeholder-shown)~.bar:before,
.group input:not(:placeholder-shown)~.bar:after {
  width: 50%;
}

.information {
  background-color: #fff;
  padding: 3% 0;
  margin: 0px 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-top: 2px solid #0079c0;
}

/* #keyFinal {
  text-shadow: 0px 0px 200px;
} */

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: block;
}

.autocomplete input {
  border: 1px solid transparent;
  padding: 10px;
  font-size: 14px;
  color: #545454;
}

.autocomplete input[type=text] {
  /* background-color: #fff; */
  width: 100%;
  color: #545454;
  border: .5px solid #0079c0;
  border-radius: 4px;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 1001;
  color: #fff;
  /*position the autocomplete items to be the same width as the container:*/
  left: 0;
  right: 0;
  margin: 0 3%;
  max-height: 150px;
  overflow-y: scroll;
}

.baseline>.autocomplete>.autocomplete-items {
  border: none;
  margin: 0 5%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.group .autocomplete label {
  color: #000;
  position: absolute;
  pointer-events: none;
  left: 8%;
  top: 30%;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.autocomplete-items div {
  padding: 5px 2px;
  cursor: pointer;
  background-color: #0079c0;
  border-bottom: 0.05px solid #d4d4d4;
  text-align: center;
}

.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #3393cc;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

/* @media (min-width: 961px) {
  .onboard-div {
    margin-top: -80px;
  }
} */

.sm-sidebarLogoImage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  border-bottom: 1px solid white;
}

.onboard-div {
  margin-left: -3.2% !important;
  margin-right: -3.2% !important;
  /* margin: 0 !important; */
  height: 100%;
  display: flex;
}

.setup-content {
  margin: 2% 10%;
}

.onboard-div>.container {
  padding-left: 0;
  padding-right: 0;
}

.onboard>#content>.navbar-default>.navbar-header>.navbar-btn {
  box-shadow: none;
  outline: 0 !important;
  border: none;
  margin: 20px 0;
  color: #fff;
  font-size: 14px;
  background-color: #1f2322;
}

.stepwizard {
  margin: 24px;
  background: white;
  padding: 48px 0;
}

.stepwizard p {
  margin-top: 10px;
}

.stepwizard-row {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.stepwizard-row:before {
  top: 15px;
  bottom: 0;
  left: 0;
  position: none;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.stepwizard-step {
  display: inline-block;
  text-align: center;
  position: relative;
  margin: 0 10%;
}

.stepwizard-step .btn.disabled {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  border-color: #4c91cc;
  border-width: 2px;
}

button,
html .stepwizard-step [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: none !important;
}

.stepwizard-step .btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 15px 0;
  font-size: 16px;
  line-height: 1.428571429;
  /* border-radius: 15px; */
}

.stepwizard-step>.btn.btn-primary {
  color: #fff;
  background-color: #0079c0;
  /* border: none!important; */
  border: 0px solid transparent !important;
}

button.first {
  margin-top: 5%;
}

#confirmationbackend {
  margin-top: 5%;
  background-color: #0079c0;
}

.onboarding-icon {
  color: black;
  font-size: 50px;
  margin: 0 0 0 -30px
}

.onboard_chevron-right {
  position: relative;
  top: -40px;
  color: #0079c0;
}

@media(max-width:768px) {
  .stepwizard {
    margin: 24px;
    background: #fff;
    padding: 48px 0;
  }
  .onboard_smview_mentionContainer {
    flex-grow: 1;
  }
}

@media(max-width:480px) {
  .stepwizard {
    margin: 0;
    background: #fff;
    padding: 24px 0;
  }
  .setup-content {
    margin: 24px 0;
  }
  .information {
    margin: 0;
  }
  #confirmationbackend {
    margin-top: 12%;
  }
}

.bootstrap-tagsinput input::placeholder {
  width: 75%
}

.bootstrap-tagsinput input,
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
  width:100%
}

/* ========================for icon on sidebar================================= */

/* Toggling the sidebar header content, hide the big heading [h3] and showing the small heading [strong] and vice versa*/

#sidebar ul li p.sidebar_strong {
  display: none;
}

#sidebar.active ul li p.sidebarProject {
  display: none;
}

#sidebar.active ul.list-unstyled.components li p.sidebar_strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  margin-bottom: 5px;
  /* color: hsla(0, 0%, 100%, .65); */
  font-size: 16px;
}

@media (max-width: 768px) {
  input[type=text].mobile-inputOnProjectSetting {
    width: 100% ! important
  }
  #sidebar.active {
    min-width: 50px;
    max-width: 50px;
    text-align: center;
  }
  #sidebar {
    width: 50px;
    left: 0;
    float: left;
    z-index: 999;
    background: #1f2322;
    color: #fff;
    min-height: 100vh;
  }
  #sidebar .sidebarProjectName {
    width: 65%;
    margin-left: 0;
  }
  #sidebar {
    margin-left: -50px;
  }
  #content {
    width: calc(100% - 50px);
    min-height: 100%;
    top: 0;
    right: 0;
    float: right;
  }
  #content.active {
    width: calc(100% - 50px);
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebar ul li p.sidebar_strong {
    display: block;
  }
  #sidebar.active ul li p.sidebarProject {
    display: none;
  }
  #sidebar.active ul li p.sidebar_strong {
    display: block;
  }
  #sidebar.active ul li a i {
    margin-right: 0;
    font-size: 1.8em;
    margin-bottom: 5px;
    width: 100%;
  }
}

/* ***************************************unpaid****************************** */

p.unpaid-headline {
  text-transform: uppercase;
  font-size: 12px;
  color: #e3e3e3;
  font-weight: bold;
  padding-bottom: 0px;
}

#unpaid_panel_projects_list {
  margin: 10px;
  text-align: center;
}

#sidebar .unpaid-process ul {
  text-align: left;
  color: #848484;
  font-size: 12px;
}

#sidebar .unpaid-process ul li:hover {
  color: #fff !important;
  background: none;
}

#sidebar .unpaid-process ul li a:hover {
  color: #fff !important;
  background: none;
}

#sidebar .unpaid-process ul li {
  padding: 10px 24px;
  font-size: 1.1em;
  text-transform: capitalize;
}

#sidebar .unpaid-process ul li a {
  padding: 0;
  color: #848484;
}

.unpaid-process ul li i {
  color: #0FB36C
}

.unpaid-content-section {
  padding: 24px;
}

.unpaid_container {
  padding: 48px;
  margin: 0 15%;
  width: 70%;
  text-align: center;
  background: white;
  border-radius: 10px;
}

.unpaid_container>h2 {
  margin-bottom: 15px;
  font-size: 32px !important;
  font-weight: 300 !important;
  line-height: 36px !important;
  color: #333333 !important;
  padding-bottom: 5px;
}

.unpaid_container>h4 {
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  color: #0FB36C !important;
}

.init {
  color: #fff
}

@media( max-width: 480px) {
  .unpaid-content-section {
    padding: 12px;
  }
  .unpaid_container {
    padding: 12px;
    margin: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 10px;
  }
}