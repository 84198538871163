/* ********************************page 1************************** */

.pdfMentionSpan {
    margin-left: 0;
    letter-spacing: .5px;
    min-height: 45px;
    font-size: 18px;
    line-height: 1em;
    color: #000;
}

.pdf_measure_div {
    width: 100%;
}
.pdfKolPostNotPresent{
    height: 100%;
    display: flex;
    border: 1px solid rgb(225,232,237);
    justify-content: center;
    align-items: center;
}
.pdf_mentionwords {
    font-size: 14px !important;
    color: #000 !important;
    letter-spacing: 0.8px;
    font-weight: normal !important;
}

.pdf_brand_name {
    margin-top: 12px;
}

.pdf_measure_div span {
    text-align: center;
    color: #000;
}

.pdf_measure {
    margin-left: 47%;
}

#spectrum_jobs {
    background: linear-gradient(to right, red, #ff0, orange, green);
    padding: 20px;
    margin-top: 7px;
    height: 20px;
    width: 100%;
    float: left;
    border-radius: 4px;
    border: 4px solid #a9a9a9;
    box-shadow: inset 0 0 10px #f9f9f9;
    position: relative;
}

#line_white {
    padding: 5px;
    border: 2px solid #a9a9a9;
    width: 1px;
    height: 60px;
    position: relative;
    margin-top: 0;
    border-radius: 4px;
    box-shadow: inset 0 0 4px #a9a9a9;
    transition-duration: .5s;
    background-color: #f2f2f2;
    animation: moveline 2s;
    z-index: 10;
}

@keyframes moveline {
    0% {
        margin-left: 25%;
    }
}

/* .pdf-home {
    background-image: url("images/report/HR.jpg");
} */

.pdf_homeimage_container {
    position: relative;
    width: 100% !important;
    height: 75vh !important
}

.pdf_homeimage_container>img:first-child {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 100% !important;
    height: 75vh !important;
    top: 0;
}

.pdf_homeimage_container>img {
    position: absolute;
    z-index: 3;
    left: 0;
    width: 100% !important; 
    height: 75vh !important;
    top: 0
}

/* 
.pdf_hr-image {
    position: absolute;
    transform: rotate(30deg);
    top: 345px;
    left: -90px;
    width: 113%;
    border-top: 10px solid #fff;
} */

/* @media only screen and (min-width: 1271px) {
    .pdf_hr-image2 {
    position: absolute;
    transform: rotate(-21deg);
    top: 172px;
    right: -77px;
    height: 200px;
    width: 59%;
    border-top: 10px solid #fff;
    }
    .pdf_hr-image3 {
    position: absolute;
    transform: rotate(-40deg);
    top: 480px;
    left: 204px;
    height: 259px;
    width: 42%;
    border-top: 10px solid #fff;
    }
}
@media only screen and (max-width: 1271px) { */

/* .pdf_hr-image2 {
    position: absolute;
    transform: rotate(-21deg);
    top: 210px;
    right: -100px;
    height: 200px;
    width: 59%;
    border-top: 10px solid #fff;
}
.pdf_hr-image3 {
    position: absolute;
    border-top: 10px solid #fff;
    transform: rotate(-40deg);
     top: 482px;
    left: 220px;
    height: 259px;
    width: 42%;
    border-top: 10px solid #fff;
} */

/* } */

/* .pdf_hr-image4 {
    position: absolute;
    border-top: 10px solid #fff;
    width: 50%;
    transform: rotate(79deg);
    top: 645px;
    left: 347px;
   
    
} */

.col-xs-6 .table-striped>tbody>tr:nth-child(odd)>td,
.col-xs-6 .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #eee;
    padding: 9px 8px;
}

.col-xs-6 .table-striped>tbody>tr:nth-child(even)>td,
.col-xs-6 .table-striped>tbody>tr:nth-child(even)>th {
    background-color: #fff;
    padding: 9px 8px;
}

.font-size50{
    font-size: 50px !important;
}
.font-size75{
    font-size: 75px
}
.padding-56{
    padding: 56px
}
.pdf-brand-right-side {
    flex-basis: 100%;
    padding: 100px !important;
    justify-content: space-between;
    height: 100vh
}
.pdfNewView{
    width:100%;
    height: 100vh; 
    line-height: 1.4
}
.pdfNewView h2{
    font-size: 100px;

}
.pdficon{
    height: 10vh; 
    width: 10vh;
}
.pdfNewView .sum_mentionsdiv{
    width: 470px !important;
    min-height: 400px;
    min-width: 550px;
}
.pdfNewView .sum_mentionsdiv p:first-child{
    font-size: 50px;
    margin-bottom: 6px;
}

.color-heading{
    font-size: 75px !important;
    font-weight: 600;
    margin-bottom: 50px !important;
}
.font-size40{
    font-size: 40px
}
.pdfdate{
    font-size: 35px
}
.pdflogo{
    height: 210px; 
    max-width: 500px;
}
.pdffrontPageheading{
    padding: 100px 80px !important;
}
.summary1graph{
    height: 500px;
    width: 1500px;
}
.pdftext{
    font-size: 50px
}
.pdfMentionByType{
    width: 700px
}
.pdfbigtext{
    font-size: 75px
}
.pdf2graphonpage{
    height:750px;
    width: 2850px;
}
.pdffeaturegraph{
    height: 1000px;
    width: 2200px;
}
.pdfwordcloud{
    width: 2200px;
}
.engagementGraph{
    height: 700px;
    width: 1100
}
.defaultombed{
    padding: 56px
}
.defaultombed img{
    height: 600px;
    width: 600px
}
.defaultombed h4{
    font-size: 75px;
    margin: 56px
}
.pdfinfluence_profile{
    border-radius: 50%;
    height: 175px;
    width: auto;
    border: 2px solid #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1), 0 0 10px 0 rgba(0,0,0,.19);
}
.pdfglossaryBox{
    margin: 56px;
    padding: 56px
}
.pdfmention-information{
    float: left;
    width: 88%;
    font-size: 12px;
    margin: 8px 0 0;
}
.pdfmediaheading{
    width: 100px;
    margin-bottom: 10px
  }
  .pdfsentiment{
    height: 35px
  }
  .linkfavicon{
    height: 35px
  }
  .pdfmediadesc{
    margin: 18px 0
  }
.col-xs-12 .table-striped>tbody>tr:nth-child(even)>td,
.col-xs-12 .table-striped>tbody>tr:nth-child(even)>th {
    background-color: #fff;
}

.col-xs-12 .table-striped>tbody>tr:nth-child(odd)>td,
.col-xs-12 .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #eee;
}

/* 
.pdf_hr-image4 {
    transform: rotate(86deg);
    top: 633px;
    left: 372px;
    position: absolute;
    border-top: 10px solid #fff;
    width: 50%;
}

.pdf_hr-image3 {
    transform: rotate(-38deg);
    top: 487px;
    left: 267px;
    height: 259px;
    width: 46%;
    position: absolute;
    border-top: 10px solid #fff;
}

.pdf_hr-image2 {
    transform: rotate(-32deg);
    top: 183px;
    right: -190px;
    height: 200px;
    width: 59%;
    position: absolute;
    border-top: 10px solid #fff;
}

.pdf_hr-image {
    transform: rotate(30deg);
    top: 308px;
    left: -116px;
    width: 119%;
    position: absolute;
    border-top: 10px solid #fff;
}

hr.pdf_hr_line {
    position: absolute;
    border-top: 10px solid #fff;
    width: 100%;
    bottom: -20px;
} */

#pdfpageThird,
#pdfpageSecond,
#pdfpageSixth,
#pdfpageForth,
#pdfpageFifth {
    /* background: #fff; */
    padding: 12px 24px;
}

/* 
.triangle-up {
    position: absolute;
    z-index: 100;
    height: 35%;
    width: 50%;
    left: -15%;
    bottom: 0;
    border-left: 420px solid transparent;
    border-right: 525px solid transparent;
    border-bottom: 330px solid #fff;
}

.triangle-up1 {
    position: absolute;
    z-index: 100;
    width: 22px;
    right: 0;
    bottom: 0;
    border-left: 597px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 265px solid #fff;
} */

/* ********************************page 2************************** */

.pdf_background_image {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.pdf_before_count {
    padding: 24px;
}

.padding-24 {
    padding: 24px;
}
.padding-12{
    padding: 12px
}
.margin-12{
    margin: 12px
}
.padding-48 {
    padding: 48px;
}

.pdf_countmention td {
    width: 100%;
    font-size: 15px;
}

.pdf_mentionTableheading {
    background: white;
    padding: 10px 10px;
    margin-bottom: 0px;
    color: #000;
}

.pdf_td_color {
    color: #000 !important;
}

/* ********************************page 4************************** */

.pdf_posts_div {
    display: flex;
    margin: 0px 12px;
}

.pdf_div_box {
    width: 50px;
    flex-direction: column;
    height: 50px;
    flex-shrink: 0;
    background: url(/images/default_profile_normal.png);
}

.pdf_div_image {
    height: 25px;
    width: 25px;
    margin-top: -10px;
    position: absolute;
    margin-left: -10px;
}

.pdf_post_text_div {
    margin: 0 0 0 15px;
}

.pdf_post_text {
    font-size: 18px;
    margin-bottom: 0;
    overflow: hidden;
    height: 50px;
    text-align: left;
    color: #000;
}

.pdf_positive_dot {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin: 24px;
}

.pdf_table_title>th {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #000 !important;
}

/* ===================================new pdf view css================================================= */

.pdf-top-mentions-boxes .sum_mentionsdiv {
    flex-grow: unset;
    flex-basis: unset;
    width: 180px;
    border: 1px solid darkgrey;
}

.pdfinfluence-icon {
      width: 50px;
      height: 50px;
      border-radius: 0px;
      top: 60px;
      margin-left: -65px;
      position: relative;
      margin-top: 20px;
      object-fit: cover;
      left: 10px;
}
#pdfresizeImage {
    height: auto;
    width: auto;
    max-height: 270px;
    max-width: 270px
} 

.pdf-para {
    font-size: 18px !important;
    margin-bottom: 10px !important;
}

.selectedSentiment .NEUTRAL,
.selectedSentiment .NOTFOUND {
    background-color: #00ccf7;
}

.selectedSentiment .POSITIVE {
    background-color: #c8e400;
}

.selectedSentiment .NEGATIVE {
    background-color: #f92000;
}

.font-size16 {
    font-size: 16px !important;
}

.font-size18{
    font-size: 18px !important;
}
.font-weight600{
    font-weight: 600;
}
.font-size24{
    font-size: 24px
}
.flexdirectionRow .table-striped>tbody>tr:nth-child(odd)>td,
.flexdirectionRow .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: unset;
}

.pdf-post-image {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    /* max-width: 175px; */
}

.pdfcard{
    display: block;
    margin: 0 30px 60px;
    background-color: #fff;
    border: 1px solid darkgrey;
    padding: 50px;
    width: 485px;
    border-radius: 10px;
}

.pdf-star-for-more-then-avg-score {
    width: 24px;
    position: absolute;
    z-index: 10;
    left: 0;
}

.pdfPage {
    padding: 20px 0;
}

.pdfPage body,
.pdfPage ol,
.pdfPage p,
.pdfPage ul,
.pdfPage span {
    color: #3f3f3f;
}

.profilePdfPage body,
.profilePdfPage ol,
.profilePdfPage p,
.profilePdfPage ul,
.profilePdfPage span {
    color: #3f3f3f;
}

.infl-pdf-top-performing-post {
    padding: 12px;
    border: 1px solid #0079c0;
    text-align: center;
    margin-top: 24px;
    font-size: 16px;
}

.infl-pdf-show-counts {
    margin-bottom: 0;
    font-size: 24px;
    color: #000 !important;
}

.infl-pdf-main-title {
    font-size: 24px;
    font-weight: 600;
}

.score-in-circle-title {
    font-size: 24px;
    font-weight: 600;
}

.sum_mention-title>h5 {
    font-size: 20px;
    font-weight: 800;
}

.text-positive {
    color: #1eb359 !important
}

.text-negative {
    color: #f92000 !important
}

.border-right {
    border-right: 2px solid #ddd
}

/* .ant-card-body {
    padding: 12px;
    zoom: 1;
}

.ant-card-meta-description {
    height: 70px;
    overflow: hidden;
    line-height: 22px;
} */

.absolute-brand-image {
    width: 70px;
    position: absolute;
    margin-left: 265px;
    margin-top: -35px;
}

#pageNumbers {
    counter-reset: currentPage;
  }
  #pageNumbers #pageId:before { 
    counter-increment: currentPage; 
    content: counter(currentPage); 
  }

.pdf-profile-hashtag li {
    font-size: 24px
}

.pdf-profile-hashtag-container .pdf-profile-hashtag-title {
    background: #65a9f2;
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.column1nbsGlossary:after{
    top: 8px;
    right: 0;
    height: calc(100% - 48px);
    width: 1px;
    background-color: #e8e8e8;
    content: "";
}
.pdf-profile-hashtag-title h4 {
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
}

.pdf-profile-hashtag-container .pdf-profile-hashtag-desc {
    background: #d1def9
}

.pdf-profile-left-side {
    flex-basis: 30%;
    min-width: 350px;
    max-width: 350px;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.pdf-brand-left-side{
    flex-basis: 30%;
    padding: 100px  !important;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    height: 100vh
}
.pdftextdesc{
    padding: 0 75px;
}

.pdf-profile-right-side {
    flex-basis: 100%;
    margin-left: 24px
}

.ant-list-item {
    display: block;
    max-width: 233px;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
}

.ant-card-meta-title {
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 4px;
}
.pdfKolBio{
    margin-bottom: 0px;
    word-break: break-word;
    max-height: 210px;
    overflow: hidden;
}

.ant-card-meta-title a {
    display: inline-block;
    max-width: 100%;
    color: rgba(0, 0, 0, .85);
    font-size: 18px;
}

.ant-card-meta-description {
    height: 44px;
    overflow: hidden;
    line-height: 22px;
}

.ant-typography-ellipsis-multiple-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

div.ant-typography {
    margin-bottom: 1em;
}

.antd-pro-pages-list-search-projects-style-cardItemContent {
    display: flex;
    height: 20px;
    margin-top: 16px;
    margin-bottom: -4px;
    line-height: 20px;
}

.antd-pro-pages-list-search-projects-style-cardItemContent>span {
    flex: 1 1;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
}

.ant-card-body {
    padding: 12px;
    zoom: 1;
    max-height: 144px;
}

.ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    transition: all .3s;
}

.ant-card-bordered {
    border: 1px solid #e8e8e8;
}

.profile-pdf-pages {
    height: 900px
}

.profile-all-counts{
    text-transform: uppercase;
    font-size: 16px;
}

.sum_mention-title .profile-all-graph-span{
    font-size: 16px;
    line-height: 22px;
}
.displayRmoLogo{
    background-image: url('/images/logos/rmo-logo-new.png');
    background-position: right 12px top 6px; 
    background-repeat: no-repeat;
    background-size: 100px;
}
@media (max-width: 1920px) and (max-height: 1080px){
    .pdf-brand-right-side {
        flex-basis: 100%;
        padding: 24px !important;
        justify-content: space-between;
        height: 100vh
    }
    p.pdfcloudParablock {
        display: inline-block;
        margin: 0 !important;
        padding: 20px;
        letter-spacing: 0;
        line-height: 0;
        word-break: break-word;
    }
    .pdfNewView .sum_mentionsdiv p:first-child{
        font-size: 24px;
        margin-bottom: 6px;
    }
    .pdfNewView h2{
        font-size: 36px;
    }
    .pdfNewView .sum_mentionsdiv{
        width: 170px !important;
        min-height: 130px;
        min-width: 170px;
        max-height: 130px
    }
	.pdfNewView .sum_mentionsdiv p:first-child{
        font-size: 18px;		    
        margin-bottom: 6px;		    
    }		
    .color-heading{
        font-size: 24px !important;
        font-weight: 600;
        margin-bottom: 24px !important; 
    }
    .pdfdate{
        font-size: 14px
    }
    .pdf-brand-left-side{
        flex-basis: 30%;
        padding: 24px  !important;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        height: 100vh;
        min-height: 728px
    }
    .pdflogo{
        max-height: 70px;
        max-width: 180px;
    }
    .pdffrontPageheading{
        padding: 0 48px !important;
    }
    .summary1graph{
        height: 160px;
        width: 500px;
    }
    .pdftext{
        font-size: 18px
    }
    .pdfMentionByType{
        width: 250px
    }
    .orm{
        border-radius: 36px 0 0 !important;
    }
    .pdfbigtext{
        font-size: 24px
    }
    .pdf2graphonpage{
        height: 250px; 
        width: 885px ;
    }
    .pdffeaturegraph{
        width: 100%;
        height: 300px;
    }
    .pdfwordcloud{
        width: 850px;
    }
    .engagementGraph{
        height: 250px;
        width: 400px
    }
    .defaultombed img{
        height: 200px;
        width: 200px
    }
    .defaultombed{
        padding: 24px
    }
    .defaultombed h4{
        font-size: 24px;
        margin: 12px 0 0 0
    }
    .pdfcard{
        display: block;
        margin: 0 10px 20px 10px;
        background-color: #fff;
        border: 1px solid darkgrey;
        padding-top: 40px;
        width: 210px;
        padding: 8px;
        border-radius: 10px;
    }
    .pdfinfluence_profile{
        border-radius: 50%;
        height: 60px;
        width: 60px;
        border: 2px solid #fff;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.1), 0 0 10px 0 rgba(0,0,0,.19);
    }
    .pdfinfluence-icon {
        width: 25px;
        height: 25px;
        border-radius: 0px;
        top: 10px;
        margin-left: -25px;
        position: relative;
        margin-top: 20px;
        object-fit: cover;
        left: 5px;
  }
  .pdf-img-card{
    width: 100%;
    height: 70px;
    padding: 5px 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    overflow: hidden;
  }
  .pdf-card-content{
    padding: 5px 10px;
    text-align: left;
    height: 205px;
  }
  .pdfglossaryBox{
    margin: 24px;
    padding: 24px
  }
  .pdfmentionbox{
      padding: 8px 12px !important;
      margin-bottom: 12px !important
  }
  #pdfresizeImage {
    height: auto;
    width: auto;
    max-height: 90px;
    max-width: 100px
} 
.pdftextdesc{
    padding: 0 24px;
}
.pdfmention-information{
    float: left;
    width: 88%;
    font-size: 12px;
    margin:  0;
}
.pdfmediaheading{
    width: 36px;
    margin-bottom: 3px
  }
  .pdfsentiment{
    height: 10px
  }
  .linkfavicon{
    height: 15px
  }
  .pdfmediadesc{
    margin: 8px 0
  }
  .pdfKolOembedHeight{
    max-height: 590px;
    overflow: hidden;
  }
  .pdfTiktokKolOembedHeight{
    max-height: 450px;
    overflow: hidden;
  }
  .pdfinstaheight{
    max-height: 410px;
    overflow: hidden;
  }
  .pdfFbHeight{
      max-height: 410px;
      overflow: hidden;
  }
  .pdfprofile_mainSection{
    padding: 12px;
    margin-top: 24px;
}
.pdf-pf-ms {
    display: flex;
    justify-content: space-between;
    background: unset;
    flex-direction: column;
    padding: 0;
}
.pdf-infl_influencerName{
    margin: 0 0 12px;
    display: flex;
    flex-direction: row;
}
.pdf-infl_influencerName img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 10px;
}
.pdf-pf-width {
    width: 100%;
}
.pdf-kol-card .detailDataItem {
    width: 100%;
    padding: 6px 0;
}
.padding0{
    padding: 0;
}
.pdf-kol-card .color-heading.font-size14{
font-size: 21px !important;
margin: 0 !important;
}
.pdf-kol-card span ,
.pdf-kol-card p{
    color:#808080
}
}

.text-overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
}
.height100{
    height:100%
}
.postImage{
    height: auto;
    width:auto;
    max-width:300px;
    max-height:250px
}
.pdfBrandSummaryOembed .postImage{
    max-height: 250px !important;
}
.oembedBox{
    margin: 0 auto;
    max-width:300px;
    border: 1px solid rgb(225,232,237);
    border-radius: 5px;
}

.pdfBrandSummaryOembed .postText{
    max-height:88px !important
}
.pdfKolOembed .postImage{
    max-height: 325px;
}

.pdfKolOembed .postText{
    max-height:185px !important
}