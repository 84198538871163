.panal-fa-icons{
  font-size: 24px;
  margin-bottom: 10px;
}

.panal-source-numbers{
  font-size: 32px;
}

.panal-source-text{
  font-size: 12px;
}

.panal-box-count{
  display: flex;
  justify-content: center;
  padding: 24px 0 24px!important;
  height: unset !important;
}

.panal-box-title {
  justify-content: space-evenly !important;
}



.summarypostnav-tabContent .panal-mention-tab {
  height: unset !important;
}

.panal-mention-view .header-enterprise .filteravatar>.mention-information{
  width: 100%;;
}

.panal-mention-view .header-enterprise .filteravatar>.author{
  width: 100%;
}

.panal-mdp-section section.mention-box1{
  height: 100%;
  padding-top: 0;
}
.panal-mdp-section ul{
  column-count: 2;
  list-style: none;
  column-gap: 6px;
  padding: 0;
  /* height: fit-content; */
  margin: 0;
}
.panal-mention-view section.mention-box .mention-information{
  width: 100%;
}

.panal-mention-view section.mention-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mention-panal-loader{
  padding-bottom: 24px;
}


.panal-source-counts span{
  color: rgba(0,0,0,.85);
  
}

.panal-source-counts span.panal-source-text{
  color:  #545454;
}


.panal-source-counts{
  padding: 24px;
  justify-content: space-between;
  background: white;
  margin-bottom: 6px;
}

.panal-source-counts:last-child{
  margin-bottom: 0px;
}

.panal-source-counts .panal-source-numbers{
  line-height: initial;
}



.panal-mdp-section .masonry-item {
  display: inline-block;
  background: #fff;
  width: 100%;
  /* -webkit-transition: 1s ease all; */
  box-sizing: border-box;
  /* -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: box-shadow .25s;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0; */
}

.panal-mdp-section .masonry {
  column-gap: 6px;
  font-size: .85em;
  column-count: 2;
}

.panal-mdp-section .mentionBox{
  margin-bottom: 0;
  padding: 12px;
  border: unset;
}

.panal-mdp-section .header-enterprise .filteravatar>.author{
  padding-left: 0;
}


.panel_big_holder .social-kpi-fields .social-kpi-show-count{
  font-size: 28px;
}

/* .panal-mdp-section .masonry-item:nth-child(2) {
  transition: .3s;
  animation-delay: 1s;
}
.panal-mdp-section .masonry-item:nth-child(3) {
  transition: .3s;
  animation-delay: 2s;
}
.panal-mdp-section .masonry-item:nth-child(4) {
  transition: .3s;
  animation-delay: 2s;
}
.panal-mdp-section .masonry-item:nth-child(5) {
  animation-delay: 3s;
}
.panal-mdp-section .masonry-item:nth-child(6) {
  animation-delay: 3s;
}
.panal-mdp-section .masonry-item:nth-child(7) {
  animation-delay: 4s;
}
.panal-mdp-section .masonry-item:nth-child(8) {
  animation-delay: 4s;
}
.panal-mdp-section .masonry-item:nth-child(9) {
  animation-delay: 5s;
}
.panal-mdp-section .masonry-item:nth-child(10) {
  animation-delay: 5s;
}
.panal-mdp-section .masonry-item:nth-child(11) {
  animation-delay: 6s;
}
.panal-mdp-section .masonry-item:nth-child(12) {
  animation-delay: 6s;
} */


.panal-top-images-div img{
  opacity: .7;
}

.panalIconContainer{
  min-width: 55px;
}
.panalIconContainer img{
  height: 40px;
}

.panal-kpi-view-container{
  max-height: unset;
  padding: 0;
  margin-bottom: 24px;
  padding-right: 24px;
}

.panal-kpi-view-container:last-child{
  padding-right: 0;
}
.panel_kpi_source_counts{
  flex-basis: 40%;
  margin-right: 24px;

}
.panel-social-kpi-view-container{
  margin-top: 6px;
}
@media(max-width:480px){
  .panal-mdp-section ul{
    column-count: 1;
    column-gap: 0;
  }
  .panal-kpi-view-container{
    max-height: unset;
    padding:0 15px;
    margin-bottom: 12px;
  }
  .panal-kpi-view-container:last-child{
    padding:0 15px;
    margin-bottom: 24px;
  }
  .panel_kpi_source_counts{
    margin-left:15px;
    margin-right:15px;
  }
  .panal-source-counts{
    margin-bottom: 12px;
  }
  .panel-social-kpi-view-container{
    margin-top: 12px;
  }
  .panal-source-counts:last-child{
    margin-bottom: 0px;
  }
}