.comp_measure {
  margin-left: 95%;
}

.comp_measure_div {
  width: 99%;
}

#projectCountName {
  line-height: initial;
  border: none;
  position: absolute;
  left: 15px;
  margin-top: 52px;
  box-shadow: inset 0 0 4px #a9a9a9;
  transition-duration: .5s;
  background-color: #f2f2f2;
  animation: moveline 2s;
  z-index: 10;
}

#comp_spectrum_jobs {
  background: linear-gradient(to right, red, #ff0, orange, green);
  padding: 15px;
  margin-top: 7px;
  /* height: 20px; */
  width: 100%;
  float: left;
  border-radius: 4px;
  border: 4px solid #a9a9a9;
  box-shadow: inset 0 0 10px #f9f9f9;
  position: relative;
}
.tableImg{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.compareTable{
  flex:1;
  min-width: 200px;
  border-right: 1px solid #ddd;
}
.compareTable:last-child{
  border-right: 0px;
}
.compareTable>div,
.compareTable>span{
  padding: 24px;
  width:100%;
  height: 89px;
  display: flex;
  color: #252530;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.compareTable>div:first-child,
.compareTable>span:first-child{
  height: 130px;
}

.titleBrandImg{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.compareTable>div:last-child,
.compareTable>span:last-child{
  border-bottom: 0px;
}
.compareTableFields>span{
  padding: 24px 12px;
  text-transform: capitalize;
}
.topAuthorsDiv{
  max-width: 26%;
  flex: 1;
  position: relative;
  margin-right: 24px;
}
.topAuthorsDiv:last-child{
  margin-right: 0;
}
.smallTableImg{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 6px;
}
/* .competitiveTable{
  table-layout: fixed;
} */
.competitiveTable td{
  min-width: 200px;
}
.wide {
  width: 150px;
}
.topMentionsOuterDiv .topMentionsDiv{
  flex: 1;
  max-width: 34%;
  margin-right: 12px;
}
.topMentionsOuterDiv .topMentionsDiv:last-child{
  margin-right: 0;
}
.topHashtags{
  margin-right: 24px;
  min-width: 200px;
  flex: 1;
}
.topHashtags:last-child{
  margin-right: 0;
}

@media(max-width:480px){
  .topAuthorsDiv{
    max-width: 100%;
    margin-right: 0;
  }
  .topMentionsOuterDiv .topMentionsDiv{
    max-width: 100%;
    margin-right: 0;
  }
}

#comp_line_white {
  padding: 5px;
  border: 1px solid #a9a9a9;
  width: 1px;
  height: 50px;
  position: absolute;
  margin-top: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 4px #a9a9a9;
  transition-duration: .5s;
  background-color: #f2f2f2;
  animation: moveline 2s;
  z-index: 10;
}

.show-comparison-project {
  margin: 2px;
  font-size: 12px;
  color: #fff;
  background: #0079c0;
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: 800;
  text-transform: capitalize;
}

/* .overflow-hidden-text {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.nps-top-show {
  color: #000;
  top: 30px;
  border-radius: 4px;
  padding: 2px 5px;
}

.nps-bottom-show {
  color: #000;
  border-radius: 4px;
  padding: 2px 5px;
}

.comp-total-mention-small-div {
  flex-basis: 100%;
  flex-grow: 1;
  height: 96px;
  max-width: 100px;
  margin: 4px;
}

.comp-total-mention-container {
  justify-content: space-around;
  background: #fff;
  flex-wrap: wrap;
  align-items: center;
  max-width: 325px;
}

.comp-main-mention-title {
  min-width: 85px;
  text-align: center;
}
.padding0{
  padding: 0;
}
/* .comp-main-mention-title p {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  white-space: nowrap;
} */

.margin-bottom10 {
  margin-bottom: 10px !important
}

.mention-count-box-overflow {
  max-height: 55px;
  overflow: hidden;
}

.comp-graph-box {
  margin-top: 24px;
}
.margin-right-toggle{
  margin-right:24px
}
@media( min-width: 480px) {
  .competitive-set-padding {
    padding: 0 24px
  }
}

@media (max-width:480px) {
  .competitive-set-padding {
    padding: 0 12px
  }
  .margin-12Phone{
    margin: 12px;
  }
  .margin-right-toggle{
    margin-right: 0
  }
}