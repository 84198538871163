span {
  font-weight: 400;
}

/* ==========================detail page back button======================== */
.graphSection{
  padding :24px
}
.backToProject {
  position: fixed;
  border-radius: 100%;
  margin-left: 5%;
  margin-top: 1%;
  bottom: unset;
  display: none;
  /* padding: 15px; */
  width: 50px;
  height: 50px;
  /* display: flex;
  justify-content: center; */
  z-index: 1111;
}

.backToProject i {
  color: #0079c0;
  font-size: 50px;
  width: 100%;
  cursor: pointer;
  background: white;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  width: 50px;
  opacity: .9;
}

.backToProject i:hover {
  opacity: 1;
}

/* --------------------------------------------------------------- */

.showAllFilters {
  display: block !important;
}
.mentionBox{
  border: 1px solid lightgrey;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom:15px;
}
.hideAllFilters {
  display: none !important;
}

.show-all-save-filter-div {
  position: absolute;
  background: #fff;
  width: 100%;
  border: 1px solid #f5f5f5;
  z-index: 10;
  border-radius: 4px;
}

.show-all-save-filter-div ul {
  padding: 5px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}

.list-all-filter:hover,
.list-all-filter:active,
.list-all-filter:focus {
  background-color: #f5f5f5;
}

.list-all-filter {
  padding: 4px 12px;
  background: #fff;
  margin: 1px 0
}

.cursor-pointer {
  cursor: pointer;
}

/* ================================label====================================== */

.pop-over-header {
  height: 35px;
  position: relative;
  margin-bottom: 8px;
  text-align: center;
}

.pop-over-header-title {
  box-sizing: border-box;
  color: #545454;
  display: block;
  line-height: 35px;
  border-bottom: 1px solid rgba(9, 45, 66, .13);
  margin: 0 12px;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
  cursor: auto;
}

.pop-over-header-close-btn {
  padding: 10px 12px 10px 8px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.pop-over-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 12px 12px;
}

.pop-over-header i {
  color: #545454;
}

.edit-labels-pop-over {
  margin-bottom: 8px;
  float: none !important;
}

.edit-labels-pop-over .mentionColorCard {
  position: relative;
  float: none !important;
  margin: 0 !important;
}

.card-label-edit-button {
  border-radius: 3px;
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
}

.displayInBottom {
  left: 0px;
  width: 200px;
  top: 38px;
  z-index: 5;
}

.displayInTop {
  left: 0px;
  width: 200px;
  bottom: 38px;
  z-index: 5;
}

.showlabelSection {
  display: block !important;
}
.showShareSection {
  display: block !important;
}

.icon-lg,
.icon-sm {
  color: #798d99;
}

.icon-sm {
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  width: 20px;
}

.card-label.mod-selectable {
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 4px;
  min-height: 20px;
  padding: 8px;
  position: relative;
  transition: padding 85ms, margin 85ms, box-shadow 85ms;
}

.mentionLabeltag {
  height: 8px;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 3px;
  width: 30px;
  display: inline-block;
  margin: 0px 0 0 5px;
}

.card-label-green,
.card-label-GREEN {
  background-color: #61bd4f !important;
}

.color-green {
  color: #61bd4f !important;
}

.color-red {
  color: #eb5a46 !important;
}

.card-label-yellow,
.card-label-YELLOW {
  background-color: #f2d600 !important;
}

.card-label-orange,
.card-label-ORANGE {
  background-color: #ff9f1a !important;
}

.card-label-red,
.card-label-RED {
  background-color: #eb5a46 !important;
}

.card-label-purple,
.card-label-PURPLE {
  background-color: #c377e0 !important;
}

.card-label-blue,
.card-label-BLUE {
  background-color: #0079bf !important;
}

.card-label-pink,
.card-label-PINK {
  background-color: #ff78cb !important;
}

.card-label-PINK.mod-selectable.selected {
  box-shadow: -8px 0 #c9558f !important;
}

.card-label-YELLOW.mod-selectable.selected {
  box-shadow: -8px 0 #d9b51c;
}

.card-label-GREEN.mod-selectable.selected {
  box-shadow: -8px 0 #519839;
}

.card-label-ORANGE.mod-selectable.selected {
  box-shadow: -8px 0 #cd8313;
}

.card-label-RED.mod-selectable.selected {
  box-shadow: -8px 0 #b04632;
}

.card-label-PURPLE.mod-selectable.selected {
  box-shadow: -8px 0 #89609e;
}

.card-label-BLUE.mod-selectable.selected {
  box-shadow: -8px 0 #055a8c;
}

.card-label:focus,
.card-label:active,
.card-label:hover {
  opacity: .9;
}

.card-label {
  background-color: #b3bec4;
  border-radius: 4px;
  color: #fff;
  display: block;
  margin-right: 4px;
  max-width: 100%;
  overflow: hidden;
  padding: 4px 6px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-label.mod-selectable .card-label-selectable-icon {
  position: absolute;
  top: 0px;
  right: -20px;
}

.card-label.mod-selectable.selected {
  margin-left: 4px;
}

.card-label.mod-selectable.active .card-label-selectable-icon {
  right: 6px;
  color: #fff;
}

.icon-lg,
.icon-sm {
  color: #798d99;
}

.icon-sm {
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  width: 20px;
}

.primary {
  background-color: #5aac44;
  box-shadow: 0 1px 0 0 #3f6f21;
  border: none;
  color: #fff;
}

.wide {
  padding-left: 24px;
  padding-right: 24px;
}

.u-float-right {
  float: right;
}

.negate {
  background-color: #eb5a46;
  box-shadow: 0 1px 0 0 #b04632;
  border: none;
  color: #fff;
}

.button.primary:active {
  background-color: #3f6f21;
  box-shadow: 0 1px 0 0 #3f6f21;
  border: none;
  color: #fff;
}
.u-clearfix{
  display: flex;
}

.u-clearfix .u-float-right{
  flex-basis: 100%;
}

.u-clearfix button {
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;
  margin: 8px 4px 0 0;
  padding: 6px 12px;
  float: left;
  flex-basis: 100%;
  width: 100%;
}

.pop-over-header-back-btn.is-shown,
.pop-over.is-shown {
  display: block;
}

.pop-over {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 16px -4px rgba(9, 45, 66, .25), 0 0 0 1px rgba(9, 45, 66, .08);
  display: none;
  overflow: hidden;
  position: absolute;
}

/* ======================================================= */

.all_filter-right {
  padding-left: 0;
}

.matchKeyword .form-check-label {
  margin-left: 10px;
  vertical-align: middle;
  font-size: 12px;
  padding-left: var(--checkbox-label-margin-left);
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  cursor: pointer;
  font-weight: 400;
  color: #0079c0;
}

.matchKeyword .form-check {
  text-align: right;
  width: 100%;
}

.mentionSearchCrossIcon {
  position: absolute;
  padding: 8px 20px 0 0;
  color: #0079c0;
  cursor: pointer;
  display: none;
}

.mentionSearchInfoIcon {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.markedAndDeleted-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 15px;
}

.markedAndDeleted-div {
  margin: 5px 0 5px 15px;
  color: #0079c0;
  font-size: 12px;
  cursor: pointer;
}

.showingFilterList:first-child{
  margin: 2px 4px 2px 4px;
}

.showingFilterList {
  /* color: #5c5c5c;
  font-size: 14px;
  border: 1px solid #000;
  padding: 5px;
  margin-right:10px; */
  display: inline-block;
  font-size: 12px;
  background-color: #f5f5f5;
  border-radius: 3px;
  margin: 2px 4px 2px 0;
  overflow: hidden;
  transition: background-color 0.1s;
  max-width: 301px;
  padding: 8px;
  vertical-align: top;
  border: 2px solid transparent!important
}
.show-filters-applied-container .btn.btn-primary{
  margin: 0px 4px;
}

.filtertitle>#filtersSection .filterListHeading {
  padding: 8px 8px 8px 0px
}

.show-filters-applied-container{
  max-height: 165px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filterListHeading {
  color: #0079c0;
  display: inline-block;
  vertical-align: top;
  padding: 8px 0 8px 0;
}

.all_filter-left {
  padding-right: 0px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
body.modal-open .all_filter-left{
  position:relative;
}

.all_filter {
  margin-left: 0;
  margin-right: 0;
}

.filter-list>li {
  padding-top: 5px;
  /* padding-right: 30px; */
}

.filter-list {
  margin-bottom: 0;
}

select.socialOption, select.kolSocialOption {
  font-family: 'FontAwesome', 'sans-serif';
}

/* .mention-dashboard {
  margin: -30px -5px 0 -5px;
} */

.mention-dashboard {
  padding: 0 24px;
}
.discovery-dashboard{
  padding: 0 24px;
}

.filter-div {
  background: #fff;
  padding: 24px;
  padding-bottom: 0;
}

.filtersDiv h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.shopping-box {
  background: #fff;
  padding: 24px;
}

.mention_header-container {
  padding: 24px
}

.mention-dashboard>.col-md-12 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.discovery-dashboard>.col-md-12{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#clearAll,
#reportClearAllFilter,
.filterclearall {
  cursor: pointer;
  font-size: 12px;
}

.filterclearall {
  display: inline-block;
  color: #0079c0;
  padding: 8px 0px;
}

#clearAll:hover,
.filterclearall:hover,
#reportClearAllFilter:hover,
#clearAll:active,
.filterclearall:active,
#reportClearAllFilter:active,
#clearAll:focus,
.filterclearall:focus,
#reportClearAllFilter:focus {
  color: #0079c0;
  cursor: pointer;
}

.filter-title {
  display: flex;
}

.filter-hr {
  margin-top: 10px !important;
  margin: 0 -25px;
}

.filter-source>h5 {
  margin: 20px 0;
}

.filter-source {
  padding: 12px 0;
  margin-bottom: -10px;
}

.shopping-sec {
  background: #fff;
  display: grid;
}

/* ==============label filter box==================== */

.detailmarginlabel {
  margin-bottom: 12px;
  display: flex;
}

.label-list-item {
  margin-bottom: 2px;
}

.label-list-item-link {
  display: flex;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.label-list-item-link {
  border-radius: 3px;
  display: block;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  padding: 4px 4px 4px 42px;
  position: relative;
}

.label-list-item-link-label {
  left: 4px;
  position: absolute;
  top: 4px;
}

.card-label.mod-square {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
}

.card-label {
  background-color: #b3bec4;
  border-radius: 4px;
  color: #fff;
  display: block;
  margin-right: 4px;
  max-width: 100%;
  overflow: hidden;
  padding: 4px 6px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-label-green {
  background-color: #61bd4f;
}

.label-list-item-link-name.mod-quiet {
  color: #000;
}

.label-list-item-link.is-active .label-list-item-link-icon {
  display: block;
}

.label-list-item-link-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.label-list-item.is-active .label-list-item-link-icon {
  display: block;
}

#mentionlabelFilter>li,
#reportlabelFilter>li {
  margin-top: 0px;
}

.label-list-item-link-icon {
  display: none;
  position: absolute;
  top: 3px;
  left: 5px;
}

.icon-lg,
.icon-sm {
  color: #fff;
}

/* .icon-sm {
  height: 20px;
  font-size: 12px;
  width: 20px;
} */

/* --------------------------- */

.form-check-label {
  margin-left: 10px;
  vertical-align: middle;
  font-size: 14px;
  padding-left: var(--checkbox-label-margin-left);
  /* color: var(--color-grey-grade5); */
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  cursor: pointer;
  font-weight: 400;
  color: #404040;
}

.form-check-label:hover,
.form-check-label:focus,
.form-check-label:active {
  color: #0079c0;
}

.filter-source>a {
  position: unset;
  color: #404040;
  position: unset;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.filter-source>a:hover {
  color: #404040;
}

.filter-source>a::after {
  color: #404040;
  background: none;
}

.filter-source>a[aria-expanded=true] {
  color: #404040;
  background: none;
}

.filter-source a[aria-expanded=false]::before {
  /* position: unset;
  float: right;
  content: '\e259';
  display: block; */
  content: '\e259';
  display: block;
  position: absolute;
  right: 20px;
  font-family: 'Glyphicons Halflings';
  font-size: .6em;
}

.filter-source>a[aria-expanded=true]::before {
  content: '\e260';
}

.filtersubtitle {
  margin-top: 5%;
}

.filter-upper {
  display: flex;
  margin: 0 15px;
}

.filtersearch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 10;
}

/* .filtersearch>input[type=text]:focus {
  width: 80%;
} */

.filtersearch>input[type=text] {
  width: 50%;
  /* margin: 0 0px 10px 0; */
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 2px 24px 2px 10px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

.filter-sort>ul>li {
  display: inline-block;
  padding: 5px 0 0px 0;
  margin: 0 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.filter-sort>ul>li:first-child{
  margin-left: 0px
}

.filter-sort {
  /* margin-left: 5px; */
  display: flex
}

.filter-sort>ul {
  flex-grow: 8
}

.filter-sort>#showingNumber {
  display: flex;
  flex-direction: column;
  /* margin: 0px 15px 0px 0px; */
  margin: 0%
}

.filter-sort>#showingNumber>#helpText {
  margin-top: -5px;
  font-size: 10px;
  font-style: italic;
  text-align: right;
}

.filter-sort>ul>li:first-child>span {
  font-weight: 900;
  color: black;
  cursor: default;
}

.filter-sort>ul>li>.active {
  border-bottom: 2px solid #2196F3;
  color: #2196F3;
}

.picturedesc .carousel-control .glyphicon-chevron-left,
.picturedesc .carousel-control .glyphicon-chevron-right,
.picturedesc .carousel-control .icon-next,
.picturedesc .carousel-control .icon-prev {
  font-size: 15px !important;
}

.textdesc .mention_text {
  overflow: visible !important;
  height: 100% !important;
  padding-bottom: 0;
  text-align: left;
}

.filterdata .filter-head .mention-box .header-enterprise {
  padding: 20px 35px 0 !important;
}

.filter-head {
  margin: 0;
}

.mention-heading-text span {
  line-height: 18px;
  font-size: 12px
}

.mention-heading-text {
  line-height: 18px;
  padding: 0 15px;
}

.header-enterprise .filteravatar>.author {
  /* float: left; */
  /* width: 54% !important; */
  margin-top: 1%;
  flex-grow: 1;
  overflow: visible;
  padding-left: 5px;
}

.filteravatar>.mention-box>.mention-information>li {
  float: left;
  margin-left: 20px !important;
  padding-left: 20px !important;
}

.filter-head section.mention-box .options {
  padding: 0 10px !important;
}

.mention-information>div {
  font-size: 12px;
  margin: 0;
  padding: 0;
  float: right;
}

section.mention-box {
  width: 100%;
  height: auto;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 1px rgba(222, 222, 222, 1);
  -moz-box-shadow: 0 0 10px 1px rgba(222, 222, 222, 1);
  box-shadow: 0 0 10px 1px rgba(222, 222, 222, 1);
  color: #545454;
  float: left;
  border-radius: 0x;
  margin-bottom: 15px;
  position: relative;
  margin-left: 0px;
}

#resizeImage {
  width: 100%;
  height: 100%;
}

.resizeImage {
  min-height: 150px
}

.pagination>li.active,
.pagination>li>a:hover,
.pagination>li>a:focus {
  background: #0079c0;
  color: white !important;
  cursor: pointer;
}

.filter-sort>#showingNumber>.content-view {
  float: right;
  padding: 0px 0px 0 0;
  color: #0079c0;
}


.detail-filter-mention{
  padding: 5px 12px 0 0;
}
.view-mentionboard{
  margin-bottom: 0;
  padding-right: 5px;
  color: #000;
}
/* //modal */

#attributeModal .modal-dialog,
#websiteModal .modal-dialog,
.filter-modal-view .modal-dialog{
  width: 600px;
  top: 15%;
  left: 0;
  margin: 0;
}

#attributeModal .modal-content,
#websiteModal .modal-content,
.filter-modal-view .modal-dialog {
  border-radius: 0px;
}

#attributeModal .modal-dialog .modal-content>.modal-body,
#websiteModal .modal-dialog .modal-content>.modal-body,
.filter-modal-view .modal-dialog .modal-content>.modal-body {
  display: flex;
  overflow-y: hidden;
}

#attributeModal .modal-footer>button,
#websiteModal .modal-footer>button,
.filter-modal-view .modal-footer>button {
  height: 38px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 10px;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 1px;
  border: 0px solid transparent !important;
  opacity: 1;
}

#attributeModal .modal-footer>.btn-default,
#websiteModal .modal-footer>.btn-default,
.filter-modal-view .modal-footer>.btn-default {
  color: #0079c0;
}

.showSideBarClass{
  display: block !important
}

.modal-footer>.btn-default:active,
.modal-footer>.btn-default:focus,
.modal-footer>.btn-default:hover {
  background: #fff !important;
  color: #0079c0 !important;
  outline: 0 !important;
}

.keywordsbtn>.btn-default:active,
.keywordsbtn .btn-default:focus,
.keywordsbtn>.btn-default:hover {
  background: #fff !important;
  color: #0079c0 !important;
  outline: 0 !important;
}

.filtertitle>.header-enterprise {
  margin-right: 0%;
}

.modal-body>ul.list-unstyled {
  padding: 0px 15px;
}

.label-more, .show-all-sub-topics {
  color: #0079c0;
  margin-left: 0;
}

#attribute>li:first-child {
  margin-top: 10px;
}

.fa-angle-down:before {
  display: none;
}

.fa-angle-up:before {
  content: "\f106";
  display: none;
}

.marktrue {
  color: #ff8000 !important;
}

.markfalse {
  color: #0079c0 !important;
}

.mentiondescImageSize {
  margin-bottom: 2%;
  max-height: 200px;
  max-width: 185px;
}

section.mention-box .mentionDescription {
  /* font-size: 12px; */
  padding: 12px 0;
  width: 100%;
  /* line-height: 18px; */
  /* float: left; */
  overflow: hidden;
  color: #545454;
  /* min-height: 80px; */
}

section.mention-box .mentionDescription p {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 18px;
  color: #3E4144;
  font-weight: 400;
  overflow: hidden;
  /* height: 67px; */
}

.mentionDescription p a {
  color: #0079c0;
}

.options>ul>li>a {
  font-size: 12px;
}

.options ul li a {
  color: #0079c0
}

.mentionDescription>p {
  margin: 0 !important;
}

.mention-sm-filterinside,
.mention-sm-sortinside {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #0079c0;
}

.mention-sm-filterinside i,
.mention-sm-sortinside i {
  padding: 12px;
  font-size: 24px;
}

.mention-sm-filterinside span,
.mention-sm-sortinside span {
  font-size: 18px;
  padding: 12px;
}

.font-size-12 {
  font-size: 12px
}

/* ************************************Alert*********************************** */

/* finish of mention-enterprise and starting of enterprise-Project-Settings */

#supportingnotify {
  padding-top: 12px;
}

.padding-right-0 {
  padding-right: 0;
}

.header-enterprise .companyheaderlogo {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: contain;
  background-size: contain; 
  margin-right: 12px;
}

.inside-breadcrumbs {
  font-size: 12px;
}

.supportmail:hover,
.supportmail:focus,
.supportmail:active {
  color: #0079c0;
}

.supportmail {
  color: #545454
}

.setting-project-title {
  color: black;
  font-size: 14px;
  margin-bottom: 0;
}

.setting-project-keywords-limit {
  color: #545454;
  font-size: 11px;
  margin-bottom: 0;
}

.show-access-required-btn{
  background: #0079c0;
  color: #fff;
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 4px;
  font-weight: 800;
  opacity: 0.9;
}

.show-access-required-btn:hover{
  opacity: 1;
}

.settinghelptext {
  font-style: italic;
  font-size: 13px;
}

.settinghelpContainer {
  line-height: 1.2;
}

hr.settinglineseprater {
  margin: 24px 0;
}

.settings {
  margin: 15px;
}

@media (min-width: 480px) {
  .project-settings {
    padding: 0 24px
  }
  .settings {
    margin: 0;
    padding: 24px
  }
}

.frequencyOption {
  color: black
}

.projectSettingInfo::title {
  background-color: #fff;
  color: #000;
}

span.projectname {
  font-size: 20px;
  font-weight: 500;
}

.option-settings>li.active {
  min-width: 14%;
  text-align: center;
  border: 2px solid #0079c0;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 2px solid #fff;
  border-radius: 4px;
}

.filter-sort>ul>li.active {
  color: #2196F3;
}

.account_showSingle.active {
  padding: 4px 10px;
}

.option-settings>li {
  min-width: 12%;
  text-align: center;
}

.increaseInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.increasekeyword {
  margin-right: 5%;
  border: 1px solid #ccc;
  color: black !important;
  font-weight: 400;
}

.increasekeyword:focus {
  border: 1px solid #0079c0 !important;
}

.baseline {
  display: flex;
}

.increaseInput>.fa-check {
  color: green !important;
  cursor: default;
  font-size: 30px;
}

.increaseInput>.fa-times {
  color: red !important;
  cursor: default;
  font-size: 30px;
}

.increaseInput>.fa-plus-circle {
  color: #0079c0;
  font-size: 30px;
}

.addSupporingKeywordbtn {
  display: flex;
  flex-direction: row;
}

.addSupporingKeywordbtn i {
  color: #0079c0;
  font-size: 24px;
  cursor: pointer;
}

.addSupporingKeywordbtn span {
  padding: 0 12px;
  font-size: 14px;
  cursor: pointer;
}

.supporingKeywordDisplay {
  display: block !important;
}

.supporingKeywordbtn {
  display: none !important;
}

.supporingContainer {
  display: flex;
  margin-bottom: 0px;
}

.supporingContainer .fa-chevron-down,
.supporingContainer .fa-chevron-up {
  color: #0079c0 !important;
  padding: 5px;
}

/* .justify-content-center>.baseline {
  margin-bottom: 2%;
} */

.margin-top-10{
  margin-top: 10px;
}

.keywordsbtn {
  float: right;
  margin: 5% 5% 2% 0;
}

.keywordsbtn>.btn-default {
  color: #0079c0;
  border: 1px solid #0079c0 !important;
}

.keywordsbtn>button {
  /* height: 38px; */
  text-align: center;
  /* font-size: 12px; */
  text-transform: uppercase;
  display: inline-block;
  /* padding: 0 10px; */
  /* font-weight: 500; */
  cursor: pointer;
  /* letter-spacing: 1px; */
  /* border: 0px solid transparent !important; */
  opacity: 1;
}

.keywordsbtn>button:first-child {
  margin-right: 10px;
}

.comingsoon {
  padding-top: 12%;
  min-height: 60vh;
}

.targetDiv {
  display: none;
  min-height: 650px;
}

.settingSectionactive {
  display: block;
}

.notification {
  text-shadow: 1px 12px 7px #0079c0;
  font-size: 80px;
}

.addSourcediv,
.brandSourcediv {
  display: flex;
  margin-bottom: 2%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.increasekeyword::placeholder {
  color: #999999;
  font-weight: 400;
}

/* ************************************Alert*********************************** */

/* finish of mention-enterprise and starting of enterprise-Account-Settings */
.account-pricing .price-box-divs{
  border: 2px solid #0079c0;
  max-width: 300px;
}

@media(min-width: 480px){
  .account-pricing {
    display: flex;
    justify-content: space-evenly;
    padding: 1em 0;
    margin: 0;
  }
  
}

@media(max-width: 480px){
  .account-pricing .price-box-divs{
    max-width: 100%;
  }
  .all_filter-left {
    position: unset;
  }
  
}


.roundActiveIcon {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 25px;
  margin-bottom: 0px;
}

.accountCentertext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .table>thead>tr.as-subscription-table>th.profile-tablehead {
  font-size: 12px !important
} */
.accountContainer {
  padding: 0;
}

.account_Sectionactive {
  display: block;
}

.account_increasekeyword::placeholder {
  color: #999999;
  font-weight: 400;
}

.account_increasekeyword {
  margin-right: 5%;
  border: 1px solid #ccc !important;
}

.account_increasekeyword:focus {
  border: 1px solid #0079c0 !important;
}

/* #account_edit_profile {
  display: none;
} */

.account_edit_profile-click {
  cursor: pointer;
  color: #0079c0;
}

.account_row_margin {
  margin-top: 10px;
}

/* for image uplode in account */

.avatar-upload {
  position: relative;
  max-width: 140px;
  /* margin: 0px auto; */
}

.avatar-upload-display {
  position: relative;
  /* max-width: 205px; */
  margin: 0px auto 20px auto;
}

.avatar-upload-display .avatar-preview-display {
  width: 75px;
  height: 75px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload-display .avatar-preview-display>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 55px;
  z-index: 1;
  top: 5px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 75px;
  height: 75px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.btn.btn-default.pricing-default-btn:hover{
  color: #333;
  background-color: #e6e6e6;
}

/* **************************influencerDetail.pug********************************* */

.flexbaseline {
  display: flex;
  align-items: baseline;
  margin: 1% 0;
}

.flexbaseline img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.audienceprofile {
  flex-grow: 5;
}

.audiencerank {
  flex-grow: 0;
  display: flex;
}

.audienceprofile span,
.audiencerank span {
  padding: 0 2%;
}

.influential-li>li.active {
  text-align: center;
  border-bottom: 2px solid #0079c0;
}

.influential-li>li {
  min-width: 50px;
  text-align: center;
  margin: 0 20px !important;
  padding: 8px 0px 4px;
  cursor: pointer;
}

/* ***************************************influential******************************************** */

.infl_getCsvContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
}

.infl_getCsvContainer .fa-download {
  font-size: 20px;
  cursor: pointer;
}

.infl_getCsvContainer .fa-download:hover {
  color: #0079c0;
}

h1.heading {
  color: #fff;
  font-size: 1.15em;
  font-weight: 900;
  margin: 0 0 0.5em;
  color: #505050;
}

.reportRangeContainer {
  background: #fff;
  cursor: pointer;
  padding: 5px 5px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}

.reportSelectDateClass {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}
.reportSelectDateClassNew {
  width: unset;
}
.fromdate,
.todate {
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

#fromdate,
#todate {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #000;
}

.filter-page {
  margin: 0 15px;
}

#imaginary_container {
  margin-top: 20%;
}

.stylish-input-group .input-group-addon {
  background: white !important;
}

.stylish-input-group .form-control {
  border-right: 0;
  box-shadow: 0 0 0;
  border-color: #ccc;
}

.stylish-input-group button {
  border: 0;
  background: transparent;
}

.md-form .filtersearch,
.md-form .filtersearch:focus {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
  padding: 10px 0 0 5px;
}

.md-form {
  display: flex;
  align-items: baseline;
  margin: 10px;
  margin-bottom: 1.5em;
  padding-right: 15px;
  margin-top: 0;
}

.md-form .filtersearch::placeholder {
  color: #545454;
  font-weight: 400;
}

.filterclick p {
  font-size: 24px;
  width: 100%;
  overflow: hidden;
}

.reportdetails>p {
  color: #545454;
  font-size: 14px;
}

.reportdetails>h4 {
  font-size: 18px;
}

.previousReportDiv .previousReportHeading {
  font-size: 14px;
  color: #545454;
}

.previousReportDiv .previousReportdes {
  font-size: 12px;
  color: #848484;
}

.previousReportDiv a {
  color: #545454;
}

.custom_report_btn {
  margin: 15px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.report_custum_date {
  font-size: 11px;
  display: flex;
  align-items: center;
}

.previousReportDiv li {
  display: flex;
  justify-content: space-between;
}
.pdfPageModel{
  width: 60%
}

@media (min-width:768px) {
  #customReport_Walkthrough {
    display: flex
  }
}

/* **********for sentiment hide ^ *********** */

/* mention page */

.sentimentUserLiDiv {
  margin: 0 !important;
}

.selectedSentiment .POSITIVE {
  background: green
}

.selectedSentiment .NEGATIVE {
  background: red
}

.selectedSentiment .POSITIVE .mentionBorderDisplay,
.selectedSentiment .NEGATIVE .mentionBorderDisplay,
.selectedSentiment .NEUTRAL .mentionBorderDisplay {
  border-radius: 40px 0 0 0;
}

.selectedSentiment .NEUTRAL {
  background: #0079c0
}

.selectedSentiment .NOTFOUND {
  background: #0079c0
}

.selectedSentiment .POSITIVE .mentionBorderDisplayTop,
.selectedSentiment .NEGATIVE .mentionBorderDisplayTop,
.selectedSentiment .NEUTRAL .mentionBorderDisplayTop {
  margin-top: 3px;
  margin-bottom: -3px !important;
}

.reportcontainer {
  margin: 0
}

.reportbutton>.btn-primary:disabled:focus,
.reportbutton>.btn-primary:disabled:hover,
.reportbutton>.btn-primary:disabled:active {
  background-color: #0079c0
}

#imgUploadErrDiv p {
  color: red
}

.report-content-container {
  border-top: 1px solid #eee;
  margin-top: 10px
}

@media only screen and (max-width: 991px) {
  .reportdetails {
    text-align: center;
    min-height: 150px;
  }
  .reportbutton {
    text-align: center;
  }
  .filterclick>img {
    height: 75px !important;
    width: 75px !important;
    padding-top: 10px;
    margin: 0 20%;
  }
  .reportbutton>button {
    font-size: 12px;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .reportcontainer {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .filterclick>img {
    padding-top: 10px;
    margin: 0 35%;
    height: 75px !important;
    width: 75px !important;
  }
  .reportbutton>button {
    font-size: 12px;
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 450px) {
  h1.heading {
    font-size: 3.55em;
  }
}

@media (min-width: 760px) {
  h1.heading {
    font-size: 3.05em;
  }
}

@media (min-width: 900px) {
  h1.heading {
    font-size: 3.25em;
    margin: 0 0 0.3em;
  }
}

@media only screen and (min-width: 991px) {
  .reportdetails {
    text-align: left
  }
  .reportbutton {
    text-align: left
  }
  .filterclick>img {
    padding-top: 5px;
    margin: 10% 0;
    height: 75px !important;
    width: 75px !important;
  }
  .reportbutton>button {
    font-size: 14px;
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .reportbutton {
    text-align: center
  }
  #fromdate,
  #todate {
    width: 100%
  }
  .reportbutton .infoerr,
  .reportbutton .pdferr,
  .reportbutton .excelerr,
  .reportbutton .customerr {
    margin: auto 2%;
  }
}

@media only screen and (min-width: 991px) {
  .shopping-sec {
    min-height: 100vh;
  }
}

@media(max-width:1024px) {
  .filter-source {
    padding: 12px 0;
  }
  section.mention-box .mentionDescription {
    font-size: 13px;
    padding: 0px 24px;
    width: 100%;
    line-height: 20px;
    float: left;
    overflow: hidden;
    color: #545454;
    min-height: 100px;
  }
  form.filter-div a[aria-expanded=false]::before {
    content: '\e259';
    display: block;
    position: absolute;
    right: 40px;
    font-family: 'Glyphicons Halflings';
    font-size: .6em;
  }
  form.filter-div a[aria-expanded=true]::before {
    content: '\e260';
    display: block;
    position: absolute;
    right: 40px;
    font-family: 'Glyphicons Halflings';
    font-size: .6em;
  }
  form.filter-div {
    padding: 24px 24px 0 24px;
  }
  .all_filter-right,
  .all_filter-left {
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 10px;
  }
  .all_filter-left {
    display: none;
    height: auto;
  }
  .mentiondescImageSize {
    margin-bottom: 2%;
    max-width: 150px;
    max-height: 175px;
  }
  .u-clearfix button {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin: 4px 4px 0 0;
    padding: 0px 4px;
    float: left;
  }
  .card-label.mod-selectable {
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 4px;
    min-height: 16px;
    padding: 8px;
    position: relative;
    transition: padding 85ms, margin 85ms, box-shadow 85ms;
  }
  .pop-over-header-title {
    box-sizing: border-box;
    color: #545454;
    display: block;
    line-height: 30px;
    border-bottom: 1px solid rgba(9, 45, 66, .13);
    margin: 0 12px;
    overflow: hidden;
    padding: 0 32px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
    cursor: auto;
  }
  .pop-over-header {
    height: 30px;
    position: relative;
    margin-bottom: 8px;
    text-align: center;
  }
  .pop-over-header-close-btn {
    padding: 5px 12px 5px 8px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .icon-sm {
    height: 15px;
    font-size: 12px;
    line-height: 17px;
    width: 20px;
  }
  .card-label.mod-selectable .card-label-selectable-icon {
    position: absolute;
    top: 0px;
    right: -20px;
  }
}

@media (max-width:730px) {
  .header-enterprise .filteravatar>.mention-information {
    float: left;
    width: 88%;
    font-size: 12px;
    margin: 1% 0 0;
  }
  .textdesc .mention_text {
    padding-top: 2%;
  }
}

@media (min-width:730px) {
  .header-enterprise .filteravatar>.mention-information {
    float: right;
    width: 34%;
    font-size: 12px;
    margin: 0;
    margin-top: 1%;
  }
}

@media (max-width:1024px) and (min-width:481px) {
  .filtersearch>input[type=text] {
    width: 80%;
  }
  section.mention-box .mentionDescription {
    font-size: 13px;
    padding: 12px 0px;
    width: 100%;
    line-height: 20px;
    float: left;
    overflow: hidden;
    color: #545454;
    min-height: 100px;
  }
}

@media (max-width:480px) {
  /* .mention-dashboard {
    margin: -30px -5px 0 -5px;
  } */
  .filter-source {
    padding: 12px 0;
  }
  form.filter-div {
    padding: 24px 24px 0 24px;
  }
  .alert.alert-info.alert-with-icon.animated {
    width: 90% !important;
    right: 0px !important;
  }
  .addSupporingKeywordbtn {
    flex-grow: 1;
  }
  .autocomplete input[type=text].mobile-inputOnProjectSetting {
    border-color: #ccc;
    font-size: 14px;
    background-color: #FFF;
  }
  .mobile-settingSectionBreadcrumbs {
    margin: 12px 0;
  }
  .mobile-allBlockWidget {
    border-bottom: 1px solid #eceff1;
  }
  .mobile-allBlockWidget .mention-inside {
    padding: 24px 12px;
    border: none;
  }
  .supporingContainer {
    margin-bottom: 0;
    display: flex
  }
  .keywordsbtn>button:first-child {
    margin-right: 0;
  }
  .keywordsbtn {
    float: right;
    margin: 24px 0;
  }
  .accountContainer {
    padding: 0;
  }
  .mentioncontainer .filter-sort#sortInfluencerInMobileView,
  .mentioncontainer .filter-sort#sortInMobileView {
    display: none;
    position: absolute;
    z-index: 5;
    top: 80%;
    right: 20%;
    background: #f1f1f1;
  }
  .filter-sort>ul.option-settings>li.showSingle {
    display: inline-block;
    padding: 5px 10px 0px;
    margin: -1px -1px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    border-radius: 4px;
  }
  .filter-sort>ul.option-settings>li.showSingle.active {
    display: inline-block;
    padding: 5px 10px 0px;
    margin: 0 -1px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    border-left: 2px solid #0079c0;
    border-right: 2px solid #0079c0;
    border-top: 2px solid #0079c0;
    border-radius: 4px;
  }
  #subheading {
    padding: 0 10px 10px;
    font-size: 12px;
  }
  .mentionDescription>.row {
    margin: 0;
  }
  .mentionDescription>.row>.picturedesc,
  .mentionDescription>.row>.textdesc {
    padding: 0;
  }
  .filter-page {
    margin: 0 10px;
  }
  .pagination {
    padding-left: 0;
    margin: 0;
    border-radius: 4px;
  }
  .pagination>li>a,
  .pagination>li>span {
    position: relative;
    float: left;
    padding: 4px 6px !important;
    line-height: 1.42857;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    /* margin-left: -1px; */
  }
  .filterListHeading {
    color: #0079c0;
    padding: 2px 0 2px 2px;
    font-size: 12px;
  }
  .filterclearall {
    display: inline-block;
    color: #0079c0;
    padding: 2px 0;
    font-size: 12px;
  }
  .showingFilterList {
    font-size: 10px;
    background-color: #f5f5f5;
    margin: 2px 4px;
    transition: background-color .1s;
    max-width: 290px;
    padding: 2px;
  }
  .backgroundforUpperDiv {
    background: #fff;
    padding: 10px 0;
  }
  .mobileTrackingMention {
    padding: 10px;
  }
  section.mention-box .mentionDescription {
    font-size: 13px;
    padding: 0 10px;
    width: 100%;
    line-height: 20px;
    float: left;
    overflow: hidden;
    min-height: 70px;
  }
  section.mention-box .mentionDescription p {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 12px;
    line-height: 21px;
    color: #3E4144;
    font-weight: 400;
    overflow: hidden;
    height: 67px;
  }
  .filter-upper {
    padding-left: 0;
    display: block;
    margin: 0;
  }
  .shopping-box {
    background: #eceff1;
    padding: 0px;
  }
  .mention-dashboard {
    margin: 0;
    padding: 0;
  }
  .discovery-dashboard{
    margin: 0 12px;
    padding: 0;
  }
  .all_filter-left,
  .all_filter-right {
    margin: 0;
    padding: 0;
  }
  .reportRangeContainer,
  .reportSelectDateClass {
    background: #fff;
    cursor: pointer;
    padding: 1px 0px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 5px;
  }
  /* =========report================= */
  .reportdetails {
    min-height: 0;
  }
  .filtertitle>#filtersSection .filterListHeading {
    padding: 2px 0px 0px 0;
  }
  
  .reportcontainer {
    margin: 12px;
    background: white;
  }
  .reportbutton>button {
    font-size: 12px;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .report-content-container {
    border-top: unset;
    margin-top: 0;
  }
  /* -------------------------- */
  .textdesc .mention_text {
    padding-top: 0;
  }
  .displayInBottom {
    left: 0;
    width: 150px;
    bottom: auto;
    z-index: 101;
    margin-top: 35px;
    top: 0
  }
  .displayInTop {
    left: 0;
    width: 150px;
    bottom: 38px;
    z-index: 101;
    margin-top: 35px;
    top: auto;
  }
  .filter-head {
    margin: 0;
    padding: 0;
  }
  .filter-head:first-child {
    margin-top: 10px !important;
  }
  section.mention-box {
    width: 100%;
    height: auto;
    float: left;
    border-radius: 0x;
    margin-bottom: 12px;
    box-shadow: none;
    position: relative;
    margin-left: 0;
  }
  .filter-head section.mention-box .options {
    padding: 0 !important;
    border-bottom: solid 1px #e3e3e3;
  }
  .summary_option>ul>li>a .fa {
    font-size: 11px;
    text-shadow: 0 1px 10px rgba(0, 0, 0, .15);
  }
  .options>ul>li>a {
    font-size: 11px;
  }
  .filter-upper {
    padding: 0 10px;
    display: block;
    margin: 0;
  }
  .header-enterprise .mention_header-title {
    font-size: 16px !important;
  }
  #helper {
    padding: 0 10px;
    font-size: 12px;
    line-height: 18px;
  }
  .mention_text>a {
    font-size: 12px
  }
  .filtersearch>input[type=text] {
    width: 80%;
  }
  .filtersearch {
    padding: 10px 10px 0 10px;
    text-align: right;
  }
  .option>ul>li>a {
    font-size: 11px !important;
    text-shadow: 0 1px 10px rgba(0, 0, 0, .15);
  }
  .mention-sm-filterinside i,
  .mention-sm-sortinside i {
    padding: 8px 0 8px 6px;
    font-size: 20px;
  }
  .mention-sm-filterinside span,
  .mention-sm-sortinside span {
    font-size: 14px;
    padding: 6px;
  }
  section.mention-box li.mentionOptionList a {
    font-size: 11px !important;
    text-shadow: 0 1px 10px rgba(0, 0, 0, .15);
  }
  section.mention-box li.mentionOptionList a .fa {
    font-size: 11px !important;
    text-shadow: 0 1px 10px rgba(0, 0, 0, .15);
  }
 
  .mentiondescImageSize {
    margin-bottom: 2%;
    max-width: 50px;
    max-height: 60px;
  }
  .setting-project-title {
    color: black;
    font-size: 12px;
    margin-bottom: 0;
  }
  .accountContainer span {
    font-size: 12px
  }
  /* =============project Settings=============== */
  .increaseInput>.fa-check {
    color: green !important;
    cursor: default;
    font-size: 20px;
  }
  .increaseInput>.fa-plus-circle {
    color: #0079c0;
    font-size: 20px;
  }
  .settinghelpContainer {
    margin-bottom: 10px;
  }
  .pdfPageModel{
    width: unset
  }
  /* --------------------------------------- */
}

@media (min-width:480px) and (max-width:1024px) {
  .displayInBottom {
    left: 0;
    width: 150px;
    top: 35px;
    z-index: 5;
  }
  .displayInTop {
    left: 0;
    width: 150px;
    bottom: 38px;
    z-index: 5;
  }
  .alert.alert-info.alert-with-icon.animated {
    width: 60% !important;
    right: 0px !important;
  }
}

.supportingreach,
.requiredreach,
.optionalreach,
.hashtagreach,
.brandPromotedHashtagsreach,
.audienceHashtagsreach,
.notKeywordreach,
.personalityreach,
.socialreach,
.additionalsocialreach,
.brandSourceReach,
.freqreach,
.kolSourcesError,
.projectNameError {
  color: #ff0000
}

section.mention-box1{
  width: 100%;
  height: auto;
  color: #545454;
  float: left;
  border-radius: 0;
  position: relative;
  margin-left: 0; 
  padding-top: 12px;
  padding-bottom:0px
}
.sentiment-type{
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
}
.sentimentPOSITIVE {
  background: rgba(146,208,80)
}

.sentimentNEGATIVE {
  background: red
}

.sentimentNEUTRAL {
  background: #0079c0
}

.sentimentNOTFOUND {
  background: #0079c0
}

.dashboard-sort{
  
  margin-bottom: 0px;
}
.filter-sort.dashboard-sort>ul>li:first-child{
  margin-left: 0px;
}

section.mention-box .-reply-button{
  padding-right: 15px!important;
  margin: 0 !important;
  transition: .5s;
  /* border-right: solid 1px #e3e3e3; */
}
section.mention-box .-like-button{
  padding-right: 15px!important;
  margin: 0 !important;
  transition: .5s;
  /* border-right: solid 1px #e3e3e3; */
}
section.mention-box .-engage-button{
  padding-right: 15px!important;
  margin: 0 !important;
  transition: .5s;
  /* border-right: solid 1px #e3e3e3; */
}
.mentions-button{
  padding: 0 12px;
  margin: 0 !important;
  /* border-right: solid 1px #e3e3e3; */
}

div[class^="replyPostView"] {
  transition: .5s
}

.sentiment-mention{
  padding: 5px 0;
  margin-left: -25px ;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  background-clip: padding-box;
  min-width:auto; 
}
.irreleventSentiment{
  padding: 0 12px;
  margin: 0 !important;
  border: unset !important;
}

.mention-optionType{
  justify-content: flex-end;
}

.mention-optionName{
  padding-right: 20px;
}
.topMentionsDiv .mention-optionName{
  padding-right: 12px;
}
.topMentionsDiv .mention-information{
  flex-wrap: wrap;
}
.mention-valueType{
  padding-right:4px;
}
span.highlightMentionPost{
  padding: 0;
  color: #000;
  font-weight: 800;
}
.textdesc .mention-post-desc-clip{
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}
.topTrendingHeading{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.textdesc .mentionDetail-post-desc-clip{
  display: -webkit-box;
  -webkit-line-clamp: 20;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}
.highlightedTextDesc{
  margin-bottom: 24px;
}
.ant-list-item-meta-description {
  color: rgba(0,0,0,.45);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px
}

.ant-tag {
  box-sizing: border-box;
  /* color: rgba(0,0,0,.65); */
  color: #000;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  /* white-space: nowrap; */
  word-break: break-all;
  background: #fff;
  text-transform: uppercase;
  /* border: 1px solid #d9d9d9; */
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  transition: all .3s cubic-bezier(.78,.14,.15,.86);
}
/* .ant-tag, .ant-tag a, .ant-tag a:hover {
  color: rgba(0,0,0,.65);
} */

.kpi-image-box .influence-icon{
  width: 17px;
  border-radius: 0;
  margin-left: -14px;
  position: absolute;
  margin-top: 18px;
  object-fit: cover;
  transition: all .25s ease;
}



#subTopicsFilterModal input[type=checkbox] {
  margin: unset !important;
}

#subTopicsFilterModal li .js_subtopics{
  margin-bottom: 4px;
}

.mention-ant-result-title {
  color: rgba(0,0,0,.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.mention-ant-result-subtitle {
  color: rgba(0,0,0,.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}


@media( max-width: 480px){
  .mention-ant-result-title {
    font-size: 18px;
    line-height: 1.4;
  }


  .mention-ant-result-subtitle {
    font-size: 12px;
    line-height: 1.2;
    padding-top: 12px;
  }
  .replyOptions{
    width: 100%;
  }
}