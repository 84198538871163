.pageContainer{
  padding-top: 24px
}

.profile_subHeader{
  font-size: 1.6em;
  font-weight: 400;
  color: #5cb85c;
  margin-bottom: 8px;
}

.influencerBio{
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden!important;
}
.profile_mainSection {
  margin-top: 24px;
  /* background: #fff; */
  padding: 24px;
}
.profile_mainSection:nth-child(2) {
  padding: 24px 0;
}

.subNav_navContainer {
  position: relative;
  -webkit-box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.4);
}

.profile_influencer_details_container {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
/* 
::after{
  background-color: rgba(0, 0, 0, 0.7);position: absolute;top: 0;left: 0; width: 100%;height: 100%;
} */
.profile_influencer_details_box {
  width: 100%;
  color: white;
  z-index: 2;
  position: inherit;
  top: inherit;
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
}

.profile-timerange .navbar-collapse{
  padding: 0px 10px;
}

.subNav_navContainer ul {
  margin: 0 -10px;
  padding-left: 0;
  list-style: none;
}

.wrapper .subNav_navContainer li.subNav_li {
  display: inline-block;
  list-style: none;
}

.wrapper .subNav_navContainer li.subNav_li a {
  padding: 8px 0 5px;
  display: inline-block;
  border-bottom: 5px solid transparent;
  margin: 0 10px;
  text-decoration: none;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.wrapper .subNav_navContainer li.subNav_li a {
  padding: 8px 0 5px;
  display: inline-block;
  border-bottom: 5px solid transparent;
  margin: 0 10px;
  text-decoration: none;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  transition: 0.5s
}

.wrapper .subNav_navContainer li.subNav_li a.selected {
  font-weight: normal;
  font-family: Roboto-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0092ff;
  border-bottom: 5px solid #deeaff;
  transition: 0.5s
}


.wrapper .subNav_navContainer li.subNav_li a.selected {
  font-weight: normal;
  font-family: Roboto-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0092ff;
  border-bottom: 5px solid #deeaff;
  transition: 0.5s
}
.subNav_navLink{
  cursor: pointer;
}

.profile_bgContainer{
  background-color: #eceff1;
}

.subNav_contentContainer, .subNav_navLink, .subNav_navContainer{
  transition: 0.5s
}


.profile_sectionHeader h5 {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
}
.profile-li{
  list-style: none;
  border-bottom: 1px solid #f2ede7;
}


.profile-ul{
  margin: 0;
  padding: 0;
}

.profile-li-box{
  justify-content:space-between;padding:12px 24px;
}

.profile-ul>.profile-li:hover{
  background: #e6f7fe;
}
.profile-ul>.profile-li.no-hover:hover{
  background: unset;
}
.table-hover>tbody>tr.profile-table-tr:hover{
  background-color: #e6f7fe;
}
.table>thead>tr>th.profile-tablehead {
  padding-bottom: 12px !important;
  text-transform: unset;
}
/* .profile-progress .progress{
  min-width: 200px;
}
.profile-progress .progress-bar{
  color: #000;
} */
.profile-tablehead{
  color: rgba(0,0,0,.85) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.hash-show-more, .ref-show-more{
  cursor: pointer;
}

.profile-progress{
  display: flex;
  align-items: center;
}

.progressbar {
  position: relative;
  width: 180px;
  height: 24px;
  margin: 0 auto;
  padding: 0px;
  background-color: #f4f4f4;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  cursor: default;
}

.progressbar-inner {
  width: 80%;
  height: 100%;
  z-index: 10;
  background-color: #007dbb;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.progressbar-inner span, .progressbar-label {
  padding-left: 4px;
  font-size: 12px;
  font-weight: 800;
  font-family: Roboto-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  vertical-align: top;
  white-space: nowrap;
}
.profile-engage-span{
  color:#fff;
  padding:0 10px;
  font-size: 24px;
}

.progressbar-label {
  color: #777;
  z-index: 1;
  width: 95%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 22px;
}


.progressbar-inner, .progressbar-label {
  position: absolute;
  top: 0;
}


.actorListContainer_picture {
  width: 36px;
  height: 36px;
  overflow: hidden;
  margin-right: 6px;
  background-size: cover;
}


.pf-ms{
  display: flex; justify-content: space-between;background:unset
}
.pf-width{
  padding: 5px 0
}
.pf-mr-l{
  margin-left: 12px
}
.pf-mr-r{
  margin-right: 12px
}
.pf-pad{
  padding: 0 24px
}
.pf-mar-t{
      margin-top: 24px;
}
.infl_influencerName{
  margin: 0 0 24px;
}
.navContainerliPadding{
  padding: 24px 40px 0;
}
@media(max-width:480px){
  .navContainerliPadding{
    padding: 0px 27px 0;
  }
.pf-ms{
  display: flex; justify-content: space-between;background:unset;
  flex-direction: column;
  padding: 0;
}
.pf-al-center{
  align-items: center;
}
.infl_influencerName img{
  width: 20px;
  height: 20px;
}
.infl_influencerName{
  display: flex;
  padding-top: 12px;
}
.infl_influencerName h4{
  font-size: 18px;
  line-height: 22px;
}
.pf-width{
  width: 100%
}
.pf-mr-l{
  margin-left: unset;
  margin-bottom: 12px;
}
.pf-mr-r{
  margin-right: unset;
  margin-bottom: 12px;
}

.pf-mr-l.pf-rm-mr-b, .pf-mr-r.pf-rm-mr-b{
  margin-bottom: 0px;  
}
.pf-pad{
  padding: 0
}
.pf-mar-t{
  margin-top: 12px
}
#mobileWidth{
  width: 100px;
}
.profile_mainSection{
  padding: 12px 0
}
}
.colorWhite{
  color: #fff;
}
.profile-page-hover-icon .antd-pro-pages-dashboard-analysis-components-charts-chart-card-index-action{
  color: #fff;
  padding: 0 10px;
}

.subNav_contentContainer .feature-overlay-centered-text{
  margin-top: 20%;
  top: unset;
}
.font-size24{
  font-size: 24px;
}
.justify-end{
  justify-content: flex-end;
}
.flex-align-baseline{
  align-items: baseline;
  display: flex;
}
#createNewInfluencerListMeta{
  height:fit-content;
  margin-left:48px;
}
.detailDataItem{
  display: flex;
  width: 20%;
  justify-content: center;
  margin-bottom: 24px;
}
#influencerDetailData .flex-align-baseline{
  width:100%
}
.campanaly-align-flex-end{
  align-items: flex-end;
}
.detailDataItemHeading{
  color: #d3d3d3;
}
.padding-side24{
  padding: 0 24px;
}
.padding-side18{
  padding: 0 18px;
}
.height100{
  height: 100%;
} 
.modalText{
  padding: 24px;
  text-align: center;
}
#claimedButton{
  cursor:unset;
  text-transform:none;
  color:white;
  opacity:1;
  border:2px solid #0079c0!important
}
#claimedButton img{
  height:30px;
  margin:0 6px 0 0;
  filter:brightness(0) invert(1)
}
@media(max-width: 480px){
  #createNewInfluencerListMeta{
    margin:0;
  }
  .modalText{
    padding: 24px 0;
  }
  .subNav_contentContainer .feature-overlay-centered-text {
    margin-top: 25%;
    top: unset;
    width: 80%
  }
  .padding-side24{
    padding: 0;
  }
  .padding-side18{
    padding: 0;
  }
  .detailDataItem{
    width:100%;
    margin-bottom: 0;
    padding: 6px 0;
  }
  #influencerDetailData .flex-align-baseline{
    width:auto
  }
  .campanaly-align-flex-end{
    align-items: unset;
  }
  .influencerProfileTimeRange{
    vertical-align: middle;
  }
  .influencerProfileTimeRange>div{
    padding: 8px 0 5px;
    display: inline-block;
    border-bottom: 5px solid transparent;
    margin: 0px 10px;
    text-decoration: none;
    color: #666;
    text-transform: uppercase;
    letter-spacing: .08em;
    transition: .5s;
  }
  .postStatisticsPadding{
    padding: 0 24px;
  }
  .infl_influencerName{
    margin: 0 0 12px;
  }
}
#shareMention i{
  width: auto;
  margin-right: 10px;
}
#shareCurrentPage{
  position:fixed;
  top:20%;
  right:0;
  z-index:100
}
#shareCurrentPage li{
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#shareCurrentPage li:first-child{
  border-top-left-radius: 4px
}
#shareCurrentPage li:last-child{
  border-bottom-left-radius: 4px
}
#shareCurrentPage i{
  color: white;
  font-size:16px
}
#shareCurrentPage #shareFacebook{
  background-color: #3b5998
}
#shareCurrentPage #shareTwitter{
  background-color: #1da1f2
}
#shareCurrentPage #shareLinkedin{
  background-color: #0077b5
}
#shareCurrentPage #shareMail{
  background-color: #323b43
}
#shareCurrentPage #shareDownload{
  background-color: #ff8226
}
#shareCurrentPage #shareInstagram{
  background-color: #E1306C
}
#shareCurrentPage #shareWhatsapp{
  background-color: #25D366
}
.notable-follower-card{
  max-width: 100px;
  color:#545454;
}
.notable-follower-card>img{
  height: 80px !important;
  width: 80px;
  border-radius: 50%;
}
@media(max-width:480px){
  #shareCurrentPage li{
    flex-grow:1
  }
  #shareCurrentPage i{
    color: white;
    font-size:16px
  }
  #shareCurrentPage{
    position:fixed;
    bottom:0;
    width:100%;
    z-index:100;
    right:auto;
    top:auto;
  }
  #shareCurrentPage li:first-child{
    border-top-left-radius: 0
  }
  #shareCurrentPage li:last-child{
    border-bottom-left-radius: 0
  }
  #profileDiv .margin-bottom12{
    margin-bottom: 6px
  };
}