/* =======================================================
*
* 	Template Style 
*	Edit this section
*
* ======================================================= */

body {
  font-family: "Roboto", Arial, sans-serif;
  line-height: 1.8;
  font-size: 14px;
  background: #fff;
  color: #545454;
  font-weight: 300;
  overflow-x: hidden;
  letter-spacing: 0.0
}

body.fh5co-offcanvas {
  overflow: hidden;
}

a {
  color: #0079c0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover {
  text-decoration: none !important;
  color: #0079c0;
}

p,
ul,
ol {
  margin-bottom: 1.5em;
  font-size: 16px;
  color: #545454;
  font-family: "Roboto", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #212121;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

figure {
  margin-bottom: 2em;
}

::-webkit-selection {
  color: #fcfcfc;
  background: #0079c0;
}

::-moz-selection {
  color: #fcfcfc;
  background: #0079c0;
}

::selection {
  color: #fcfcfc;
  background: #0079c0;
}

#fh5co-header {
  position: relative;
  background-color: #111;
  margin-bottom: 0;
  z-index: 9999999;
}

.fh5co-cover,
.fh5co-hero {
  position: relative;
}

.background-white {
  background: #fff;
}

@media screen and (max-width: 767px) {
  .fh5co-cover,
  .fh5co-hero {
    max-height: 650px;
    display: inherit;
  }
  .home-btn-view-results {
    margin-top: 10px;
  }
}

.requestDemoBtn {
  border-radius: 25px;
}

@media(min-width: 320px) {
  .pricing-table {
    font-size: 12px;
  }
  .pricing-button.btn.btn-primary {
    width: 75px;
    letter-spacing: normal;
    padding: 5px;
    font-size: 11px;
  }
}

@media(min-width: 768px) {
  .pricing-table {
    font-size: 14px;
  }
}

@media(min-width: 1200px) {
  .pricing-table {
    font-size: 16px;
  }
}

.fh5co-cover.fh5co-hero-2,
.fh5co-hero.fh5co-hero-2 {
  height: 600px;
}

.fh5co-cover.fh5co-hero-2 .fh5co-overlay,
.fh5co-hero.fh5co-hero-2 .fh5co-overlay {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
}

.fh5co-overlay {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
}

.fh5co-cover {
  background-size: cover;
  position: relative;
}

@media screen and (max-width: 767px) {
  .fh5co-cover {
    height: 100%;
    padding: 3em 0;
  }
}

.fh5co-cover .btn {
  padding: 1em 2em;
  opacity: .9;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.fh5co-cover.fh5co-cover_2 {
  height: 600px;
}

@media screen and (max-width: 767px) {
  .fh5co-cover.fh5co-cover_2 {
    height: inherit;
    padding: 3em 0;
  }
}

.fh5co-cover .desc {
  top: 20%;
  position: absolute;
  width: 100%;
  margin-top: -100px;
  z-index: 2;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .fh5co-cover .desc {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fh5co-cover .desc h2 {
  color: #fff;
  font-size: 55px;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 60px;
  letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
  .fh5co-cover .desc h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

.fh5co-cover .desc span {
  display: block;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
}

.cloud-learn {
  margin: 2% 0 0 0;
  text-transform: uppercase;
}

/* .learn-cmn {
  background: #2ba6de;
  color: #fff;
  font-size: 1.2rem;
  padding: 8px;
} */

.fh5co-cover .desc .examples {
  font-size: 12px;
}

.fh5co-cover .desc .examples .example-href {
  text-decoration: underline;
}

.fh5co-cover .desc span i {
  color: #DA1212;
}

.fh5co-cover .desc span a {
  color: rgba(255, 255, 255, 0.8);
}

.fh5co-cover .desc span a:hover,
.fh5co-cover .desc span a:focus {
  color: #fff !important;
}

.fh5co-cover .desc span a.fh5co-site-name {
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

/* Superfish Override Menu */

.sf-menu {
  margin: 0 !important;
}

.sf-menu {
  float: right;
}

.sf-menu ul {
  box-shadow: none;
  border: transparent;
  min-width: 12em;
  *width: 12em;
}

.sf-menu a {
  color: rgba(255, 255, 255, 0.7);
  padding: 0.75em .44em !important;
  font-weight: normal;
  border-left: none;
  border-top: none;
  border-top: none;
  text-decoration: none;
  zoom: 1;
  font-size: 15px;
  border-bottom: none !important;
  font-weight: 500;
  cursor: pointer;
}

@media(max-width:1100px) {
  .sf-menu a {
    padding: 0.75em .58em !important;
  }
}

.sf-menu a:hover,
.sf-menu a:focus {
  color: #fff !important;
}

.sf-menu li.active a:hover,
.sf-menu li.active a:focus {
  color: #fff;
}

.sf-menu li,
.sf-menu ul li,
.sf-menu ul ul li,
.sf-menu li:hover,
.sf-menu li.sfHover {
  background: transparent;
}

.sf-menu ul li a,
.sf-menu ul ul li a {
  text-transform: none;
  padding: 0.25em 1em !important;
  letter-spacing: 1px;
}

.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
  color: #fff;
}

.sf-menu ul li:hover,
.sf-menu ul li.sfHover {
  background: transparent;
}

.sf-menu ul li {
  background: transparent;
}

.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em;
}

.sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #ccc;
}

.sf-arrows>li>.sf-with-ul:focus:after,
.sf-arrows>li:hover>.sf-with-ul:after,
.sf-arrows>.sfHover>.sf-with-ul:after {
  border-top-color: #ccc;
}

.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #ccc;
}

.sf-arrows ul li>.sf-with-ul:focus:after,
.sf-arrows ul li:hover>.sf-with-ul:after,
.sf-arrows ul .sfHover>.sf-with-ul:after {
  border-left-color: #ccc;
}

#fh5co-header-section {
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: black;
}

.nav-header {
  margin-top: 10px;
  position: relative;
  float: left;
  width: 100%;
}

.header-credit {
  float: right;
  font-size: 13px;
}

#fh5co-logo {
  z-index: 2;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 900;
  float: left;
  margin-bottom: 0;
  margin-top: 0;
}

#fh5co-logo a {
  position: relative;
}

#fh5co-logo a:after {
  position: absolute;
  content: '';
  top: -5px;
  left: -9px;
  background: #DA1212;
  width: 10px;
  height: 10px;
}

#fh5co-logo span {
  padding: 0;
  margin: 0;
  font-weight: 100;
}

@media screen and (max-width: 767px) {
  #fh5co-logo {
    width: 100%;
    float: none;
    text-align: center;
  }
}

#fh5co-logo a {
  color: #fff;
}

#fh5co-logo a:hover,
#fh5co-logo a:focus {
  color: #fff !important;
}

@media (min-width: 768px) {
  #fh5co-menu-wrap {
    width: 100% !important;
  }
  #fh5co-primary-menu:first-child {
    float: left;
  }
  #fh5co-primary-menu {
    float: right;
  }
}

#fh5co-menu-wrap {
  position: relative;
  z-index: 2;
  display: block;
  float: right;
}

#fh5co-primary-menu>li>ul li.active>a {
  color: #0079c0 !important;
}

#fh5co-primary-menu>li>.sf-with-ul:after {
  border: none !important;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: -5px;
  top: 50%;
  margin-top: -7px;
  content: "\e922";
  color: rgba(255, 255, 255, 0.5);
}

#fh5co-primary-menu>li>ul li {
  position: relative;
}

#fh5co-primary-menu>li>ul li>.sf-with-ul:after {
  border: none !important;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: 10px;
  top: 20px;
  font-size: 16px;
  content: "\e924";
  color: rgba(0, 0, 0, 0.5);
}

#fh5co-primary-menu .fh5co-sub-menu {
  padding: 10px 7px 10px;
  background: #fff;
  left: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

#fh5co-primary-menu .fh5co-sub-menu>li>.fh5co-sub-menu {
  left: 100%;
  min-width: 14em;
}

#fh5co-primary-menu .fh5co-sub-menu:before {
  position: absolute;
  top: -9px;
  left: 20px;
  width: 0;
  height: 0;
  content: '';
}

#fh5co-primary-menu .fh5co-sub-menu:after {
  position: absolute;
  top: -8px;
  left: 30px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  content: '';
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:before {
  top: 6px;
  left: 100%;
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:after {
  top: 7px;
  right: 100%;
  border: none !important;
}

.site-header.has-image #primary-menu .sub-menu {
  border-color: #ebebeb;
  -webkit-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -ms-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
}

.site-header.has-image #primary-menu .sub-menu:before {
  display: none;
}

#fh5co-primary-menu .fh5co-sub-menu a {
  letter-spacing: 0;
  padding: 0 15px;
  font-size: 14px;
  line-height: 34px;
  color: #101010 !important;
  text-transform: none;
  background: none;
}

#fh5co-primary-menu .fh5co-sub-menu a:hover {
  color: #0079c0 !important;
}

#fh5co-programs-section,
#fh5co-schedule-section,
#fh5co-team-section,
#fh5co-blog-section {
  padding: 4em 0;
}

@media screen and (max-width: 767px) {
  #fh5co-programs-section,
  #fh5co-schedule-section,
  #fh5co-team-section,
  #fh5co-blog-section {
    padding: 2em 0;
  }
}

.fh5co-lightgray-section {   
  background: rgba(0, 0, 0, 0.04);
}

.heading-section {
  margin-bottom: 4em;
}

.heading-section h2 {
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  font-weight: 900;
}

.program {
  margin-bottom: 4em;
}

.program img {
  color: red;
  width: 270px;
  height: 180px;
  margin-bottom: 10px;
}

.program h3 {
  font-size: 20px;
}

.program.program-schedule {
  margin-bottom: 2em;
  padding: 40px;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.program.program-schedule img {
  width: 40px !important;
}

.program.program-schedule h3 {
  margin-bottom: 0;
}

.program.program-schedule small {
  display: block;
  font-size: 12px;
}

.program.program-schedule:hover,
.program.program-schedule:focus {
  -webkit-box-shadow: 0px 6px 55px -23px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px 6px 55px -23px rgba(0, 0, 0, 0.72);
  box-shadow: 0px 6px 55px -23px rgba(0, 0, 0, 0.72);
}

.schedule {
  padding: 0;
  margin: 0 0 40px 0;
}

.schedule li {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-size: 16px;
}

.schedule li a {
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 20px;
  cursor: pointer;
}

.schedule li a:active,
.schedule li a:focus {
  text-decoration: none;
}

.schedule li a.active {
  background: #0079c0;
  color: #fff !important;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

@media screen and (max-width: 767px) {
  .schedule li {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.schedule-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.schedule-content.active {
  opacity: 1;
  visibility: visible;
}

.fh5co-table,
.fh5co-table-cell,
.fh5co-parallax {
  height: 500px;
}

.fh5co-parallax {
  background-color: #555;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.fh5co-parallax .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: .2;
}

@media screen and (max-width: 767px) {
  .fh5co-parallax {
    height: inherit;
    padding: 4em 0;
  }
}

.fh5co-parallax .fh5co-intro h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 300;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .fh5co-parallax .fh5co-intro h1 {
    font-size: 30px;
  }
}

.fh5co-parallax .fh5co-intro p {
  color: #fff;
}

.fh5co-parallax .fh5co-intro p a {
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.fh5co-parallax .fh5co-intro p a:hover {
  color: #fff;
}

.fh5co-parallax .fh5co-intro.box-area {
  padding: 40px;
  background: #222831;
}

.fh5co-table {
  display: table;
}

.fh5co-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.fh5co-pricing {
  padding: 4em 0;
}

@media screen and (max-width: 767px) {
  .fh5co-pricing {
    padding: 2em 0;
  }
}

.fh5co-pricing .pricing {
  display: block;
  float: left;
  margin-bottom: 30px;
}

.fh5co-pricing .price-box {
  text-align: center;
  padding: 24px;
  background: #fff;
  height: 100%;
  color: rgb(26, 16, 16);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 0;
  position: relative;
  max-height: 160vh;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.price-box .startingWith {
  font-size: 12px;
  margin-bottom: -5px;
  text-align: left;
  color: #000;
}

.price-plan-include-headings {
  margin-bottom: 10px
}

.plan-include-div strong {
  font-size: 18px;
  line-height: 1;
}

.price-plan-include-icons {
  margin-right: 15px;
  width: 15%;
  max-width: 34px;
}

.pricing-faq-ques {
  font-size: 20px;
  margin-top: 1.2rem;
  margin-bottom: 10px;
  color: #000;
}

.pricing-faq-ans {
  font-size: 14px;
  color: #000;
}

.plan-include-div p {
  font-size: 15px;
  color: #000;
  line-height: 1.3;
}

.plan-include-div i {
  color: #0079c0;
  font-size: 34px;
}

.save-on-annually {
  margin-bottom: 0;
  font-size: 14px;
  background: #0079c0;
  padding: 2px 10px;
  color: #fff;
  border-radius: 4px;
  cursor: default;
}

/* .price-box .price span {
  text-decoration: line-through
} */

.fh5co-pricing .price-box.popular .btn-select-plan {
  background: #0079c0;
}

.fh5co-pricing .price-box.popular .price {
  color: #0079c0;
}

.fh5co-pricing .price-box .btn-select-plan {
  padding: 10px 20px;
  background: #5bc0de;
  color: #fff;
}

.fh5co-pricing .price-box .classes {
  padding: 0;
}

.fh5co-pricing .price-box .classes li {
  display: block;
  list-style: none;
  margin: 0;
  font-size: 12px;
  padding: 8px 10px;
}

.fh5co-pricing .price-box .classes li.color {
  background: rgba(0, 0, 0, 0.04);
}

.plan-card__features__heading {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #CCC;
  margin-bottom: 16px;
}

@media (min-width: 62em) {
  .plan-card__features__list {
    padding-left: 22.5px;
  }
}

@media (min-width: 34em) {
  .plan-card__features__list {
    padding-left: 37.5px;
  }
}

.price-box-divs {
  padding: 6px;
  background: #fff;
  margin-bottom: 24px;
}

.plan-card__features__list {
  padding-right: 7.5px;
  padding-left: 22.5px;
  margin-bottom: 0;
  text-align: left;
  color: gray;
}

.plan-card__features__list li {
  margin-bottom: 12px;
  font-size: 13px;
  color: #000;
  font-weight: 400;
}

.plan-card__features__list li:before {
  content: '✓';
  color: #0079c0;
  padding-right: 15px;
}

.fh5co-pricing .pricing-plan {
  /* margin: 0 0 40px 0; */
  padding: 0;
  font-size: 14px;
  letter-spacing: 2px;
  height: 28px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;
}

.fh5co-pricing .pricing-plan.pricing-plan-offer {
  margin-bottom: 24px;
}

.fh5co-pricing .pricing-plan span {
  display: block;
  /* margin-top: 10px; */
  margin-bottom: 0;
  color: #d5d8db;
}

.pricingdiscountPercentage {
  margin-bottom: 0;
  font-size: 13px;
  margin: 0 10%;
  background: #ee7300;
  line-height: 30px;
  font-weight: 600;
  color: white;
  border-radius: 30px;
}

.pricingpara {
  font-size: 14px;
  color: #0079c0;
  font-weight: 600;
  margin-bottom: 0;
  padding-top: 24px
  /* color: #000; */
  /* min-height: 100px; */
  /* font-weight: 400; */
}


.fh5co-pricing .price {
  font-size: 44px;
  line-height: 50px;
}

.fh5co-pricing .price .currency {
  font-size: 12px;
  top: -2em;
  padding-right: 10px;
}

.fh5co-pricing .price sup.currency-cut {
  top: -.3em;
  font-size: 10px;
  padding-right: 2px;
}

.fh5co-pricing .price sub.currency-cut {
  top: -.3em;
  font-size: 10px;
  padding-left: 2px;
}

.price strike {
  color: red;
}

.price strike span {
  color: black;
  font-size: 14px
}

.fh5co-pricing .price .onwards {
  font-size: 30px;
  top: 0;
  padding-right: 10px;
}

.fh5co-pricing .price small {
  font-size: 13px;
  display: block;
  text-transform: uppercase;
  color: #000;
}

.team-section-grid {
  position: relative;
  background-size: cover;
  height: 450px;
  margin-bottom: 30px;
  overflow: hidden;
}

.team-section-grid .overlay-section {
  position: absolute;
  top: 0;
  bottom: -450px;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.team-section-grid .overlay-section h3 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.team-section-grid .overlay-section span {
  display: block;
  margin-bottom: 15px;
}

.team-section-grid .overlay-section p {
  color: rgba(255, 255, 255, 0.7);
}

.team-section-grid .overlay-section p.fh5co-social-icons a:hover,
.team-section-grid .overlay-section p.fh5co-social-icons a:focus {
  text-decoration: none !important;
}

.team-section-grid .overlay-section p.fh5co-social-icons i {
  font-size: 40px;
  color: #fff;
}

.team-section-grid .overlay-section span {
  color: #fff;
  display: block;
}

.team-section-grid .overlay-section .desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
}

.team-section-grid:hover .overlay-section {
  bottom: 0;
  opacity: 1;
}

.about {
  padding-bottom: 5em;
}

.about img {
  margin-bottom: 50px;
}

.section-title {
  font-weight: 900;
  text-transform: uppercase;
}

.fh5co-blog {
  margin-bottom: 50px;
  position: relative;
}

.fh5co-blog .inner-post {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100px;
  height: 75px;
}

.flexDisplay {
  display: flex;
  align-items: flex-start;
}

.fh5co-blog .inner-post img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .fh5co-blog .inner-post {
    width: 100%;
    position: relative !important;
    height: inherit !important;
    margin-bottom: 30px;
  }
}

.fh5co-blog .desc {
  padding-left: 110px;
}

@media screen and (max-width: 767px) {
  .fh5co-blog .desc {
    padding-left: 0;
  }
  .fh5co-blog .desc h3 {
    font-size: 14px;
  }
  .fh5co-blog .desc span {
    font-size: 14px;
  }
  .blogtext p {
    font-size: 12px;
  }
}

.fh5co-blog .desc h3 {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 32px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.fh5co-blog .desc h3 a {
  color: rgba(0, 0, 0, 0.7);
}

.fh5co-blog .desc span {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 20px;
}

.fh5co-blog .desc.desc2 {
  padding-left: 100px;
}

@media screen and (max-width: 767px) {
  .fh5co-blog .desc.desc2 {
    padding-left: 0;
  }
}

.fh5co-blog .meta-date {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  background: #4CB648;
}

@media screen and (max-width: 767px) {
  .fh5co-blog .meta-date {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
}

.fh5co-blog .meta-date p {
  margin: 0;
  display: block;
  color: #fff;
}

.fh5co-blog .meta-date p span {
  display: block;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .fh5co-blog .meta-date p span {
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
}

.fh5co-blog .meta-date p span.date {
  font-size: 30px;
  font-weight: 900;
  line-height: 28px;
}

@media screen and (max-width: 767px) {
  .fh5co-blog .meta-date p span.date {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-right: 10px;
    margin-top: 8px;
  }
}

#map {
  width: 100%;
  height: 500px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #map {
    height: 200px;
  }
}

.fh5co-social-icons>a {
  padding: 4px 3px;
  color: #4CB648;
}

.fh5co-footer-social-icons>a {
  padding: 4px 3px;
  color: #fff;
}

.fh5co-footer-social-icons>a i {
  font-size: 35px;
}

.fh5co-footer-social-icons>a i:hover,
.fh5co-footer-social-icons>a i:focus,
.fh5co-footer-social-icons>a i:active {
  text-decoration: none;
  color: #0079c0;
}

.fh5co-social-icons>a i {
  font-size: 26px;
}

.fh5co-social-icons>a:hover,
.fh5co-social-icons>a:focus,
.fh5co-social-icons>a:active {
  text-decoration: none;
  color: #212121;
}

#footer {
  background: #222831;
  padding: 60px 0 40px;
}

#footer .section-title {
  color: rgba(255, 255, 255, 0.8);
}

#footer .contact-info {
  margin: 0 0 60px 0;
  padding: 0;
}

#footer .contact-info li {
  list-style: none;
  margin: 0 0 20px 0;
  position: relative;
}

#footer .contact-info li i {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

#footer .contact-info li a {
  color: rgba(255, 255, 255, 0.6);
}

#footer .contact-form .form-group input[type="name"],
#footer .contact-form .form-group input[type="text"],
#footer .contact-form .form-group input[type="email"],
#footer .contact-form .form-group textarea {
  font-size: 16px;
}

#footer .contact-form .form-group input[type="name"]::-webkit-input-placeholder,
#footer .contact-form .form-group input[type="text"]::-webkit-input-placeholder,
#footer .contact-form .form-group input[type="email"]::-webkit-input-placeholder,
#footer .contact-form .form-group textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#footer .contact-form .form-group input[type="name"]:-moz-placeholder,
#footer .contact-form .form-group input[type="text"]:-moz-placeholder,
#footer .contact-form .form-group input[type="email"]:-moz-placeholder,
#footer .contact-form .form-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}

#footer .contact-form .form-group input[type="name"]::-moz-placeholder,
#footer .contact-form .form-group input[type="text"]::-moz-placeholder,
#footer .contact-form .form-group input[type="email"]::-moz-placeholder,
#footer .contact-form .form-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}

#footer .contact-form .form-group input[type="name"]:-ms-input-placeholder,
#footer .contact-form .form-group input[type="text"]:-ms-input-placeholder,
#footer .contact-form .form-group input[type="email"]:-ms-input-placeholder,
#footer .contact-form .form-group textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

#footer .contact-form .form-group input {
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

#footer .contact-form .form-group textarea {
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border: none;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

#footer #btn-submit {
  color: rgba(255, 255, 255, 0.9) !important;
  background: #4CB648 !important;
}

#footer .copy-right {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 40px;
  margin-top: 20px;
}

#footer .copy-right p {
  color: rgba(255, 255, 255, 0.3);
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
}

#footer .copy-right p a {
  color: rgba(255, 255, 255, 0.5);
}

.btn {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn.btn-primary {
  color: #fff;
  background-color: #0079c0;
  border: none !important;
  border: 2px solid transparent !important;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  box-shadow: none;
  color: #fff !important;
}

/* 
.btn:hover,
.btn:active,
.btn:focus {
  background: #0079c0;
  color: #fff !important;
  outline: none !important;
} */

.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active {
  border-color: transparent;
}

.js .animate-box {
  opacity: 0;
}

.btn.btn-primary.facebook {
  background-color: #3e58a4
}

.btn.btn-primary.google {
  background-color: #e05343
}

#fh5co-wrapper {
  /* overflow-x: hidden; */
  position: relative;
}

.fh5co-offcanvas {
  overflow: hidden;
}

.offcanvas-menu-hidden {
  display: none;
}

.fh5co-offcanvas #fh5co-wrapper {
  overflow: hidden !important;
}

#fh5co-page {
  position: relative;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.fh5co-offcanvas #fh5co-page {
  -moz-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -o-transform: translateX(-240px);
  transform: translateX(-240px);
}

@media screen and (max-width: 767px) {
  .form-control {
    margin: 0;
    width: 100%;
  }
  #fh5co-menu-wrap {
    display: none;
  }
  #offcanvas-menu ul li .subheadingCaseStudies {
    padding-left: 15px
  }
  /* .navigatetolink {
    text-align: right;
  } */
}

.navigatetolink {
  display: flex;
}

.navigatetolink a:first-child {
  flex-grow: 1
}

#offcanvas-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: #222;
  right: -240px;
  width: 240px;
  padding: 20px;
  overflow-y: scroll !important;
}

@media (min-width:961px) {
  #offcanvas-menu {
    display: none;
  }
}

#offcanvas-menu ul {
  padding: 0;
  margin: 0;
}

#offcanvas-menu ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}

#offcanvas-menu ul li a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

#offcanvas-menu ul li a:hover {
  color: #4CB648;
}

#offcanvas-menu ul li>.fh5co-sub-menu {
  margin-top: 9px;
  padding-left: 20px;
}

#offcanvas-menu ul li.active>a {
  color: #4CB648;
}

#offcanvas-menu ul .fh5co-sub-menu {
  display: block !important;
}

.fh5co-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}

.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #fff;
}

.fh5co-nav-toggle.dark.active i::before,
.fh5co-nav-toggle.dark.active i::after {
  background: #fff;
}

.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

.fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 30px;
  height: 2px;
  color: #fff;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  transition: all .2s ease-out;
}

.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: '';
  width: 30px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fh5co-nav-toggle.dark i {
  position: relative;
  color: #000;
  background: #000;
  transition: all .2s ease-out;
}

.fh5co-nav-toggle.dark i::before,
.fh5co-nav-toggle.dark i::after {
  background: #000;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999;
  display: block;
  margin: 0 auto;
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .fh5co-nav-toggle {
    display: block;
    top: 10px;
    margin-right: 10px
  }
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.row-bottom-padded-lg {
  padding-bottom: 7em;
}

@media screen and (max-width: 767px) {
  .row-bottom-padded-lg {
    padding-bottom: 2em;
  }
}

.row-top-padded-lg {
  padding-top: 7em;
}

@media screen and (max-width: 767px) {
  .row-top-padded-lg {
    padding-top: 2em;
  }
}

.row-bottom-padded-md {
  padding-bottom: 4em;
}

@media screen and (max-width: 767px) {
  .row-bottom-padded-md {
    padding-bottom: 2em;
  }
}

.row-top-padded-md {
  padding-top: 4em;
}

@media screen and (max-width: 767px) {
  .row-top-padded-md {
    padding-top: 2em;
  }
}

.row-bottom-padded-sm {
  padding-bottom: 2em;
}

@media screen and (max-width: 767px) {
  .row-bottom-padded-sm {
    padding-bottom: 2em;
  }
}

.row-top-padded-sm {
  padding-top: 2em;
}

@media screen and (max-width: 767px) {
  .row-top-padded-sm {
    padding-top: 2em;
  }
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.lead {
  line-height: 1.8;
}

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.fh5co-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../images/hourglass.svg) center no-repeat #222222;
}

.sourcecards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sourcecards img {
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.3);
  max-width: 25vw;
}

#feedback {
  float: left;
  position: fixed;
  top: calc(90% - 47px);
  right: 0;
  z-index: 2000;
}

#feedback a {
  background: transparent;
  border-radius: 5px 0 0 5px;
  border: 3px solid transparent;
  border-right: 0;
  display: block;
  padding: 20px 12px;
  transition: all .2s ease-in-out;
}

#feedback a:hover {
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .desktop {
    display: none;
  }
  .videoheading {
    margin-top: 20px;
    margin-bottom: 30px
  }
}

@media only screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
  .videoheading {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .sidebyside {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  #leftbox {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin-top: 0;
  }
  #rightbox {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin-top: 0;
  }
  #rightbox p {
    font-size: 13px;
  }
  .itemstyle {
    height: 100%;
    margin: 10px;
    padding: 10px;
    width: 100%
  }
}

.rounded-box {
  border: #4CB648;
  border-width: 1px;
  border-style: solid;
  padding: 8px;
  border-radius: 25px;
  color: whitesmoke;
}

.middle-align-items {
  display: flex;
  flex-direction: column;
  height: 360px;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .aligned {
    display: flex;
    flex-flow: row wrap;
  }
  #leftbox {
    float: left;
    /* padding-left: 60px; */
    vertical-align: middle;
    margin-top: 0;
  }
  #rightbox {
    float: right;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    margin-top: 0;
  }
  #rightbox p {
    font-size: larger;
    font-weight: bolder;
    height: 100%;
    vertical-align: middle;
    padding-right: 60px;
    /* padding-left: 60px; */
  }
  #rightbox #data {
    align-self: center;
  }
  .itemstyle {
    width: 100%;
  }
  /* #leftbox>.img-thumbnail,
  #rightbox>.img-thumbnail {
    width: 65%;
  } */
  .whatyou {
    margin-top: 3%;
  }
  #data h4 {
    font-size: 24px;
    /* margin-top: 10px; */
  }
  #data span {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
  }
  whatyou .img-thumbnail {
    border: none;
    padding: 0 15%;
  }
}

.carousel-control.left {
  background-image: none;
}

.carousel-control.right {
  background-image: none;
}

.img-thumbnail {
  border: none;
  padding: 0 15%;
}

.monitor-provided-img {
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 24px 0;
}

.column-content-discovery {
  float: left;
  width: 50%;
  padding: 0px;
}


.whatmain {
  height: 70vh;
}

.carousel-indicators-whatyouget li {
  border-color: #4CB648;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 20%;
  top: 40%;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 20%;
  top: 40%;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: lightcyan;
}

.container.industry-hero {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */

.bottom-left {
  position: absolute;
  top: 3%;
  padding-right: 50%;
  padding-left: 25px;
}

.label-industry {
  background-color: aquamarine;
  padding: 10px;
}

.card-industry {
  background-image: url("/images/trainer-4.jpg");
  background-repeat: no-repeat;
  padding: 30px;
}

.card-industry.item {
  background-image: url("/images/trainer-4.jpg");
  background-repeat: no-repeat;
  padding: 30px;
  margin: 10px;
}

.industry .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.industry .row>[class*='col-'] {
  display: flex;
  flex-direction: column;
}

.desc-card-industry p {
  color: whitesmoke;
  padding: 25px;
  font-size: 16px;
  font-weight: inherit
}

.industry.fh5co-table {
  height: 300px;
}

.fh5co-table-cell.industry {
  height: 300px;
}

.overlay-section1 {
  padding: 40px;
  color: white;
  background: radial-gradient(circle, #286090, #222831);
  height: 100%;
}

.overlay-section1.lightback {
  background: linear-gradient(rgba(21, 21, 21, 0.5), rgba(21, 21, 21, 0.5));
}

.overlay-section1 .desc1 h3 {
  color: white;
}

.overlay-section1 .desc1 p {
  color: white;
}

.team-section-grid1 {
  position: relative;
  background-size: cover;
  height: 450px;
  margin-bottom: 30px;
  overflow: hidden;
}

.team-section-grid1 .overlay-section {
  position: absolute;
  top: 0;
  bottom: -450px;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.team-section-grid1 .overlay-section h3 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.team-section-grid1 .overlay-section span {
  display: block;
  margin-bottom: 15px;
}

.team-section-grid1 .overlay-section p {
  color: rgba(255, 255, 255, 0.7);
}

.team-section-grid1 .overlay-section p.fh5co-social-icons a:hover,
.team-section-grid1 .overlay-section p.fh5co-social-icons a:focus {
  text-decoration: none !important;
}

.team-section-grid1 .overlay-section p.fh5co-social-icons i {
  font-size: 40px;
  color: #fff;
}

.team-section-grid1 .overlay-section span {
  color: #fff;
  display: block;
}

.team-section-grid1 .overlay-section .desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
}

.team-section-grid1:hover .overlay-section {
  bottom: 0;
  opacity: 1;
}

.team-section-grid .overlay-section-services .desc {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
}

.team-section-grid:hover .overlay-section-services {
  bottom: 0;
  opacity: 1;
}

.team-section-grid .heading {
  margin-top: 50px;
  margin-left: 10px
}

.team-section-grid .heading h3 {
  color: yellow
}

.contactusMap {
  width: 100%;
  height: 200px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  /* padding-top: 25px; */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.social-icon {
  display: inline-block;
  width: 33px;
  height: 33px;
  border: 0px solid#000000;
  background-image: url(images/sprite.png);
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-left: 2px;
}

.social-icon:hover {
  cursor: pointer;
}

.social-icon a:hover {
  background-color: blue;
}

.tw {
  background-position: -38px -0px;
}

.fb {
  background-position: -2px -1px;
}

.linki {
  background-position: -145px -1px;
}

.gp {
  background-position: -218px -1px;
}

.yt {
  background-position: -74px -1px;
}

.insta {
  background-position: -110px -1px;
}

.quora {
  background-position: -183px -1px;
}

.tm-header {
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#tm-slider {
  margin-bottom: 30px;
}

.tm-holder {
  border: 0px solid#000000;
  border-radius: 10px;
  /* background-color: #999; */
  text-align: center;
}

.text-white {
  color: white;
  margin-bottom: 10px;
}

bq {
  quotes: "\201C""\201D""\2018""\2019";
}

bq:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  vertical-align: -0.4em;
}

ul.headstyle-ul {
  list-style: none;
  text-align: left;
  padding-left: 15%;
}

ul.headstyle-ul li {
  display: block;
  margin-bottom: .5em;
  line-height: 1;
  font-size: 18px;
}

.headstyle {
  color: #d3d4d6;
  text-align: left;
  padding-left: 15%;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto, Arial, sans-serif;
  margin-bottom: 10px;
}

ul.headstyle-ul li .gi {
  color: #d3d4d6;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: .4px;
}

.testimonial {
  height: 400px;
}

.testimonial img {
  height: 100px;
}

.partner .overlay-section1 .desc-card-industry p {
  color: black;
}

.partner .team-section-grid1 {
  overflow: visible;
}

@media screen and (max-width:768px) {
  .nettyfeed {
    width: 225px !important;
    height: 96px !important;
    margin-left: 0px;
    margin-top: 10px;
  }
  .scoopearth {
    margin-top: 15px;
  }
}

@media screen and (min-width:1200px) {
  .customplan {
    padding-top: 10px;
  }
}

@media screen and (min-width:768px) {
  .scoopearth {
    margin-top: 25px;
  }
  .nettyfeed {
    width: 250px !important;
    height: 105px !important;
    margin-left: 0px;
    margin-top: 30px;
  }
}

.blogs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mapaddr {
  background: #0079c0;
  padding: 22px 24px;
  color: #fff;
  margin: 24px;
}

.mapaddr h3 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 5px;
  color: #fff;
}

.mapaddr p {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 18px;
  color: #fff;
}

.mapaddr p span {
  display: block;
  /* padding-left: 30px; */
  position: relative;
  color: #fff;
  margin-bottom: 8px;
}

.mapaddr p a {
  color: #fff;
}

.mapaddr p span a:hover {
  color: #fff !important;
}

/*
.blogs
{
  display: inline-block;
  width: 50%;
}
*/

.blogtext p {
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  position: relative;
  word-break: break-all;
}

.grecaptcha-badge {
  display: none;
}

#newsletterform .form-group>div {
  display: inline-block;
}

#email+div {
  display: inline-block;
}

/* .customer-testimonial { */

/* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); */

/* border: solid 1px #dddddd; */

/* background: white; */

/* margin-bottom: 20px; */

/* } */

.customer-testimonial p.quote {
  font-size: 24px;
  line-height: 1.3em;
  padding: 24px 32px 24px 40px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

/* .customer-testimonial p.quote:before {
    content: "“";
    position: absolute;
    opacity: 0.5;
    font-weight: 600;
    z-index: -1;
    font-size: 56px;
    margin-top: 5px;
    left: 6px;
    color: #ff8400;
}

.customer-testimonial p.quote:after {
    content: "”";
    position: absolute;
    display: inline-block;
    opacity: 0.5;
    font-weight: 600;
    z-index: -1;
    font-size: 56px;
    margin-top: 22px;
    line-height: 1px;
    color: #ff8400;
} */

.customer-testimonial .attribution {
  padding: 0 24px;
  /* background: #f9f9f9; */
  /* border-top: solid 1px #dddddd; */
  display: flex;
  vertical-align: middle;
  flex-direction: row-reverse;
  align-items: center;
}

.quotetext {
  min-height: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  padding: 24px;
  letter-spacing: .4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quotetext span {
  padding: 0 24px;
}

@media(max-width:480px) {
  .quotetext {
    min-height: 300px;
    padding: 0
  }
  .customer-testimonial .attribution {
    padding: 0
  }
}

.customer-testimonial .attribution .photo {
  margin: 0 12px;
}

.customer-testimonial .attribution .photo img {
  border-radius: 50%;
  display: block;
}

/* .customer-testimonial .attribution .citation {
} */

.citation .name {
  font-weight: bold;
  font-size: 18px;
  text-align: right;
}

.citation .title {
  line-height: 20px;
  font-size: 12px;
  text-align: right;
}

.citation p {
  margin-bottom: 0;
}

.customer-testimonial .attribution .logo {
  float: right;
  height: 50px;
  line-height: 48px;
}

.customer-testimonial .attribution .logo img {
  vertical-align: middle;
  max-width: 100px;
  max-height: 40px;
}

#data a h4:hover,
#data a p:hover,
#data a img:hover {
  color: #0079c0
}

.hoverblue {
  color: #212121;
  text-decoration: none;
}

.hoverblue:hover,
.hoverblue:active,
.hoverblue:focus {
  color: #0079c0
}

@media screen and (max-width:768px) {
  .whatuget>.glyphicon-chevron-left,
  .whatuget>.icon-prev {
    left: 20%;
    top: 15vh !important;
  }
  #data h4 {
    font-size: 22px;
    margin: 5% 0 2% 0;
  }
  #data span {
    font-size: 16px;
    text-align: center;
    color: #545454;
  }
  .whatuget>.glyphicon-chevron-right,
  .whatuget>.icon-next {
    right: 20%;
    top: 15vh !important;
  }
  .whatmain {
    height: 50vh;
  }
}

.whatuget>.glyphicon-chevron-left,
.whatuget>.icon-prev {
  left: 20%;
  top: 20vh;
}

.whatuget>.glyphicon-chevron-right,
.whatuget>.icon-next {
  right: 20%;
  top: 20vh;
}

@media screen and (min-width:900px) {
  .lastprice {
    margin-bottom: 75vh;
  }
}

@media screen and (max-width:900px) {
  .whatuget>.glyphicon-chevron-left,
  .whatuget>.icon-prev {
    left: 20%;
    top: 25vh;
  }
  .whatuget>.glyphicon-chevron-right,
  .whatuget>.icon-next {
    right: 20%;
    top: 25vh;
  }
}

.alert {
  padding: 20px;
  background-color: #2196F3;
  color: white;
  width: 30%;
  border-radius: 0px;
  left: 0;
  float: left;
  position: fixed;
  top: calc(78% - -5px);
  z-index: 2000;
}

.alert.alert-warning{
    background-color:#ffbc67;
    color: black;
    width:50%;
}
@media(max-width:768px){
  .alert.alert-warning{
      width:90%
  }
}
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

/* *************************for \login*********************************  */

@media screen and (min-width:1200px) {
  .btn-social {
    min-width: 22%
  }
}

.forgot {
  vertical-align: bottom;
  color: #0079c0;
}

.forgot:focus,
.forgot:active,
.forgot:hover {
  color: #0079c0;
}

.userlogin {
  margin-top: 10px
}

.userlogin input,
.userlogin input:active,
.userlogin input:focus {
  border: 1px solid #0079c0;
}

/* ==================usecase======================== */

.usecase-w3-card-3 {
  display: inline-block;
  position: relative;
  width: 250px;
  margin: 12px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
  padding: 10px;
}

.usecase-w3-card-3 p {
  font-weight: 400;
  color: #000;
  padding: 10px 0;
  margin: 0;
}

.usecaseImageContainer {
  width: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: round !important;
  height: 180px;
}

/* *********************signup modal********************** */

#verifyotp,
#verifyemail,
#customDatePickerModal {
  top: 10% !important;
}


.login-email::placeholder {
  font-size: 14px;
  color: #999999;
  vertical-align: middle;
}

.signup-input{
  width:50%;display:inline-block
}

@media only screen and (min-width: 769px) {
  .subscribeemail {
    text-align: right
  }
  .subscribebtn {
    text-align: left;
  }
}

@media only screen and (max-width:768px) {
  .signup-input{
    width:75%;
  }
  
}

@media only screen and (max-width:480px) {
  .mobilecontact {
    margin-left: 20px;
    margin-right: 20px;
    width: 80%;
  }
  .subscribeinbox {
    margin: 2% 0;
  }
}

.rankmeproducts {
  padding: 20px 10px;
  background: rgba(0, 0, 0, 0.04);
  text-align: center;
  min-height: 150px;
}

.rankmeproducts p {
  font-size: 18px;
}

/* ***************for trusted all over world************************************ */

.rank-ui-map-light {
  text-align: center;
  position: relative;
  min-height: 680px;
  padding: 2em 0 40px 0;
  /* background: #f5f7f9; */
}

.rank-ui-map-light__map {
  background: #f5f7f9 url(/images/services/worldmap.png) no-repeat center center;
  width: 1200px;
  margin: 0 auto;
  min-height: 610px;
  position: relative;
  background-size: contain;
}

@media only screen and (min-width: 1200px) {
  .rank-ui-map-light__map {
    width: 100%;
  }
}

.rank-ui-map-light__subtitle {
  font-size: 16px;
  color: #829ca9;
  margin: 0 auto;
  line-height: 28px;
}

.rank-ui-map-light__flash-container {
  width: 400px;
  height: 400px;
  position: absolute;
}

.rank-ui-map-light__flash-container.m-explode .rank-ui-map-light__flash-wave {
  width: 96px;
  height: 96px;
  opacity: 0;
  top: 182px;
  left: 182px;
  transition: all 3s ease-in-out;
}

.rank-ui-map-light__flash-container.m-explode .rank-ui-map-light__flash {
  width: 41px;
  height: 41px;
  top: 182px;
  left: 182px;
}

.rank-ui-map-light__flash-container.m-explode .rank-ui-map-light__amount {
  opacity: 1;
  transition: 3s ease-in-out;
  width: 80%;
  height: 15%;
}

.rank-ui-map-light__flash-container.m-explode .rank-ui-map-light__time {
  opacity: 1;
  transition: 3s ease-in-out;
}

.rank-ui-map-light__flash-container.m-explode .rank-ui-map-light__flash-v-spike {
  opacity: 0;
  transition: 3s ease-in-out;
  height: 100px;
  top: 150px;
}

.rank-ui-map-light__flash-container.m-explode .rank-ui-map-light__flash-h-spike {
  opacity: 0;
  transition: 3s ease-in-out;
  width: 100px;
  left: 150px;
}

.rank-ui-map-light__flash-container.m-westeurope {
  top: 65px;
  left: 260px;
}

@media only screen and (min-width: 1200px) {
  .rank-ui-map-light__flash-container.m-westeurope {
    left: 355px;
    top: 60px;
  }
}

.rank-ui-map-light__flash {
  position: absolute;
  width: 0;
  height: 0;
  top: 200px;
  left: 200px;
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iNDFweCIgaGVpZ2h0PSI0MXB4IiB2aWV3Qm94PSIwIDAgNDEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICAgICAgPHRpdGxlPkFydGJvYXJkIDM3PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IkhvbWVwYWdlIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBza2V0Y2g6dHlwZT0iTVNQYWdlIj4gICAgICAgIDxnIGlkPSJBcnRib2FyZC0zNyIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCIgZmlsbD0iIzAwQjlGRiI+ICAgICAgICAgICAgPGNpcmNsZSBpZD0ib3V0bGluZS1jb3B5IiBvcGFjaXR5PSIwLjA3Nzg5MTc5MSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCIgY3g9IjIwLjUiIGN5PSIyMC41IiByPSIyMC41Ij48L2NpcmNsZT4gICAgICAgICAgICA8Y2lyY2xlIGlkPSJvdXRsaW5lIiBvcGFjaXR5PSIwLjMzMzM3MjIwMSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCIgY3g9IjIwLjUiIGN5PSIyMC41IiByPSI3LjUiPjwvY2lyY2xlPiAgICAgICAgICAgIDxjaXJjbGUgaWQ9ImRvdCIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCIgY3g9IjIwLjUiIGN5PSIyMC41IiByPSIxLjUiPjwvY2lyY2xlPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
  background-position: center center;
}

.rank-ui-map-light__time {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  display: block;
  position: absolute;
  left: 220px;
  top: 191px;
  width: auto;
  height: auto;
  background-color: #00b9ff;
  padding: 0 5px;
  opacity: 0;
  border-radius: 5px;
}

.rank-ui-map-light__amount {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  display: block;
  position: absolute;
  left: 220px;
  top: 221px;
  width: auto;
  height: auto;
  background-color: #223049;
  padding: 0 5px;
  opacity: 0;
  border-radius: 5px;
}

.rank-ui-map-light__flash-v-spike {
  width: 4px;
  height: 0;
  top: 200px;
  left: 200px;
  background: radial-gradient(#00b9ff, rgba(0, 0, 0, 0));
  opacity: 0.7;
  position: absolute;
}

.rank-ui-map-light__flash-h-spike {
  height: 4px;
  width: 0;
  top: 200px;
  left: 200px;
  background: radial-gradient(#00b9ff, rgba(0, 0, 0, 0));
  opacity: 0.7;
  position: absolute;
}

.rank-ui-map-light__flags-description {
  color: #e6ebed;
}

.rank-ui-map-light__flags-title {
  color: #e6ebed;
}

.map-flags {
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: #232f49;
  background-color: rgba(35, 47, 73, 0.9);
  padding-top: 40px;
}

.map-flags__title {
  text-align: left;
}

.map-flags__flags {
  padding: 0;
}

.map-flags__flags li {
  margin-bottom: 30px;
}

.map-flag {
  width: 30px;
  height: 23px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1px;
  border: 1px solid rgba(93, 112, 121, 0.5);
}

/* ***************************demo form****************** */

.demoFormMargin {
  margin-top: 24px;
  background: #eee;
  color: #000;
  border-radius: 2px;
  font-size: 16px;
  padding: 10px 50px;
}

.demoFormMargin::placeholder {
  color: #191919;
  font-size: 16px;
}

.demoFormMargin:hover,
.demoFormMargin:focus,
.demoFormMargin:active {
  border-color: #191919;
}

.demoFormBtn {
  width: 50%;
  /* text-align: center; */
  border-radius: 2px;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  padding: 1em 2em;
  opacity: .9;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.demoimageIcon {
  width: 25px;
  position: absolute;
  margin-top: -35px;
  margin-left: 15px;
}

@media screen and (min-width: 768px) {
  #requestDemo_form>.form-group {
    padding: 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .demoimageIcon {
    width: 25px;
    position: absolute;
    margin-top: -35px;
    margin-left: 18px;
  }
  /* .demoFormMargin {
    width: 90% !important;
  } */
}

.admin_makestatus p {
  margin: 0;
  padding: 5px;
  text-align: center;
}

.admin_makestatusButton {
  color: white;
  background: #0079c0;
  padding: 5px;
  border-radius: 5px;
}

.customer-grid {
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
}

#customer_grid {
  padding: 48px
}

.customer-grid .col {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  flex-basis: 33.333%;
  cursor: default;
  border-top: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  padding: 30px 30px 20px;
  flex-shrink: 0;
  flex-grow: 1;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}

.customer-grid .col .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 0;
  height: 0;
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px 0;
  background-color: rgba(58, 66, 85, 0.9);
  color: #fcfcfc;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  overflow: hidden;
}

.customer-grid .col .description p {
  margin-bottom: 10px;
}

.customer-grid .col .description p:last-of-type {
  margin-bottom: 0;
}

.customer-grid .col .description p.customer-link {
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  display: table;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .customer-grid .col:nth-child(3n+3) {
    border-right: 1px solid #e2e2e2;
  }
}

@media (max-width: 768px) {
  .customer-grid .col:nth-child(2n+2) {
    border-right: 1px solid #e2e2e2;
  }
}

@media (max-width: 532px) {
  .customer-grid .col:nth-child(1n+1) {
    border-right: 1px solid #e2e2e2;
  }
}

.subheadingCaseStudies {
  padding-left: 10px;
}

.brands-list__item {
  padding: 0 4%;
  display: inline-block;
  position: relative;
  width: 230px; 
} 

.our-success-brands-list__item {
  padding: 15px;
  display: inline-block;
  position: relative;
}

.brands-list {
  text-align: center;
  padding-top: 20px;
}

.brands-list__item img {
  /* height: 100px; */
  width: 175px;
  background: #fff;
  padding: 5px;
}

.our-success-brands-list__item img {
  /* height: 100px; */
  width: 170px;
  background: #fff;
  padding: 5px;
}

/* .caseStudy-endsection{
  display:flex;
  padding: 24px 0 48px 0;
}
.caseStudy-requestdemo{
  display: flex;
  padding: 15px;
  flex-flow: column-reverse;
}
.caseStudy-requestdiv{
    display: flex;
    flex-flow: row-reverse;
} */

.casestudyImage img {
  width: 60%
}

@media (max-width:768px) {
  .casestudyImage img {
    width: 100%
  }
}

textarea.feedbackmessage {
  resize: none;
}

/* ===============logincontainer==================== */

.AuthPage__Content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  padding: 4rem 0;
  top: 0;
  background: #fff;
  height: 100%;
}

.login-content-holder {
  margin: 24px 20%;
}
@media (max-width:920px) {
  .login-content-holder {
    margin: 12px 15%;
  }
}

@media (max-width:768px) {
  .login-content-holder {
    margin: 12px 10%;
  }
}

.AuthTerms {
  color: rgba(0, 0, 0, 0.35);
  line-height: 1.35;
  font-size: 0.875rem;
}

.AuthPage__Form {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 3rem;
}

/* ----------------------------------------- */

/* ==========================================razorPay========================== */

.payMainContainer p,.payMainContainer span.payhighlight {
  font-size: 14px;
  margin-bottom: 10px
}

.payMainContainer .payhighlight {
  color: #000
}

.payMainContainer {
  display: flex;
  padding: 48px;
  background: #eceff1
}

.payMainContainer span {
  color: #545454;
  font-size: 12px;
}

.pay_firstleft {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 24px;
}

.payPaymentInformation {
  display: flex;
  background: #fff;
  padding: 24px;
}

.flowgrowone {
  flex-grow: 1
}

.pay_firstright {
  padding: 12px 24px;
  background: #fff;
}

.displayflex {
  display: flex;
}

.rpFieldName {
  padding-right: 12px;
}

.payOrderInformation {
  background: #fff;
  padding: 24px;
  margin-top: 10px;
}

.payImagediv {
  justify-content: center;
  display: flex;
  padding: 24px;
  flex-direction: column
}

.divOrderDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.razorpayViewBody {
  text-align: center;
  padding: 24px;
  background: #eceff1;
  border-radius: 5px;
}

.razorpaycontainer ul p {
  font-size: 14px
}

.razorpaycontainer ul span {
  font-size: 14px
}

.pay_orderinfo {
  text-align: right;
}

.razorpaycontainer ul {
  margin-bottom: 0 !important
}

.pay_paymentinfo {
  flex-direction: column;
  text-align: right
}

/* .rp_payinitshowul {
  padding: 12px 20%;
  background: #fff;
} */

.rp_paymentSuccess {
  text-align: center
}

.printInvoicepara a {
  color: #545454
}

@media(max-width:768px) {
  .pay_firstright {
    margin: 24px;
  }
  .payMainContainer {
    padding: 48px 0;
    flex-direction: column;
  }
  .payPaymentInformation {
    flex-direction: column;
  }
  .pay_orderinfo {
    text-align: left;
  }
  .divOrderDiv {
    flex-direction: column;
  }
  .divOrderDiv .displayflex {
    flex-direction: column;
  }
  .payImagediv {
    padding: 0 0 12px 0;
  }
  .pay_paymentinfo {
    text-align: left;
    padding: 12px 0;
  }
  /* .rp_payinitshowul {
    padding: 0;
    background: #fff;
  } */
  .rp_paymentSuccess {
    text-align: left
  }
}

/* =========================.industry pages========================== */

.industry-form-div,
.industry-form-div #industryForm,
.industry_form-width {
  width: 100%
}

.industry-form-div #industryForm input {
  background: #fff;
  margin: 0;
}

input.industry_form-width {
  padding: 5px 10px;
}

.home_industry_report_points p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

/* =========================.webinar pages========================== */

.webinar-mentor-image img {
  border-radius: 100%;
}

.webinar-mentor-image,
.webinar-mentor-details {
  padding: 12px 0;
}

.fh5co-parallax .fh5co-intro .webinar-mentor-details p {
  color: #545454;
  margin-bottom: 0;
  font-weight: 400;
}

.fh5co-parallax .fh5co-intro .webinar-mentor-details-timing p {
  color: #545454;
  margin-bottom: 0;
  font-weight: 600;
}

.webinar-form-div,
.webinar-form-div #webinarForm,
.webinar_form-width {
  width: 100%
}

.webinar-form-div #webinarForm input {
  background: #fff;
  margin: 0;
}

input.webinar_form-width {
  padding: 5px 10px;
}

.home_webinar_report_points p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.home_industry_timeline p {
  font-size: 14px
}

.home_industry_name h2 {
  margin-bottom: 10px;
}

#industryAcknowledgement {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 12px 0;
}

.home_webinar_timeline p {
  font-size: 14px
}

.home_webinar_name h2 {
  margin-bottom: 10px;
}

#webinarAcknowledgement {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 12px 0;
}

@media (max-width:480px) {
  .animate-box>h2,
  h2.animate-box,
  h2>a.hoverblue {
    font-size: 22px
  }
  .home_industry_report_points p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .home_webinar_report_points p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .home_industry_timeline p {
    font-size: 12px
  }
  .home_industry_name h2 {
    font-size: 22px;
  }
  .home_industry_report_points li {
    font-size: 14px;
  }
  .home_webinar_timeline p {
    font-size: 12px
  }
  .home_webinar_name h2 {
    font-size: 22px;
  }
  .home_webinar_report_points li {
    font-size: 14px;
  }
}

/* ===================Analysis Pages============================== */

.flexjustifycenter {
  display: flex;
  justify-content: center;
}

.flexdirectionCol { 
  display: flex;
  flex-direction: column;
}

.flexdirectionRow {
  display: flex;
  flex-direction: row;
}

.campanaly-divs {
  display: flex;
  flex-direction: row;
  padding: 24px 0;
}

.campanaly-container,
.influencer-container-next {
  padding: 24px 48px;
  background: #fff;
}

.campanaly-container-next {
  padding: 24px 48px;
}

.campanaly-image-div-left {
  display: flex;
  align-items: center;
  padding-left: 48px;
}

.campanaly-image-div-right {
  display: flex;
  align-items: center;
  padding-right: 48px;
}

.campanaly-flexdirectionRow {
  display: flex;
  flex-direction: row;
}
.campanaly-flexdirectionRow-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.campanaly-flexdirectionCol{
  display: flex;
  flex-direction: column;
}
.campanaly-flexdirectionCol-reverse {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width:480px) {
  .influMark-influenceScore {
    /* align-items: center; */
    display: flex;
    padding: 0 96px;
  }
  .inflMark_showinfluencerScore p {
    font-size: 12px;
    font-style: italic;
  }
  .inflMark_showinfluencerScore h4 {
    margin-bottom: 10px;
  }
  .inflMark_showinfluencerScore img {
    width: 100px;
  }
  .inflMark_divs .campanaly-image-div-right {
    flex-basis: 100%
  }
  .inflMark_container {
    background: #fff;
    padding: 24px 48px;
  }
}

@media (max-width:480px) {
  .campanaly-divs {
    display: flex;
    flex-direction: column;
  }
  .campanaly-container-next {
    padding: 24px
  }
  .campanaly-image-div-right,
  .campanaly-image-div-left {
    padding: 24px 0;
  }
  .campanaly-divs img {
    width: 100%;
  }
  .campanaly-container {
    padding: 24px;
  }
  .campanaly-flexdirectionRow, .campanaly-flexdirectionRow-reverse {
    display: flex;
    flex-direction: column;
  }
  .campanaly-flexdirectionCol{
    display: flex;
    flex-direction: row;
  }
  .campanaly-flexdirectionCol-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
  .inflMark-specialImage {
    width: 100%
  }
}

/* ================cookie======================== */

@media(max-width:480px) {
  #cookiescript_injected {
    width: 90% !important;
    left: 5% !important;
  }
}

/* ==============discount offer=================== */

.offer-content {
  padding: 12px 0;
}

.offer-content h4 {
  margin-bottom: 10px;
  font-weight: 800;
  color: darkorange;
}

.offer-content h2 {
  margin-bottom: 10px;
  font-weight: 800;
}

.offer-content span {
  font-weight: 600;
  font-size: 12px;
}

@media(max-width:1200px) {
  .offer-content h2 {
    font-size: 24px;
  }
  .offer-content h4 {
    font-size: 18px;
  }
  .offer-content {
    padding: 10px 0;
  }
}

@media(max-width:768px) {
  .offer-content h2 {
    font-size: 20px;
  }
  .offer-content h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .offer-content {
    padding: 10px 0;
  }
}

/* ======================.client_story_content======================== */

.client_all_story {
  margin: 6px 0;
  padding: 12px;
}

.client_story_content {
  padding: 12px;
}

.client_story_content span {
  font-weight: 400;
}

.client_image {
  padding: 12px;
  background-color: #fff
}

.client_image img {
  /* height: 125px; */
  width: 188px;
}

/* =========================home click api================= */

.home-api-click-btn {
  background: #0079c0;
  padding: 5px;
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 600;
  white-space: nowrap;
}

.message-social-remove {
  padding: 0px;
  max-width: 400px;
}

.message-social-remove p,
.message-social-remove span,
.message-social-remove a {
  font-size: 12px;
}

.home-influencer-problem {
  position: relative;
}

.home-influencer-problem img {
  vertical-align: middle;
}

.home-influencer-problem-content {
  position: absolute;
  bottom: 0;
  height: 100%;
  background: rgb(0, 0, 0);
  /* Fallback color */
  background: rgba(0, 0, 0, 0.8);
  /* Black background with 0.8 opacity */
  width: 99%;
  padding: 20% 10%;
  text-align: center;
  display: flex;
}

.home-influencer-problem-content h4 {
  color: #fff;
  align-items: center;
  display: flex;
}

.home-influencer-solution-container {
  padding: 12px 0;
  justify-content: space-between;
}

.home-influencer-problem img,
.home-influencer-solution-container img {
  width: 99%
}

.padding-top-down {
  padding: 24px 0
}

.home-influencer-solution-image .analytics-4 {
  width: unset;
}

.home-influencer-problem-container {
  padding-bottom: 5px;
  justify-content: space-between;
}

.home-influencer-solution-container p {
  padding-top: 12px;
  font-size: 14px;
}

.home-influencer-solution-image .infl-graph {
  height: 250px
}

@media(max-width:480px) {
  .home-influencer-problem {
    margin: 12px 0;
  }
  .home-influencer-problem-container,
  .home-influencer-solution-container {
    padding: 0;
  }
  .home-influencer-solution h4 {
    font-size: 14px;
    line-height: 1.5;
    padding: 12px 0;
    margin-bottom: 10px;
  }
  .home-influencer-solution-image {
    padding-bottom: 12px;
  }
  .home-influencer-solution h4.home-influencer-text {
    padding-top: 24px;
  }
  .influencer-container-next {
    padding: 24px 12px;
  }
  .home-influencer-solution-image .analytics-4,
  .home-influencer-solution-image .scores-infl {
    width: 100%;
  }
  .home-influencer-solution-container p {
    padding-top: 12px;
    font-size: 12px;
  }
  .home-influencer-solution-image .infl-graph {
    height: unset
  }
}

.btn.btn-success.login-button:hover,
.btn.btn-success.login-button:focus,
.btn.btn-success.login-button:active,
.btn.btn-success.signup-button:hover,
.btn.btn-success.signup-button:focus,
.btn.btn-success.signup-button:active {
  color: #fff;
  background-color: #449d44;
  opacity: .8
}

/*show_count_of_success  */

.figure-box {
  width: 100%;
  background: linear-gradient(rgba(0, 121, 192, 0.7), rgba(0, 121, 192, 1));
  float: left;
}

.figure-box .figure-box-div {
  display: inline-block;
  position: relative;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  flex-wrap: wrap;
  width: 16%;
  padding: 12px;
  /* border-right: 1px solid #2b1a12; */
}

.figure-box .figure-box-div .icon-box {
  text-align: center;
  /* width: 100%; */
  /* float: left; */
}

.figure-box .figure-box-div .icon-detail {
  width: 100%;
  /* float: left; */
  padding: 0 12px;
}

.figure-box .figure-box-div .counter {
  font-size: 30px;
  color: #fff;
  overflow: hidden;
  position: relative;
  font-weight: 700;
}

.counter-value-name {
  color: #fff;
  overflow: hidden;
  position: relative;
  font-weight: 700;
}

@media only screen and (max-width: 1365px) and (min-width: 1024px) {
  .figure-box .figure-box-div .counter,
  .figure-box .figure-box-div .counter-value-name {
    font-size: 20px;
  }
}

.figure-box .figure-box-div .plus {
  /* font-size: 35px; */
  color: #fff;
  font-weight: 400;
  position: relative;
  top: -10px;
  margin-left: 5px;
}

.figure-box .figure-box-div .stat-detail {
  font-size: 14px;
  color: #fff;
  text-transform: none;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}

@media(max-width:920px) {
  .figure-box .figure-box-div .counter,
  .figure-box .figure-box-div .counter-value-name {
    font-size: 20px;
  }
  .figure-box .figure-box-div .plus {
    font-size: 25px;
  }
  .figure-box .figure-box-div .stat-detail {
    font-size: 14px;
    line-height: 20px;
  }
  .figure-box .figure-box-div {
    justify-content: baseline;
    width: 30%;
  }
}

@media(max-width:768px) {
  .figure-box .figure-box-div .counter,
  .figure-box .figure-box-div .counter-value-name {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }
  .figure-box .figure-box-div .plus {
    font-size: 12px;
    top: -5px;
    margin-left: 0;
  }
  .figure-box .figure-box-div .stat-detail {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  .figure-box .figure-box-div {
    justify-content: baseline;
    width: 40%;
  }
  /* .figure-box {
    background: linear-gradient(rgba(83, 119, 153, 0.8), rgba(83, 119, 153, 0.7));
  } */
}

.usecase-w3-card-3 .f_trail {
  font-size: 14px;
  color: #545454;
  text-align: left;
  min-height: 195px;
  padding: 12px
}

.learn_btn {
  display: inline-block;
  color: #0079c0;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  left: 15px;
  bottom: 15px;
}

.learn_btn::after {
  content: '\f054';
  height: 17px;
  width: 17px;
  font-family: fontAwesome;
  font-weight: 300;
  margin-left: 5px;
  top: 45%;
  margin-top: -9px;
}

@media only screen and (max-width:768px) {
  .new-usecase-view-div .usecase-w3-card-3 {
    display: inline-block;
    position: relative;
    width: 90%;
    border-radius: 5px;
    background: #fff;
    margin: 10px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    padding: 12px;
  }
}

@media only screen and (min-width:769px) {
  .new-usecase-view-div .usecase-w3-card-3 {
    display: inline-block;
    position: relative;
    width: 25%;
    border-radius: 5px;
    background: #fff;
    margin: 10px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    padding: 12px;
  }
}

.new-usecase-view-div .usecaseImageContainer {
  width: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: round !important;
  height: 180px;
}

.hidden {
  opacity: 0;
}

.console-container {
  font-family: Ubuntu Mono, monospace;
  font-weight: 400;
  font-size: 28px;
  text-align: left;
  height: 200px;
  width: 800px;
  display: block;
  position: absolute;
  color: rgba(0, 121, 192, 1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 30px;
  text-transform: uppercase;
  letter-spacing: -0.5px;
}

.console-underscore {
  display: inline-block;
  position: relative;
  top: -0.14em;
  left: 10px;
}

#text {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 121, 192, 1);
  background-image: linear-gradient(-90deg, rgba(0, 121, 192, 1), rgba(0, 121, 192, .7));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.console-underscore {
  color: rgba(0, 121, 192, 1);
}

.display-coverage p {
  font-weight: 400;
  margin-bottom: 15px;
}

@media(max-width:768px) {
  .console-container {
    font-family: Ubuntu Mono, monospace;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
    height: 200px;
    /* width: 800px; */
    display: block;
    position: absolute;
    color: rgba(0, 121, 192, 1);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 30px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
  }
  .console-underscore {
    display: inline-block;
    position: relative;
    top: -0.14em;
    left: 10px;
  }
  #text {
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 121, 192, 1);
    background-image: linear-gradient(-90deg, rgba(0, 121, 192, 1), rgba(0, 121, 192, .7));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -o-background-clip: text;
    -ms-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent; 
  } 
  .console-underscore {
    color: rgba(0, 121, 192, 1);
  }
  .display-coverage p {
    font-size: 14px
  }
}
.feature-case{
  display: flex;
  flex-flow: wrap;
  align-items: flex-start; 
  justify-content: center;
  font-weight: 600;
}

.feature-case-w3-card3{
  display: inline-block;
  position: relative;
  border-radius: 5px;
  background: #fff;
  margin: 10px;
  padding: 12px;
}
.text-position{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.featurecaseImageContainer{
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  padding: 12px 24px;
}

.usecaseImageContainer-img{
  height: 80px;
  width: 80px;
  background-size: cover !important;
  background-repeat: round !important;
}

.w3-container.w3-center>a,
.w3-container.w3-center>a:hover,
.w3-container.w3-center>a:active,
.w3-container.w3-center>a:visited,
.w3-container.w3-center>a:focus{
  text-decoration: none;
}

.feature-case-w3-card3>a,
.feature-case-w3-card3>a:hover,
.feature-case-w3-card3>a:active,
.feature-case-w3-card3>a:visited,
.feature-case-w3-card3>a:focus{
  text-decoration: none;
}

.feature-case>p:hover{
  color: #0079c0
}

.feature-link{
  text-align: center;
}

.feature-link>a,
.feature-link>a:hover,
.feature-link>a:active,
.feature-link>a:visited,
.feature-link>a:focus{
  text-decoration: none;
  outline: none;
}

.message-color{
  color: rgb(255, 0, 0);
}

@media screen and (max-width: 480px){
  .text-space{
    text-align: center;
  }
  .align-items-mobile{
    align-items: center;
  }
}


form.loginForm  .login-button{
  margin-top: 24px
}


.newbtn, a.newbtn{
  font-family: "Montserrat", Sans-serif;
  fill: #ffffff;
  color: #ffffff;
  background-color: #00dafc;
  border-radius: 30px 30px 30px 30px;
  padding: 10px 20px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 600;
  border: unset;
  text-transform: uppercase;
}


a.newbtn:hover, .newbtn:hover {
  color: #fff;
  background-color: #4632da
}

@media (min-width:480px) {
  .find-infl-search{
    padding: 0 0 0 24px
  }
  .find-infl-container .form-group{
    margin-bottom: 0;
  }
}

.find-infl-container{
  padding: 24px;
}
.flex-grow-1{
  flex-grow: 1;
}

.flex-grow-5{
  flex-grow: 5;
}

.find-infl-search input.form-control {
  border: 2px solid #00dafc;
  border-radius: 6px 0 0 6px;
  height: 56px;
}
.find-infl-select-div select.form-control{
  border: 2px solid #00dafc;
  border-radius: 6px;
  height: 56px;
}

.find-infl-search-btn button{
  border-radius: 0 6px 6px 0;
  background-color: #00dafc !important;
  border: 2px solid #00dafc !important;
  height: 56px;
  font-size: 24px;
  text-transform: capitalize;
}

.showAllFilters {
  display: block !important;
}
.hideAllFilters {
  display: none !important;
}

.show-inflencer-icon img{
  height: 46px;
}

.infl-search-form img{
  height: 46px;
}

.align-items-center{
  display: flex;
  align-items: center;
}

.flex-infl-image{
  padding: 5px 0;
}

.infl-list-all-filter{
  padding: 2px 0;
}

.cursor-pointer{
  cursor: pointer;
}

@media (max-width:480px) {
  .find-infl-search-btn button{
    border-radius: 6px;
    font-size: unset;
    height: unset;
  }
  .find-infl-select-div select.form-control{
    border-radius: 6px;
    font-size: unset;
    height: unset;
  }
  .find-infl-search input.form-control{
    border-radius: 6px;
    font-size: unset;
    height: unset;
    font-size: 12px;
  }
  .infl-search-form img, .show-inflencer-icon img {
      height: 30px;
  }
  .new-infl-source-images-div{
    height: 41px;
  }

}


a.btndisabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}

#influencerDiscoveryForm .autocomplete-items div {
 background : #00dafc
}

#influencerDiscoveryForm .autocomplete-items div:hover, #influencerDiscoveryForm .autocomplete-items div:active, #influencerDiscoveryForm .autocomplete-items div:focus{
  background: #4632da;
}
#influencerDiscoveryForm .autocomplete-items {
  margin: 0;
}

.btn-delete{
  color: red;
  border-color: red;
}

.adminConfirm{
  color: #0079c0;
  border-color: #0079c0;
}